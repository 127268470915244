import React, { useEffect, useState } from 'react';
import {MenuItem,Menu,Chip,IconButton, CardContent,Card,Typography, Box,Divider,ListItemText,ListItemButton, CardHeader ,Grid, Button} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from "moment";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BoookingsLists from './BookingsLists';
import ReScheduleBookings from './RescheduleBookings';
import CancelBooking from './BookingDetailComponents/CancelBooking';
import UpdateTaskTime from '../../Tasks/components/TaskDetailComponents/UpdateTaskTime';
import AdditionalBookingForm from './AdditionalBookingForm';
import BookingApi from '../BookingApi';
import ConfirmBooking from './BookingDetailComponents/ConfirmBooking';
import SlideButton from './BookingDetailComponents/StartBooking';
import CompleteBooking from './BookingDetailComponents/CompleteBooking';
import GoBack from '../../../commonuser/GoBack';
function BookingDetail({booking,token}) {
  const created = moment(booking.created_at).fromNow();
     const dateConvert = (ds) =>{
        return  moment(ds).format('MMMM Do YYYY, h:mm a');
        
    }

    const cancelTaskStatuses = ["CANCELLED_BY_HELPER","CANCELLED_BY_SEEKER","TASK_IN_PROGRESS","TASK_COMPLETED"]
    const [anchorEl, setAnchorEl] = useState(null);
      const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleMenuClose = () => {
        setAnchorEl(null);
      };
    const [showBoookingDetail, setShowBookingDetail] = useState(true);
    const [showBookings, setshowBookings] = useState(false);
    const [showReschedulekBookings, setshowReschedulekBookings] = useState(false);
    const [openReschuleTask, setOpenReschuleTask] = useState(false);
    const [showTaskCancel, setshowTaskCancel] = useState(false);
    
    const [showRequestConfirm, setshowRequestConfirm] = useState(false);
    const [showBookingConfirm, setshowBookingConfirm] = useState(false);
    const [showADF, setshowADF] = useState(false);

    const [showBookingStart, setshowBookingStart] = useState(false);
    const [showBookingEnd, setshowBookingEnd] = useState(false);
    const [showBookingButton, setshowBookingButton] = useState(false);
    const [showBookingEndButton, setshowBookingEndButton] = useState(false);

    useEffect(() =>{
      BookingApi.GetRequestForConfirmation(token,booking.task_data.id).then(resp=>{
        
        if (resp.REQUEST_FOR_CONFIRMATION) {
          setshowBookingConfirm(true)
          setshowRequestConfirm(true)
        }
      })
    },[token,booking.task_data.id])

    useEffect(() =>{
      BookingApi.ShowStartBooking(token,booking.id).then(resp=>{
        setshowBookingButton(resp.show_start_booking)
      })
       BookingApi.ShowEndBooking(token,booking.id).then(resp=>{
        setshowBookingEndButton(resp.show_end_booking)
      })
    },[token,booking.id])
    const reschedule_action =  booking.reschedule_data &&  booking.reschedule_data.some((bk) => bk.status === 'RESCHEDULE_REQUESTED_BY_SEEKER');


    return ( 
      <div>
    <React.Fragment>
      { showBoookingDetail &&
      <React.Fragment>
 <SlideButton token={token} bookingId={booking.id} setshowBookingStart={setshowBookingStart} showBookingStart={showBookingStart}/>
  <CompleteBooking token={token} bookingId={booking.id} setshowBookingEnd={setshowBookingEnd} showBookingEnd={showBookingEnd}/>       
         <Box sx={{flexGrow: 1}} p={2}>
         {showBookingButton && (
          <Button
          fullWidth
            onClick={(e) => {
              setshowBookingStart(true)
            }}
            variant="contained"
            color="primary"
          >
            Start Booking
          </Button>

         )}
         {showBookingEndButton&&(
          <Button
          fullWidth
            onClick={(e) => {
              setshowBookingEnd(true)
            }}
            variant="contained"
            color="primary"
          >
            Complete Booking
          </Button>

         )}
          
          <GoBack/>
         <Card style={{backgroundColor: "#0dcaf000"}} elevation={1} >
            <CardHeader
        title={<Typography sx={{ml:3,display:"flex",justifyContent:'center'}} variant="h5">Booking Details</Typography>}

        action={
          <React.Fragment>
          <IconButton aria-label="kebab menu" onClick={handleMenuClick}  >
            <MoreVertIcon />
          </IconButton>
          </React.Fragment>
          
        }
            >
          </CardHeader>
          <Menu
            id="kebab-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
           > 

        {cancelTaskStatuses.includes(booking.task_data.status) ? (
          <div></div>
        ) : (
          <MenuItem onClick={() => setshowTaskCancel(true)}>
            <Typography color="error">Cancel Booking</Typography>
            </MenuItem>
        )}
        {booking.available_actions.reschedule_action && (
          <MenuItem onClick={() => setOpenReschuleTask(true)}>Reschedule Booking</MenuItem>
        ) 
        }

        {
          booking.available_actions.additonal_time_action &&
          (
          <MenuItem onClick={() => setshowADF(true)}>Additional Booking</MenuItem>
          )
        }
        {
          showRequestConfirm && (
             <MenuItem onClick={() => setshowBookingConfirm(true)}>Mark as Complete</MenuItem>
          )
        }



           </Menu>


        <ConfirmBooking taskId={booking.task_data.id} token={token} showBookingConfirm={showBookingConfirm} setshowBookingConfirm={setshowBookingConfirm}/>
        <CancelBooking taskId={booking.task_data.id} token={token} showTaskCancel={showTaskCancel} setshowTaskCancel={setshowTaskCancel}/>
        <UpdateTaskTime taskId={booking.task_data.id} old_start_time={booking.task_data.start_time} old_end_time={booking.task_data.end_time} openReschuleTask={openReschuleTask} setOpenReschuleTask={setOpenReschuleTask} />          
        <AdditionalBookingForm booking={booking} showAdditionalBookingForm={showADF} setShowAdditionalBookingForm={setshowADF}/>            
<CardContent>
      {booking.task_data.task_type==="IN_PERSON" ? (<Typography sx={{ fontSize:15  }}> <strong>In Person Appoinment</strong></Typography>) :(<Typography sx={{ fontSize:15 }}> <strong>Online Appoinment</strong></Typography>)}
    <Typography sx={{ fontSize:12 }}>Posted {created}</Typography>
    </CardContent>
      <Divider>Booking Details</Divider>

<CardContent>
        <Grid container spacing={0.3}>
        <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Category:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{booking.task_data.parent_category_name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1"><strong>Sub Category:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{booking.task_data.sub_catgory_name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>Start Time: </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{dateConvert(booking.task_data.start_time)}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>End Time:  </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{dateConvert(booking.task_data.end_time)}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>Languages Selected:  </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {booking.task_data.languages_selected && JSON.parse(booking.task_data.languages_selected).join(', ')}
            </Typography>
        </Grid>
      </Grid>
      </CardContent>
      <Divider>Description</Divider>
       <CardContent>
      <Typography variant="subtitle1"> {booking.task_data.description}</Typography>
      
    {booking.task_data.additional_info && <Typography variant="subtitle1"><strong>Additional Info: </strong>{booking.task_data.additional_info}</Typography> }
    </CardContent>
        <Divider>Contact Details</Divider>
        <CardContent>
    <Grid container spacing={0.3}>
        <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Phone:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{booking.task_data.phone_number}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1"><strong>Pincode:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{booking.task_data.pincode}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>Location: </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{booking.task_data.city}</Typography>
        </Grid>

    </Grid>
    </CardContent>
     <CardContent>
    {booking.payment_data && 
     <React.Fragment>
     <Divider>Earnings Details</Divider>
     <Grid container spacing={0.3}>
      <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Price:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{booking.payment_data.total_price_exc_vat.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
        </Grid>
      <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Total Hours:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{booking.payment_data.total_no_hours}</Typography>
        </Grid>
     </Grid>
      </React.Fragment>}
    </CardContent>

    <CardContent>
    <Divider sx={{ bgcolor: "secondary.light" }} component="p" />
        <ListItemButton  >
        <ListItemText primary="Bookings" onClick={()=>{
          setShowBookingDetail(false)
          setshowBookings(true)}} />
        <ArrowForwardIosIcon/>
        </ListItemButton>
    </CardContent>
    <CardContent>
      <Divider sx={{ bgcolor: "secondary.light" }}  component="p" />
        <ListItemButton  >

          <ListItemText 
          // primary="Reschedules" 
          primary={ reschedule_action ?
            (<React.Fragment>
                <Chip   label="Action Required" size="small" color="error" variant="outlined" > </Chip> 
                <Typography>Reschedules</Typography></React.Fragment> )
             : "Reschedules" }
          onClick={()=>{
            setShowBookingDetail(false)
            setshowReschedulekBookings(true)}}  >
            </ListItemText>
          <ArrowForwardIosIcon/>
        </ListItemButton>
      </CardContent>

    </Card>
    </Box>
    </React.Fragment>
    


      }
      

    <React.Fragment>
    {showBookings && 
    <React.Fragment>
    <BoookingsLists booking={booking} token={token} setShowBookingDetail={setShowBookingDetail} setshowBookings={setshowBookings} setshowADF={setshowADF}/>
    </React.Fragment>
    }

    {showReschedulekBookings && 
    <React.Fragment>
    <ReScheduleBookings booking={booking} token={token} setShowBookingDetail={setShowBookingDetail} setshowReschedulekBookings={setshowReschedulekBookings}/>
    </React.Fragment>
    }
    </React.Fragment>
    </React.Fragment> 
    </div>
    );
}

export default BookingDetail;
import React, { useState ,useEffect} from 'react';
import { Box,FormControl, Button, TextField, Select,MenuItem,Typography, FormControlLabel,Checkbox } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import PasswordIcon   from '@mui/icons-material/Password';
import DialpadIcon from '@mui/icons-material/Dialpad';
import LoginIcon from '@mui/icons-material/Login';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import TermsAndConditionsPopup from '../commonuser/TermsAndConditionsPopup';
import UserApiService from '../commonuser/UserApi';
import { useNavigate } from "react-router-dom";
import ErrorToast from '../utils/ErrorToast';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import 'react-phone-number-input/style.css'
const UserRegister = () => {

    let navigate = useNavigate();
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [phone_number, setPhone_number] = useState('')
    const [refferal_code, setRefferal_code] = useState('')
    const [errors, setErrors] = useState({});
    const [has_accepted_terms, setAccepted] = useState(false);
    const [is_promotion, setIsPromotion] = useState(false);
    const [country, setCountry] = useState("DE")
    const [CallingCode, setCallingCode] = useState()
    // const [open, setOpen] = useState(false);

    const handleAccept = (event) => {
        setAccepted(event.target.checked);
      };
    const handleProm = (event) => {
        setIsPromotion(event.target.checked);
      };


  const handleSubmit = (event) => {
    event.preventDefault();
    let ph;
    if (phone_number.length>1) {
      ph = CallingCode + phone_number
    } else {
      ph =  ''
    }
     
    UserApiService.UserRegister({first_name,last_name,email,phone_number:ph, password1,password2,refferal_code,has_accepted_terms,is_promotion})
        .then((resp) =>  {
            navigate('/');
        })
         .catch((error) => {
            const { data } = error.response;
            const errorFields = Object.keys(data);
            const newErrors = {};
            errorFields.forEach(field => {
            const [errorMessage] = data[field];
            newErrors[field] = errorMessage;
            });
            setErrors(newErrors);
        })
  };

  const renderSelectedValue = () => {
    if (!country) {
      return '';
    }
    return `+${getCountryCallingCode(country)}`;
  };
useEffect(()=>{
  setCallingCode(+getCountryCallingCode(country))
},[country])

  return (
    <Box sx={{ maxWidth: 500, mx: 'auto', mt: 3 ,p:2 }}>
      <ErrorToast message={errors}/>
      <Typography align='center' variant="h6" mb={3}>
         Sign Up as User
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{display:'flex'}}>
        <TextField
          fullWidth
          label="First Name"
          required
          name="First Name"
          value={first_name}
          onChange={e => setFirstName(e.target.value)}
          error={Boolean(errors.first_name)}
          helperText={errors.first_name}
          sx={{ ml: 1, mr:1,pb:2 }}
        />
        <TextField
        fullWidth
          required
          label="Last Name"
          variant="outlined"
          value={last_name}
          onChange={e => setLastName(e.target.value)}
          mb={2}
          error={Boolean(errors.last_name)}
          helperText={errors.last_name}
          sx={{ pr :1,pb:2  }}
        />
        </Box>

        <TextField
        fullWidth
          required
          label="Email"
          variant="outlined"
          value={email}
          onChange={e => setEmail(e.target.value)}
          mb={2}
          sx={{ p: 1,pb:2  }}
          error={Boolean(errors.email)}
          helperText={errors.email}
          InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
        />
        <Box display="flex" justifyContent="space-between">
           <FormControl sx={{ m: 1, minWidth: 120 }} size="medium">
        <Select
        value={country}
        renderValue={renderSelectedValue}
        defaultValue="DE"
        onChange={(event) => {
        setCountry(event.target.value || undefined)
        }}>
        {getCountries().map((country) => (
          <MenuItem key={country} value={country}>
            {en[country]} +{getCountryCallingCode(country)}
          </MenuItem>
        ))}
      </Select>
      </FormControl>
        <TextField
            fullWidth
            // required
            label="phonenumber"
            variant="outlined"
            value={phone_number}
            onChange={e => setPhone_number(e.target.value)}
            mb={2}
            sx={{ p: 1,pb:2  }}
            error={Boolean(errors.phone_number)}
            helperText={errors.phone_number}
            InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DialpadIcon />
            </InputAdornment>
          ),
        }}
        />
        </Box>
        <TextField
        fullWidth
          required
        type="password"
        label="Password"
        variant="outlined"
        value={password1}
        onChange={e => setPassword1(e.target.value)}
        mb={2}
        sx={{ p: 1 ,pb:2 }}
        error={Boolean(errors.password1)}
        helperText={errors.password1}
        InputProps={{
        startAdornment: (
    <InputAdornment position="start">
        <PasswordIcon />
    </InputAdornment>
          ),
        }}
            />
        <TextField
        fullWidth
          required
        type="password"
        label="Password"
        variant="outlined"
        value={password2}
        onChange={e => setPassword2(e.target.value)}
        mb={2}
        sx={{ p: 1 ,pb:2 }}
        error={Boolean(errors.password2)}
        helperText={errors.password2}
        InputProps={{
        startAdornment: (
            <InputAdornment position="start">
                <PasswordIcon />
            </InputAdornment>
          ),
        }}
        />
        <TextField
        fullWidth
        type="refferal_code"
        label="Refferal Code"
        variant="outlined"
        value={refferal_code}
        onChange={e => setRefferal_code(e.target.value)}
        mb={2}
        sx={{ p: 1 ,pb:2 }}
        error={Boolean(errors.refferal_code)}
        helperText={errors.refferal_code}
        InputProps={{
        startAdornment: (
            <InputAdornment position="start">
                <Diversity3Icon />
            </InputAdornment>
          ),
        }}
        />
        <Box  sx={{  alignItems:"center",display:"flex" , justifyItems:'center',justifyContent:'center'}} >
          <TermsAndConditionsPopup />
        </Box>
      <FormControlLabel
        control={<Checkbox checked={has_accepted_terms} onChange={handleAccept} />}
        label="I accept the terms and conditions"
      />
      <FormControlLabel
       sx={{fontSize: 58}}
        control={<Checkbox   checked={is_promotion} onChange={handleProm} />}
        label={<Typography variant='caption'>Yes! Send me news and offers from LocaleLingo about products, events, Newsletters and more.</Typography>}
      />
        <Button startIcon={<LoginIcon />} variant="contained" type="submit" fullWidth>
          Register
        </Button>
        <Box  sx={{  alignItems:"center",display:"flex" , justifyItems:'center',justifyContent:'center'}} >
          <Button onClick={() => navigate('/')} sx={{ textTransform: 'none', fontSize: 16, mb: 2,mt:1 }}>Already Signed Up? Login</Button>
        </Box>
        
        {errors.non_field_errors &&  <Typography variant="body1" color="error" sx={{ mb: 2 }}>{errors.non_field_errors}</Typography>}
      </form>
    </Box>
  );
};

export default UserRegister;

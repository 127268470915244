import React from 'react';
import { Dialog, DialogTitle , DialogContent, DialogActions, Button, Typography } from '@mui/material';
import BookingApi from '../../BookingApi';
function ConfirmBooking({taskId,token,showBookingConfirm, setshowBookingConfirm}) {

    const TaskCancel = (task_id) => {
      BookingApi.PostRequestForConfirmation(token,task_id).then(resp=>{
          window.location.reload(false);
      })
    }
    // const handleOpen = () => {
    //         setshowBookingConfirm(true);
    //     };

    const handleClose = () => {
            setshowBookingConfirm(false);
        };

    return ( 

    <React.Fragment>
      <Dialog open={showBookingConfirm} variant="contained" onClose={handleClose}>
        <DialogTitle>Complete Booking</DialogTitle>
        <DialogContent>
            <Typography>Are you sure you want to Mark  this Booking as Complete?</Typography>
             <Typography>Once you Mark as Complete you will not be able to create Additional bookings</Typography>
            </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => {
            TaskCancel(taskId);
            handleClose();
          }} variant="contained" sx={{background:""}}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>    
    </React.Fragment>

    );
}

export default ConfirmBooking;
import React, { useState ,useEffect} from 'react';
import {List,ListItemAvatar,ListItem,ListItemText,Typography,Divider} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import useCheckAuth from '../commonuser/CheckAuth';
import NotifyApiService from './NotifyApi';
import moment from 'moment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NavBar from '../NavBars/navBar';
import BottomBar from '../NavBars/BottomBar';
import IsMobile from '../Styles/IsMobile';
import { useNavigate } from "react-router-dom";
import Loading from '../NavBars/Loading';
import { useDispatch } from 'react-redux';
import { setUreadMessages } from '../reducers/NotifyActions';
import { useSelector } from 'react-redux';

function UnreadMessagesMob( ) {
  const unmsg =  useSelector(x => x.notify.unread_messages);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = useCheckAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const isMobile = IsMobile()
  useEffect(()=>{
    if (!isMobile){
        navigate('/')
    }
  },[isMobile,navigate])
  useEffect(() => {
    setMessages(unmsg)
   },
   [unmsg])

  function truncateMessage(message, maxLength) {
  if (message.length <= maxLength) {
    return message;
  }

  return message.substring(0, maxLength) + '...';
}
  useEffect(() => {
  NotifyApiService.unReadMessage(token).then((resp) => {
      setMessages(resp)
      setLoading(false)
      dispatch(setUreadMessages(resp))
    })
   // eslint-disable-next-line
  }, []); 

  return (
    <div>
      <NavBar/>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
        <Typography sx={{padding:1, marginRight:"10%"}}variant="h5">Unread Messages!</Typography>
        { messages.length >0 ? (messages.map((msg,index) => (
        <List key={index} >
           <Divider variant="middle" color="primary" orientation="horizontal" ></Divider>
        <ListItem
          button
          component="a"
          href={`/message/${msg.external_id}`}

        //   href = `/helper/job/${notificationData.associated_id}`

        >
          <ListItemAvatar>
            {/* <Badge badgeContent={messages.length} color="secondary"></Badge> */}
            <EmailIcon />
          </ListItemAvatar>
          <ListItemText
            // alignItems="flex-start"
            primary={`${msg.sender_name} - ${ moment(msg.timestamp).fromNow()}`}
            secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {truncateMessage( msg.text, 20)}
              </Typography>
            </React.Fragment>
          }

        />
        </ListItem>
        </List>
        ))
      
      ):(
        <List >
         <ListItemText sx ={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
          <Divider variant="middle" color="primary" orientation="horizontal" > <CheckCircleOutlineIcon sx ={{ fontSize:50  , color:"green" }}/></Divider>
                <Typography sx={{textAlign: "center","overflowY": 'auto', "minWidth": '300px', "maxWidth": '500px',}} variant="h5">All caught up!</Typography>
          <Typography sx={{textAlign: "center", }}variant="body1">
            You have no new messages
          </Typography>
        </ListItemText>
        </List>)}
        </React.Fragment>
      )}
        <BottomBar/>
    </div>
  );
}

export default UnreadMessagesMob;

import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import JobsApi from '../JobsAPi';
import { FaCube } from 'react-icons/fa';

function CreateQuote({task_id,token,total_no_hours,connects_required}) {

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [quote, setQuote] = useState(0);
    const [QuoteSetError, setQuoteSetError] = useState({});
    const [selectedOption, setSelectedOption] = useState('pricePerHour');

    const handleConfirmOpen = () => {
        setConfirmOpen(true);
    };
    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };
    const EnterQuote= () => {

        let quote_in =  {}
        if (selectedOption==="pricePerHour") {
            quote_in = {price_hr: quote}
        } 
        if (selectedOption==="totalPrice") {
            quote_in = {comp_price: quote}
        } 
        JobsApi.setQuote(token,task_id,quote_in)
        .then((resp) => (window.location.reload(false)))
        .catch((error) => {
          const { data } = error.response;
          const errorFields = Object.keys(data);
          const newErrors = {};
          errorFields.forEach(field => {
          const [errorMessage] = data[field];
          newErrors[field] = errorMessage;
          });
          setQuoteSetError(newErrors);
        })
      }
    const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

    return (
        <React.Fragment>
    <FormControl component="fieldset">
      <FormLabel component="legend">Select Option:</FormLabel>
      <RadioGroup
        aria-label="option"
        name="option"
        value={selectedOption}
        onChange={handleOptionChange}
      >
        <FormControlLabel value="pricePerHour" control={<Radio />} label="Price/hr" />
         {selectedOption === 'pricePerHour' && (
          <TextField
            label="Price per Hour"
            value={quote}
            onChange={e => setQuote(e.target.value)}
            variant="outlined"
          />
        )}
        <FormControlLabel value="totalPrice" control={<Radio />} label="Total Price" />
        {selectedOption === 'totalPrice' && (
          <TextField
            label="Total Price"
            value={quote}
            onChange={e => setQuote(e.target.value)}
            variant="outlined"
          />
        )}
        
      </RadioGroup>
      <Button sx={{p:1,mt:2}} variant="contained" color="primary" onClick={handleConfirmOpen}>
            Confirm
        </Button>
      
    </FormControl>
{/* 
        <TextField
            id="quote"
            label="Enter Offer (Price/hr)"
            type="number"
            value={quote}
            onChange={e => setQuote(e.target.value)}
        /> */}
        
        <Dialog open={confirmOpen} onClose={handleConfirmClose}>
            <DialogTitle>Confirm Amount</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Are you sure you want to submit this quote?
            </DialogContentText>
            {selectedOption === 'totalPrice' ? ( 
                <DialogContentText>Total Amount: {(quote*1).toLocaleString("en-US", {style:"currency", currency:"EUR"})}</DialogContentText>
            ) : (
              <React.Fragment>
              <DialogContentText>Price: {quote.toLocaleString("en-US", {style:"currency", currency:"EUR"})}/hr</DialogContentText>
                <DialogContentText>Total Amount: {(quote * total_no_hours).toLocaleString("en-US", {style:"currency", currency:"EUR"})}</DialogContentText>
                </React.Fragment>
            )}
            <DialogContentText>
                Sending quote for this task will cost {connects_required} <FaCube/>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleConfirmClose} color="primary">
                Cancel
            </Button>
            <Button onClick={() => EnterQuote()} color="primary">
                Submit
            </Button>
            </DialogActions>
        </Dialog>
        {QuoteSetError.message && <div className="alert alert-danger">{QuoteSetError.message}</div>}
        </React.Fragment>
     );
}

export default CreateQuote;
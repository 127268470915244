import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Container, Typography } from '@mui/material';

const Loading = () => {
  const [showRetry, setShowRetry] = useState(false);

  useEffect(() => {
    // Simulating a loading process
    const timeout = setTimeout(() => {
      setShowRetry(true);
    }, 10000); // Timeout after 20 seconds

    // Cleanup the timeout on unmount
    return () => clearTimeout(timeout);
  }, []);

  const handleRetry = () => {
    // Perform any necessary actions before retrying
    // For example, you can reload the page
    window.location.reload();
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      {!showRetry && (
        <React.Fragment>
      {/* <Typography variant="h5" gutterBottom>
        Loading...
      </Typography> */}
       <CircularProgress style={{ margin: '50px auto' }} />
       </React.Fragment>
      )}
     
      {showRetry && (
         <React.Fragment>
      <Typography variant="h5" gutterB1ottom>
        { 'Oh no, Something went Wrong'}
      </Typography>
       <Typography variant="h6" gutterB1ottom>
        {'Please Try Again'}
      </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleRetry}
          style={{ marginTop: '20px' }}
        >
          Retry
        </Button>
        </React.Fragment>
      )}
    </Container>
  );
};

export default Loading;

import React, { useState, useEffect } from 'react';
import useCheckAuth from '../commonuser/CheckAuth';
import {Grid,Tooltip,Button,ListItemText,Avatar,Divider,ListItemButton, Card, CardContent, CardHeader, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import NavBar from '../NavBars/navBar';
import InfoIcon from '@mui/icons-material/Info';
import Loading from '../NavBars/Loading';
import PaymentConnect from '../payment/PaymentConnect';
import { useDispatch } from 'react-redux';
import { setUserEmail ,setHelperType,setUserCredit,setUserFirstName,setUserLastName,setUserCubes,setUserAccess,setHelperKyc} from '../reducers/userActions';
import UserApiService from '../commonuser/UserApi';
import { MoreVert } from '@mui/icons-material';
import ProfileUpdate from '../user/ProfileUpdate';
import theme from '../Styles/theme';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FaCube } from 'react-icons/fa';
import PasswordChange from '../user/PasswordChange';
import BottomBar from '../NavBars/BottomBar';
import SocialConnect from '../commonuser/SocialConnect';
import WaitingForVerificationBanner from './WaitingForVerificationBanner';
import Logout from '../NavBars/Logout';
import DeleteAccountRequest from '../commonuser/DeleteAccountRequest';
function HelperProfile() {
  const [profile, setProfile] = useState({});
  const [editMode, setEditMode] = useState(false);
  const token = useCheckAuth();
  const [showProfile, setshowProfile] = useState(true);
  const [ShowSocialAccont, setShowSocialAccont] = useState(false);
  const [loading, setLoading] = useState(true);
  const [passwordChange, showpasswordChange] = useState(false);
  const dispatch = useDispatch(); 
  const handleButtonClick = () => {
    setEditMode(true)
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickA = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    UserApiService.GetHelperProfile(token).then((resp) => {
      setProfile(resp);
      setLoading(false);
      dispatch(setUserEmail(resp.email));
      dispatch(setUserCredit(resp.user_credit));
      dispatch(setUserFirstName(resp.first_name));
      dispatch(setUserLastName(resp.last_name));
      dispatch(setUserCubes(resp.connects_amount))
      dispatch(setUserAccess(resp.is_access_enabled))
      dispatch(setHelperType(resp.helper_type))
      setHelperKyc(resp.kyc_verified)
    });
  }, [token,dispatch]);
  const handleClick = (stripe_account_id) => {
    UserApiService.GetStripeDashboardUrl(token, { stripe_account_id }).then(
      (resp) => { (window.location.href = resp.stripe_dashboard_url) }
    );
  };
  return (
    <div>
      <NavBar />
      {loading ? (
        <Loading />
      ) : (
       
        <div>
           <WaitingForVerificationBanner/>
            {ShowSocialAccont && <SocialConnect token={token} setShowSocialAccont={setShowSocialAccont} setshowProfile={setshowProfile}/> }

           {passwordChange && <PasswordChange token={token} showpasswordChange={showpasswordChange} setshowProfile={setshowProfile} />   }
          
          {!showProfile ? null :(
            
            <Card
               style={{backgroundColor: "#0dcaf000"}}
              sx={{ maxWidth: 800,margin: "auto",}}
              >
              <CardHeader
                avatar={
                    <Avatar aria-label="profile picture" 
                    sx={{backgroundColor: theme.palette.primary.main,}}
                     />
                  }
                 title={<Typography variant="h5">{profile.first_name} {profile.last_name}</Typography>}
                 subheader={<Typography variant="subtitle1">{profile.email}</Typography>}
                 action={
                  <React.Fragment>
                  <Logout/>
                    <IconButton aria-label="kebab menu" onClick={handleClickA}>
                      <MoreVert />
                    </IconButton>
                    </React.Fragment>}
                >
                </CardHeader>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  >
                  <MenuItem onClick={handleButtonClick}>Update Profile</MenuItem>
                </Menu>           
                <CardContent>
                <Typography variant="subtitle1">Referral Code: {profile.refferal_code}</Typography>
                <Typography variant="subtitle1">Credits: {profile.user_credit.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
                <Typography variant="subtitle1">Cubes: {profile.connects_amount} <FaCube/>
                  <Tooltip  enterTouchDelay="0" title="Use Cubes to send Offers to different Jobs. your connect will be reseted every month if you want buy more please follow the link near by">
                  <IconButton>
                    <InfoIcon></InfoIcon>
                  </IconButton>
                </Tooltip>
                <PaymentConnect/>
                </Typography> 
                {!profile?.helper_type.includes["ORG_USER"] &&    (      
                <Typography variant="subtitle1">KYC Status:  {profile.kyc_verified === false ? (
                <Tooltip  enterTouchDelay="0" title="Kyc Verification not successful, if done recently please wait for 3-4 days for confirmation if not proceed ro stripe daashboard to complete the process">
                <IconButton>
                  <CancelIcon sx={{color:"error" , marginRight:0.5 }} ></CancelIcon>
                  <Button sx={{ fontSize:10 }} onClick={() => handleClick(profile.stripe_account_id)} > Stripe Dashboard </Button>
                </IconButton>
              </Tooltip>
                ) :
                (<VerifiedIcon sx={{color:"green"}}></VerifiedIcon>)} 
                </Typography>
                )}     
              </CardContent>
              
                <Typography sx={{marginLeft:2}}variant="h6">Personal Information</Typography>
              
              { editMode ? (
             
             <ProfileUpdate user={profile} user_type="helper" token={token} setEditMode={setEditMode} setLoading={setLoading}  />
              
            ) :(
              <CardContent>
                <Divider  component="li" />
                <Grid container spacing={0.3}>
                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>First Name:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.first_name}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>Last Name:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.last_name}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>Phone Number:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.phone_number}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>Address:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.address}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>Pincode:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.pincode}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>City:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.city}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>Job Preferences:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.job_preferences && JSON.parse(profile.job_preferences).join(', ')}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>Languages Selected:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.languages_selected && JSON.parse(profile.languages_selected).join(', ')}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>Verified Languages:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">{profile.verified_languages && JSON.parse(profile.verified_languages).join(', ')}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            )}
            
            <CardContent>
              <Divider  component="li" />
              <ListItemButton component="a" href="/helper/earnings">
                <ListItemText primary="Earnings" />
                <ArrowForwardIosIcon/>
              </ListItemButton>
              {profile.helper_type.includes("ORG_ADMIN") || profile.helper_type.includes("LOCAL") && (
                <React.Fragment>
                  <Divider component="li" />
                  <ListItemButton onClick={() => handleClick(profile.stripe_account_id)}>
                    <ListItemText primary="Stripe Dashboard" />
                    <ArrowForwardIosIcon />
                  </ListItemButton>
                </React.Fragment>
              )}
            <Divider component="li" />
              <ListItemButton onClick={() => {
                showpasswordChange(true)
               setshowProfile(false)}} >
                <ListItemText primary="Password Change" />
                <ArrowForwardIosIcon/>
              </ListItemButton>
            <Divider component="li" />
              <ListItemButton onClick={() => {
                 setshowProfile(false)
                setShowSocialAccont(true)
                }} >
                <ListItemText primary="Link Social Accounts" />
                <ArrowForwardIosIcon/>
              </ListItemButton>
              <DeleteAccountRequest/>
              </CardContent>
              </Card>
           )}
          
            



         
      </div>
      )}
      <BottomBar/>
    </div>
  );
}

export default HelperProfile;
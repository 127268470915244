import React, { useState, useEffect } from 'react';
import {  Divider,ListItemButton,ListItemText,CardContent, Button,Typography,Box,Select, MenuItem , Card, CardHeader} from '@mui/material';
import UserApiService from '../../commonuser/UserApi';
import Loading from '../../NavBars/Loading';
import EarningsGraph from './EarningsGraph';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Withdrawls from './Withdrawls';
// import BottomBar from '../NavBars/BottomBar';
import { useSelector } from 'react-redux';
import OrgApi from '../OrgApi';
function OrgEarningComponent({AllEarnings,token,withdrawls}) {
    console.log({AllEarnings,token,withdrawls});
  const helper_type = useSelector(x => x.user.helper_type);
  const [tasks, setTasks] = useState([]);
  const [TotalEarnings, setTotalEarnings] = useState({});
  const [earningsOvertime, setearningsOvertime] = useState([]);
  const [ earningMonths,setearningMonths]= useState(3);
  
  const [loading, setLoading] = useState(false);
  const [showEarningsPage, setshowEarningsPage] = useState(true);
  const [showEarningsGraph, setshowEarningsGraph] = useState(false);
  const [showWithdrawls, setshowWithdrawls] = useState(false);


  const handleOptionChange = (event) => {
    setearningMonths(event.target.value);
  };
  useEffect(()=>{
    OrgApi.EarningsOverTime(token,earningMonths)
    .then(response => {setearningsOvertime(response.data)
      setshowEarningsGraph(true)
    }
    )

  },[token,earningMonths])


  const handlePayoutAllClick = () => {
    OrgApi.PayoutRemaining(token).then(response => {
        console.log(response.data)
        window.location.reload(false);
      })
  };
  

 

  return (
    <div>
    {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
{showEarningsPage && (
    <React.Fragment>
            
            <Card style={{backgroundColor: "#0dcaf000"}}
              sx={{ maxWidth: 800,margin: "auto",}}
              >
            <CardHeader
                action={
                <React.Fragment>
            <Button  
               disabled={AllEarnings.total_payout_amount_ready_to_withdraw <= 0}
              variant="text" 
              align="right"
              color="primary"
              onClick={() => handlePayoutAllClick()}>withdraw
            </Button>
                </React.Fragment>
                
            }
            ></CardHeader>
                <Typography align="center"variant="h5">{AllEarnings.total_payout_amount_ready_to_withdraw.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
                <Typography align="center" variant="subtitle1">Total Amount Ready to Withdraw</Typography>
            <CardContent>
                <Typography>
                    Overview
                </Typography>
            <Select fullWidth value={earningMonths} onChange={handleOptionChange} variant="standard">
                <MenuItem value="2">This Month</MenuItem>
                <MenuItem value="3">3 Months</MenuItem>
                <MenuItem value="6">6 Months</MenuItem>
                <MenuItem value="12">Year to Date</MenuItem>
            </Select>
            {showEarningsGraph &&<EarningsGraph data={earningsOvertime}/>}
            <Typography variant="h5">
                Earnings
            </Typography>
            <Divider sx={{ borderBottomWidth: '2px', borderColor: 'black' }} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Total Amount Ready to Withdraw</Typography>
                <Typography variant="body1">{AllEarnings.total_payout_amount_ready_to_withdraw.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
            </Box>
            <Box my={2} />
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Total Amount Pending Verification</Typography>
                <Typography variant="body1">{AllEarnings.total_payout_amount_pending_withdraw.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
            </Box>
            <Box my={2} />
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Total Earnings</Typography>
                <Typography variant="body1">{AllEarnings.total_earnings.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
            </Box>
            <Box my={2} />
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Total Payouts</Typography>
                <Typography variant="body1">{AllEarnings.total_payout_amount.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
            </Box>
            <Box my={2} />
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Total Booking Completed</Typography>
                <Typography variant="body1">{AllEarnings.total_bookings_completed}</Typography>
            </Box>
            <Box my={2} />
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
                <React.Fragment>
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            <ListItemButton disableGutters onClick={() => {
                 setshowEarningsPage(false)
                 setshowWithdrawls(true)
                }} >
                <ListItemText primary="Withdrawls" justifyContent="space-between"/>
                <ArrowForwardIosIcon/>
              </ListItemButton>
              </React.Fragment> 
              
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            </CardContent>
            </Card>
      </React.Fragment>

)}
{showWithdrawls && <Withdrawls withdrawls={withdrawls} setshowEarningsPage={setshowEarningsPage} setshowWithdrawls={setshowWithdrawls}/>}
        </React.Fragment>
      )}
      {/* <BottomBar/> */}
    </div>
  );
}

export default OrgEarningComponent;

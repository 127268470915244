import React,{useEffect} from 'react';
import {  
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import { useLocation } from 'react-router-dom';
import TaskIcon from '@mui/icons-material/Task';
import IsMobile from '../Styles/IsMobile';
import {useCookies} from 'react-cookie';
import HomeIcon from '@mui/icons-material/Home';
import { useSelector } from 'react-redux';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import ContactsIcon from '@mui/icons-material/Contacts';
const Footer = () => {
  const isMobile = IsMobile();
  const location = useLocation();
  const [userType] = useCookies(['UserType']);
  const [notifyconut, setnotifyconut] = React.useState(0);
  const [messagecount, setmessageconut] = React.useState(0);
  const notifications = useSelector(x => x.notify.notifications);
  const unreadmessages = useSelector(x => x.notify.unread_messages);
  useEffect(()=>{
    const NewNotifyStatusList = ["CREATED",'NOTIFICATION_SENT'];
    const new_notif = (notifications.length ? notifications.filter(notify => NewNotifyStatusList.includes(notify.notification_status)): [])
    // const OldNotifyStatusList = ["READ"];
    // const new_notif =   (notifications.length ? notifications.filter(notify => OldNotifyStatusList.includes(notify.notification_status)): [])

    setnotifyconut(new_notif.length)
  },[notifications])
  useEffect(()=>{
  

    setmessageconut(unreadmessages.length)
    console.log(unreadmessages);
  },[unreadmessages])
  const helperMobileList = () => (
     <React.Fragment>
  
          <IconButton
          
          sx={{display:'flex', flexGrow:1,  marginBottom:1,   flexDirection:'column' , color: location.pathname === '/org/users' ? 'grey' : 'white' }} 
          href='/org/users'
            >
            <ContactsIcon   fontSize="small" />
             <Typography fontSize={10} >Users</Typography>
          </IconButton>
          <IconButton 
          sx={{display:'flex', flexGrow:1, marginBottom:1,  flexDirection:'column' ,
           color: location.pathname === '/org/home' ? 'grey' : 'white' }} 
          href='/org/home'
          >
            <HomeIcon  fontSize="small" />
            <Typography fontSize={10} >Home</Typography>
          </IconButton>
          </React.Fragment>
  );
  return (
    <React.Fragment>
      {isMobile && (
        <React.Fragment>
          <div style={{ marginTop:'35' ,padding:40}}></div>
        <AppBar position="fixed"  sx={{ top: 'auto', bottom: 0 , background :"#131313", padding:1}}>
          
           <Toolbar sx={{display:'flex',flexGrow:1}}>
          { helperMobileList()}
          <IconButton
          sx={{display:'flex', flexGrow:1,  marginBottom:1,  
           flexDirection:'column' , 
            color: location.pathname === "/org/admin-profile" ? 'grey' : 'white' }} 
          href="/org/admin-profile"
          >
            <Badge badgeContent={notifyconut} color="secondary"></Badge>
            <PersonIcon fontSize="small"  />
            <Typography fontSize={10} >Profile</Typography>
          </IconButton>
          </Toolbar>
          
          
        </AppBar>
        </React.Fragment>

      )}
      
        </React.Fragment>

  );
};

const BottomBar = ({first_name,user_type}) => {
  return (
    <Footer first_name={first_name} user_type={user_type} />
  );
};

export default React.memo(BottomBar)

import React from 'react';

import GetAppIcon from '@mui/icons-material/GetApp';
import {Chip, Paper, Typography, IconButton } from '@mui/material';


function AttachmentList({attachments}) {
    const handleDownload = (url) => {
    window.open(url, '_blank');
    };
    return (  <div style={{flexGrow: 1}} >
      <Paper 
      sx={{ 
        padding:1,
  }} 
    elevation={0}
    >
        {attachments.map((attachment, index) => (
<React.Fragment>
        <Chip 
        key={index}
        sx={{p:1,ml:1}}
        
        label={attachment.name.slice(0, 50)}
        onClick={()=>handleDownload(attachment.url)}
        />
        </React.Fragment>
        ))}
      </Paper>
    </div>);
}

export default AttachmentList;
import React, { useState } from 'react';
import {Box,Divider,CardContent,IconButton, Accordion,Button, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TaskAPi from '../../TaskApi';
function QuotesList({task,token,setShowTaskDetail,setshowTaskOffers}) {
    const [quoteAcceptError, setQuoteAcceptError] = useState({});
    
    const acceptQuote = (quote_id) => {
    TaskAPi.QuoteAccept(token, quote_id)
      .then((resp) => {
        window.location.reload(false);
      })
      .catch((error) => {
        const { data } = error.response;
        const errorFields = Object.keys(data);
        const newErrors = {};
        errorFields.forEach((field) => {
          const [errorMessage] = data[field];
          newErrors[field] = errorMessage;
        });
        setQuoteAcceptError(newErrors);
      });
  };
  const handleGoBack = (event) => {
    event.preventDefault();
    setShowTaskDetail(true)
    setshowTaskOffers(false)
  }

    return ( <div>
        <Box>
        <IconButton sx={{p:3}} onClick={handleGoBack}>
          <ArrowBackIcon/>
        </IconButton>
        <Box  sx={{  alignItems:"center",display:"flex" , justifyItems:'center',justifyContent:'center'}} >
           
           <Typography sx={{ml:"30"}} variant="h4">Available Offers</Typography>
        </Box>
        <Divider sx={{ bgcolor: "secondary.light" }} component="p" />
        <CardContent  style={{backgroundColor: "#0dcaf000"}} sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}>
        {
                task.quotes.length === 0 && <div>No Offers Found</div>
        }
        {
          task.quotes.length >= 3 && task.status === "CREATED" && (
            <React.Fragment>
              <Typography variant="h6">Max Offers Reached</Typography>
              <Typography variant="body1">Please Accept one of the following:</Typography>
            </React.Fragment>
          )
        }
        </CardContent>
        <Box sx={{flexGrow: 1}} p={2} >
           
          {task.quotes.map((quote, index) => (
            <Accordion style={{backgroundColor: "#0dcaf000"}} defaultExpanded={true} key={index}>
              <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
                <Typography><strong>{quote.helper_name} has a offer for you</strong> </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{display:"flex",flexGrow:1}}>
                <Box  sx={{display:"flex",flexGrow:1}}>
                <Typography sx={{p:1}}><strong>Total inc VAT:</strong> {quote.total_price_inc_vat.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
                {/* {quote.price_hr_inc_vat > 0&& <Typography sx={{p:1}} ><strong>Price/hr inc VAT:</strong> {quote.price_hr_inc_vat}</Typography> } */}
                </Box>
                { quote.status === "ACCEPTED" && "Offer Accepted" }
                { quote.status === "CREATED" && <Button sx={{display:"flex",flexGrow:1,background :"green"}} variant="contained"  onClick={() => acceptQuote(quote.id)}>Accept</Button>}
                </Box>
                {quoteAcceptError.message && (
                  <Typography color="error">
                    {quoteAcceptError.message}
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
          </Box>
        </Box>
    </div> );
}

export default QuotesList;
import React from 'react';
import moment from "moment";

import {Box, Accordion, AccordionSummary, AccordionDetails, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function OnSpotBookings({onsbk,token}) {
  const dateConvert = (ds) =>{
        return  moment(ds).format('MMMM Do YYYY, h:mm:ss a');
    }

    return ( <div>     
        <Box sx={{flexGrow: 1}} p={2} >
          {onsbk.map((onsbk, index) => (
            <Accordion style={{backgroundColor: "#0dcaf000"}}   defaultExpanded={true} key={index}>
              <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
                <Typography><strong>You requested additional time for {onsbk.onsbk_quotes_data.total_no_hours} Hours</strong> </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box  sx={{display:"flex",flexGrow:1,flexDirection:'column'}}>
                <Typography sx={{p:1}}><strong>title:</strong> {onsbk.title}</Typography>
                <Typography sx={{p:1}}><strong>Description:</strong> {onsbk.description}</Typography>
                <Typography sx={{p:1}} ><strong>Start Time:</strong>  {dateConvert(onsbk.start_time)}</Typography>
                <Typography sx={{p:1}}><strong>End Time:</strong> {dateConvert(onsbk.end_time)} </Typography>
                <Typography sx={{p:1}} ><strong>Price/hr inc VAT:</strong> {onsbk.price_hr_inc_vat}</Typography>
                <Typography sx={{p:1}} ><strong>Total Price inc VAT:</strong> {onsbk.onsbk_quotes_data.total_price_inc_vat}</Typography>
                <Typography sx={{p:1}} ><strong>Total Hours:</strong> {onsbk.onsbk_quotes_data.total_no_hours}</Typography>
                {
                  onsbk.status === "USER_ACCEPTED" && (
                     <Typography  sx={{p:1}}><strong>Status:</strong> Accepted</Typography>
                  )
                } 
                </Box>
                
                
                {
                  onsbk.status === "HELPER_INITATED" && (
                  <Typography><strong>Status:</strong> Request Sent</Typography>
                  )
                }
                {
                  onsbk.status === "CANCELLED" && (
                  <Typography><strong>Status:</strong> Cancelled</Typography>
                  )
                }
              </AccordionDetails>
            </Accordion>
          ))}
          </Box>
    </div> );
}

export default OnSpotBookings;
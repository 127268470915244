import  { useEffect } from 'react';
import {useCookies} from 'react-cookie';
import { useNavigate } from "react-router-dom";

function useCheckAuth() {
    let navigate = useNavigate()
    const [token] = useCookies(['authtoken','UserType']);
    useEffect(() => {
        if(!token['authtoken']) {
            navigate('/')           
        }
        if(!token['UserType']) {
            navigate('/')           
        }
    }, [token,navigate])

    return token && token.authtoken;
}

export default useCheckAuth;
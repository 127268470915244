import React, { useState } from 'react';
import FeedbackForm from './FeedbackForm';
import { Dialog ,Box, Button ,DialogTitle } from '@mui/material';

function FeedbackPopup({token,task,opened}) {
  const [isOpen, setIsOpen] = useState(opened);

  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleFeedbackSubmit = () => {
    setIsOpen(false);
  }

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Leave Feedback
      </Button>
      <Dialog open={isOpen} variant="contained" onClose={handleClose}>
        <DialogTitle>Please Submit your Feedback</DialogTitle>
            <FeedbackForm onFeedbackSubmit={handleFeedbackSubmit} task={task} token={token} handleClose={handleClose}/>
      </Dialog>    
    </Box>
  );
}

export default FeedbackPopup;

import React, { useEffect, useState } from 'react';
import {Badge,Chip,MenuItem,Menu,IconButton, CardContent,Card,Typography, Box,Divider,ListItemText,ListItemButton, CardHeader ,Grid} from "@mui/material";
import moment from "moment";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import QuotesList from './QuotesList';
import PaymentStep from './PaymentStep';
import BoookingsLists from './BookingsLists';
import ReScheduleBookings from './RescheduleBookings';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UpdateTaskTime from './UpdateTaskTime';
import UpdateTask from './UpdateTaskView';
import Canceltask from './CancelTask';
import FeedbackPopup from '../Feedback/FeedbackPopup';
import UnreadMessages from './UnreadMessages';
import GoBack from '../../../../commonuser/GoBack';
import AttachmentList from './AttachmentList';
import PaymentStatus from '../../../status/PaymentStatus';
import Timer from './Timer';

const TaskDetail = ({task,token,otp}) => {
  const taskData=task
  const created = moment(task.created_at).fromNow();
  const start_time = moment(task.start_time).format('MMM Do  YYYY ddd h:mm A');
  const end_time = moment(task.end_time).format('MMM Do YYYY ddd h:mm A');
  const quotes = taskData.quotes
  const cancelTaskStatuses = ["CANCELLED_BY_HELPER","CANCELLED_BY_SEEKER","TASK_IN_PROGRESS","TASK_COMPLETED"]
  // const RescsheduleBookingStatuses = ["BOOKED"]
  const [openReschuleTask, setOpenReschuleTask] = useState(false);
  const [showUpdateTask, setShowUpdateTask] = useState(false);
  const fiveMinutesFromNow = new Date();
  fiveMinutesFromNow.setMinutes(fiveMinutesFromNow.getMinutes() + 5);

  useEffect(()=>{
    console.log(task.external_id);
  },[])

  

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };  
  const AcceptedQuote = quotes.length ? quotes.filter(task => task.status === 'ACCEPTED')[0]
   : {};
  const [showTaskDetail, setShowTaskDetail] = useState(true);
  const [showTaskOffers, setshowTaskOffers] = useState(false);
  const [showTaskBookings, setshowTaskBookigns] = useState(false);
  const [showTaskCancel, setshowTaskCancel] = useState(false);
  const [showRescheduleTaskBookings, setshowRescheduleTaskBookings] = useState(false);
  const ShowOffers=()=>{
    setShowTaskDetail(false);
    setshowTaskOffers(true);
    };
  const reschedule_action =  task.reschedule_data &&  task.reschedule_data.some((bk) => bk.status === 'RESCHEDULE_REQUESTED_BY_HELPER');
  const onspot_booking_action =  task.onspot_booking_data &&  task.onspot_booking_data.some((bk) => bk.status === 'HELPER_INITATED');
  const accepted_quote = task?.quotes.find(quote => quote.status === "ACCEPTED");
  return (
    <div>
    {showTaskDetail && 
    <React.Fragment>
    <Box sx={{flexGrow: 1}} p={2}>
      <GoBack/>
      {taskData.status ==="QUOTE_ACCEPTED" && taskData.payment_data == null  &&
      <PaymentStep 
        id={AcceptedQuote.id} 
        type={"dbk"}
        title={taskData.title}
        task_type={taskData.task_type} 
        total_price_inc_vat={AcceptedQuote.total_price_inc_vat} 
        total_no_hours={AcceptedQuote.total_no_hours} 
        open={true}
        helper_name={accepted_quote?.helper_name}
        disable_offers={task.disable_offers}
        taskId={task.external_id}
      />
       }
       {taskData.ratings_data?.message ==="WAITING_FOR_RATINGS"  &&
      <FeedbackPopup task={task.id} opened={true} token={token}/> 
       }
        {taskData.status === "QUOTE_ACCEPTED" && taskData.payment_data !== null && 
        <React.Fragment>
        {
         taskData.payment_data.status === "PAYMENT_VERIFICATION_FAILED"  &&
          <PaymentStep 
            id={AcceptedQuote.id} 
            type={"dbk"}
            title={task.title}
            task_type={task.task_type} 
            total_price_inc_vat={AcceptedQuote.total_price_inc_vat} 
            total_no_hours={AcceptedQuote.total_no_hours} 
            open={true}
            helper_name={accepted_quote?.helper_name}
            disable_offers={task.disable_offers}
            taskId={task.external_id}
          />
        }
        </React.Fragment>
       }
      <Card
      style={{backgroundColor: "#0dcaf000"}}
        // sx={{padding:3, maxWidth: 1000,margin: "auto",}}
        elevation={1}
          >
        <CardHeader
        title={<Typography sx={{ml:3,display:"flex",justifyContent:'center'}} variant="h5">Task Details</Typography>}
        
        action={
          <React.Fragment>
            {cancelTaskStatuses.includes(task.status) ? (
          null
        ) : (
          <IconButton aria-label="kebab menu"  onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
        )}
        { task.conversation && task.conversation.chat_enabled  &&  <CardHeader component={UnreadMessages} count={task.conversation.no_unread_messages} task_id={task.external_id}></CardHeader> }
          </React.Fragment>
          
        }
            >
          </CardHeader>

          <Menu
            id="kebab-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
           >
        
        {cancelTaskStatuses.includes(task.status) ? (
          null
        ) : (
          <MenuItem sx={{ color: "error" }} onClick={() => setshowTaskCancel(true)}>
            <Typography color="error">Cancel Task</Typography>
            </MenuItem>
        )}
        {cancelTaskStatuses.includes(task.status) ? (
          null
        ) : (
          <MenuItem onClick={() => setShowUpdateTask(true)}>Update Task</MenuItem>
        )}
        {/* {RescsheduleBookingStatuses.includes(task.status) ? (
          <MenuItem onClick={() => setOpenReschuleTask(true)}>Reschedule Booking</MenuItem>
        ) : (
          null
        )} */}
        {task?.booking.available_actions?.reschedule_action && (
          <MenuItem onClick={() => setOpenReschuleTask(true)}>Reschedule Booking</MenuItem>
        ) 
        }
        <UpdateTask task={task} token={token}  showUpdateTask={showUpdateTask} setShowUpdateTask={setShowUpdateTask}/>
        <UpdateTaskTime taskId={task.id} old_start_time={task.start_time} old_end_time={task.end_time} openReschuleTask={openReschuleTask} setOpenReschuleTask={setOpenReschuleTask} />          
        <Canceltask taskId={task.id} token={token} showTaskCancel={showTaskCancel} setshowTaskCancel={setshowTaskCancel}/>
      </Menu>
      <CardContent>
        {otp && (
        <React.Fragment>
        <Typography align='center' variant="subtitle1">OTP</Typography>
      <Typography align='center' sx={{mb:2}}variant="h5"><strong>{otp}</strong></Typography>
      </React.Fragment>
      )}
      <Typography sx={{mb:2}}variant="h5">{task.title}</Typography>
      

      
      {task.task_type==="IN_PERSON" ? (<Typography sx={{ fontSize:15  }}> <strong>In Person Appoinment</strong></Typography>) :(<Typography sx={{ fontSize:15 }}> <strong>Online Appoinment</strong></Typography>)}
      <Typography sx={{ fontSize:12 }}>Posted {created}</Typography>
      <Divider>Task Details</Divider>
      <Grid container spacing={0.3}>
        <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Category:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{task.parent_category_name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1"><strong>Sub Category:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{task.category_name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>Start Time: </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{start_time}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>End Time:  </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{end_time}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>Languages Selected:  </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {task.languages_selected && JSON.parse(task.languages_selected).join(', ')}
            </Typography>
        </Grid>
      </Grid>
      <Divider>Description</Divider>
      <Typography variant="subtitle1"> {task.description}</Typography>
    {task.additional_info && <Typography variant="subtitle1"><strong>Additional Info: </strong>{task.additional_info}</Typography> }
      <Divider>Contact Details</Divider>
      <Grid container spacing={0.3}>
        <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Phone:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{task.phone_number}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1"><strong>Pincode:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{task.pincode}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>Location: </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{task.city}</Typography>
        </Grid>
      </Grid>
       {task.attachments?.length >0  && <Divider>Attachments</Divider> }
      <Grid container spacing={0.3}>
        {task.attachments && task.attachments.length > 0  && <AttachmentList attachments={task.attachments} />}
      </Grid>
    {AcceptedQuote && taskData.payment_data !== null &&
     <React.Fragment>
        { task.payment_data.status !== "PAYMENT_VERIFICATION_SUCESS" && 
        <React.Fragment>
          <Divider>Accepted Offer</Divider>
          <Grid container spacing={0.3}>
        <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Price:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1"> {AcceptedQuote.total_price_inc_vat.toLocaleString("en-US", {style:"currency", currency:"EUR"})} (Inc Vat)</Typography>
        </Grid>
        <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Total Hours:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1"> {AcceptedQuote.total_no_hours}</Typography>
        </Grid>
          </Grid>
        </React.Fragment>
        }

        {/* <div className="App">
        <Timer startTime={fiveMinutesFromNow.toISOString()} />
        </div> */}
       
        {task.payment_data.status === "PAYMENT_INITIATED" && (
          <React.Fragment>
            <Box>
               <Grid container spacing={0.3}>
                <Grid  item xs={4}>
                  <Typography  variant="subtitle1"><strong>Payment Status:</strong></Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="subtitle1">Waiting For Confirmation</Typography> 
                  <Timer startTime={task.payment_data.updated_at} />
              </Grid>

               </Grid>
        
        </Box>
        </React.Fragment>
        )}
        {task.payment_data.status === "PAYMENT_VERIFICATION_FAILED" && <Typography variant="subtitle1">Payment Status: Payment Failed</Typography> }
     </React.Fragment>
    }
 
     {task.total_payment_data && 
     <React.Fragment>
     <Divider>Payment Details</Divider>
     <Grid container spacing={0.3}>
        <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Price:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1"> {task.total_payment_data.total_price_inc_vat.toLocaleString("en-US", {style:"currency", currency:"EUR"})} (Inc Vat)</Typography>
        </Grid>
        <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Total Hours:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1"> {AcceptedQuote.total_no_hours}</Typography>
        </Grid>
        {task.payment_data && 
        <React.Fragment>
        <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Payment Status:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1"> {<PaymentStatus status={task.payment_data.status}/>}</Typography>
        </Grid>
        </React.Fragment>}
          </Grid>
      </React.Fragment>}
      </CardContent>
      {!task.disable_offers && (
        <React.Fragment>
          <CardContent>
        <Divider sx={{ bgcolor: "secondary.light" }} component="p" />
          <ListItemButton  >
            <ListItemText  onClick={ShowOffers}  primary={
              task.status === "CREATED" && task.quotes.length > 0 ? (
                <React.Fragment>
              <Chip   label="Action Required" size="small" color="error" variant="outlined" > </Chip> 
              <Badge sx={{p:0.5}} badgeContent={task.quotes.length} color="error">  </Badge>  
              <Typography>Offers</Typography>
              </React.Fragment>)
              : "Offers" } />
            <ArrowForwardIosIcon/>
          </ListItemButton>
        </CardContent>

        </React.Fragment>
      )}
       
        <CardContent>
        <Divider sx={{ bgcolor: "secondary.light" }} component="p" />
          <ListItemButton  >
             {/* eslint-disable-next-line */}
            <ListItemText 
            // primary="Bookings" 
            primary={ onspot_booking_action ?
            (<React.Fragment>
                <Chip   label="Action Required" size="small" color="error" variant="outlined" > </Chip> 
                <Typography>Bookings</Typography></React.Fragment> )
             : "Bookings" }
             // eslint-disable-next-line
            onClick={()=>(setShowTaskDetail(false), setshowTaskBookigns(true))} />
            <ArrowForwardIosIcon/>
          </ListItemButton>
        </CardContent>
        <CardContent>
        <Divider sx={{ bgcolor: "secondary.light" }}  component="p" />
          <ListItemButton  >
             
             {/*  eslint-disable-next-line */}
            <ListItemText primary={ reschedule_action ?
            (<React.Fragment>
                <Chip   label="Action Required" size="small" color="error" variant="outlined" > </Chip> 
                <Typography>Reschedules</Typography></React.Fragment> )
             : "Reschedules" }
             // eslint-disable-next-line
            onClick={()=>(setShowTaskDetail(false), setshowRescheduleTaskBookings(true))}  />
            <ArrowForwardIosIcon/>
          </ListItemButton>
        </CardContent>
        <Divider sx={{ bgcolor: "secondary.light" }} component="p" />
      </Card>
    </Box>
    </React.Fragment>
}

    {showTaskOffers && 
    <React.Fragment>
    <QuotesList 
    task={taskData} 
    token={token} 
    setShowTaskDetail={setShowTaskDetail} 
    setshowTaskOffers={setshowTaskOffers}
    />
    </React.Fragment>
    }
     {showTaskBookings && 
    <React.Fragment>
    <BoookingsLists task={taskData} token={token}  setShowTaskDetail={setShowTaskDetail} setshowTaskBookigns={setshowTaskBookigns}/>
    </React.Fragment>
    }

    {showRescheduleTaskBookings && 
    <React.Fragment>

     <ReScheduleBookings task={taskData} token={token}  setShowTaskDetail={setShowTaskDetail} setshowRescheduleTaskBookings={setshowRescheduleTaskBookings} />
    </React.Fragment>
    }
    
    
</div>
  );
};

export default TaskDetail;

import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function GoBack() {
    const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1)
  };

  return (
    <IconButton onClick={handleGoBack}>
        <ArrowBackIcon/>
    </IconButton>
  );
}

export default GoBack;

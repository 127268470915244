import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Route ,Routes ,  BrowserRouter } from "react-router-dom";
import {CookiesProvider} from 'react-cookie';
import LoginForm from './components/commonuser/LoginForm';
import UserProfile from './components/user/UserProfile';
import HelperProfile from './components/helpercomponent/HelperProfile';
import JobList from './components/marketplacev2/Jobs/JobsList';
import PaymentSucess from './components/payment/paymentSuccess';
import PaymentCancel from './components/payment/paymentCancel';
import PasswordResetForm from './components/commonuser/PasswordResetForm';
import ResendEmailVerification from './components/commonuser/ResendEmailVerification';
import Checkout from './components/payment/Payment';
import KycSuccess from './components/helpercomponent/KycSuccess';
import { Provider } from 'react-redux';
import store from './components/app/store';
import {  ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import theme from './components/Styles/theme';
import UserRegister from './components/user/UserRegister';
import PasswordReset from './components/user/PasswordReset';
import HelperRegister from './components/helpercomponent/HelperRegister';
import VerifyEmail from './components/commonuser/VerifyEmail';
import TaskDetailView from './components/marketplacev2/Tasks/TaskDetailsView';
import BookingDetailView from './components/marketplacev2/Bookings/BookingDetailView';
import JobDetailView from './components/marketplacev2/Jobs/JobDetailView';
import TaskForm from './components/marketplacev2/Tasks/TaskForm';
import { GoogleOAuthProvider } from '@react-oauth/google';
import JobsTabView from './components/marketplacev2/Jobs/JobsTabView';
import TaskTabView from './components/marketplacev2/Tasks/TaskTabView';
import BookingTabView from './components/marketplacev2/Bookings/BookingTabView';
import Chat from './components/marketplacev2/webChat/Chat';
import EarningsComponentMobile from './components/helpercomponent/EarningsComponentMobile';
import HelperHome from './components/HelperHome/HelperHome';
import LoadingPage from './components/commonuser/loadingpage/LoadingPage';
import NotifyAllMob from './components/notify/NotifyAllMob';
import UnreadMessagesMob from './components/notify/UnreadMessagesMob';
import OrganizationAdmin from './components/OrganisationPortal/OrganizationUsers';
import OrgEarningsPage from './components/OrganisationPortal/OrgEarningsPage';
import OrgAdminProfile from './components/OrganisationPortal/OrgAdminProfile';
import UserStats from './components/OrganisationPortal/HelperStats/UserStats';
import HelperPreference from './components/helpercomponent/HelperPreference';
import { GOOGLE_CLIENT_ID } from './config';
import PaymentRedirectPage from './components/payment/PaymentRedirectPage';
import NotFoundPage from './components/NavBars/NotFoundPage';
import LandingPage from './components/commonuser/loadingpage/LandingPage';
import { inject } from '@vercel/analytics';
import ReactGA from "react-ga4";
import { GA_TRACKING_ID } from './config';

if (GA_TRACKING_ID) {
ReactGA.initialize(GA_TRACKING_ID);
}


function Router() {



    if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
      navigator.serviceWorker.register('/sw.js').then(function(registration) {
       
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
      }, function(err) {
        console.log('ServiceWorker registration failed: ', err);
      });
    });
  }   
 

  return(
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <CookiesProvider>
    <BrowserRouter>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Routes>
        <Route exact path = "/"  element ={<LandingPage />}/>
        <Route exact path = "/login"  element ={<LoginForm />}/>
        <Route exact path = "/register/user"  element ={<UserRegister />}/>
        <Route exact path = "/register/helper"  element ={<HelperRegister />}/>
        <Route exact path = "/auth/registration/account-confirm-email/:key"  element ={<VerifyEmail />}/>
        <Route exact path = "/auth/passwordreset"  element ={<PasswordReset />}/>
        <Route exact path = "/auth/password/reset/confirm/:uid/:token"  element ={<PasswordResetForm />}/>
        <Route exact path = "/auth/registration/resend-email"  element ={<ResendEmailVerification />}/>


        <Route exact path = "/profile/user"  element ={<UserProfile />}/>
        <Route exact path = "/profile/helper"  element ={<HelperProfile />}/>
        <Route exact path = "/kyc/success"  element ={<KycSuccess />}/>

        <Route exact path = "/user/tasks"  element ={<TaskTabView />}/>
        <Route exact path = "/user/task/create"  element ={<TaskForm />}/>
        <Route exact path = "/user/task/:taskId"  element ={<TaskDetailView />}/>
        
        <Route exact path = "/helper/home"  element ={<HelperHome />}/>
        <Route exact path = "/helper/jobs"  element ={<JobsTabView />}/>
         <Route exact path = "/helper/jobsv2"  element ={<JobList />}/>
        <Route exact path = "/helper/job/:jobId"  element ={<JobDetailView />}/>
        <Route exact path = "/helper/earnings"  element ={<EarningsComponentMobile />}/>
        <Route exact path = "/helper/map"  element ={<HelperPreference />}/>
        {/* <Route exact path = "/payment/:quote_id"  element ={<StripeCheckout />}/> */}
        <Route exact path = "/payment/success"  element ={<PaymentSucess />}/>
        <Route exact path = "/payment/cancel"  element ={<PaymentCancel />}/>
        <Route exact path = "/payment/:type/:Id"  element ={<Checkout />}/>
        <Route exact path = "/payment-redirect"  element ={<PaymentRedirectPage/>}/>
        

        <Route exact path = "/Booking"  element ={<BookingTabView />}/>
        <Route exact path = "/Booking/:bookingId"  element ={<BookingDetailView />}/>
        <Route exact path = "/message/:taskId"  element ={<Chat />}/>
        <Route exact path = "/notify"  element ={<NotifyAllMob/>}/>
        <Route exact path = "/messages"  element ={<UnreadMessagesMob/>}/> 
        
        <Route exact path = "/org/users"  element ={<OrganizationAdmin/>}/> 
        <Route exact path = "/org/home"  element ={<OrgEarningsPage/>}/> 
        <Route exact path = "/org/admin-profile"  element ={<OrgAdminProfile/>}/> 
        <Route exact path = "/org/stats/:userId"  element ={<UserStats />}/>


        <Route exact path = "*"  element ={<NotFoundPage />}/>
        
      </Routes>
    
  
</GoogleOAuthProvider>
    </BrowserRouter>
    </CookiesProvider>
    </ThemeProvider>
  )

}



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
   <Provider store={store}>
  {/* <React.StrictMode> */}
    <Router />
   {/* </React.StrictMode> */}
   </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
inject();
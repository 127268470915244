import { SET_USER_ACCESS,SET_HELPER_KYC ,SET_HELPER_TYPE, SET_USER_CREDIT,SET_USER_CUBES, SET_USER_ID, SET_USER_EMAIL,SET_USER_FIRST_NAME, SET_USER_LAST_NAME , RESET_USER_REDUCER} from './userActions';

const initialState = {
  cubes:'',
  credit: '',
  id: '',
  email: '',
  first_name:'',
  last_name: '',
  access_enabled: false,
  helper_type: '' ,
  helper_kyc:  true,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HELPER_TYPE:
      return {
        ...state,
        helper_type: action.payload,
      };
    case SET_HELPER_KYC:
      return {
        ...state,
        helper_kyc: action.payload,
      };
    case SET_USER_CUBES:
      return {
        ...state,
        cubes: action.payload,
      };
    case SET_USER_CREDIT:
      return {
        ...state,
        credit: action.payload,
      };
    case SET_USER_ID:
      return {
        ...state,
        id: action.payload,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_USER_FIRST_NAME:
      return {
        ...state,
        first_name: action.payload,
      };
    case SET_USER_LAST_NAME:
      return {
        ...state,
        last_name: action.payload,
      };
    case SET_USER_ACCESS:
      return {
        ...state,
        access_enabled: action.payload,
      };
    case RESET_USER_REDUCER:
      return initialState ;
    default:
      return state;
  }
};

export default userReducer;

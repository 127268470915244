import { BACKEND_URL ,authheaders, basic_headers } from "../../config"
import axios from 'axios';
export default class UserApiService {
    static UserRegister(body) {
       const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/signup/seeker/`,
        headers: basic_headers,
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)
    }
    static HelperRegister(body) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/signup/helper/`,
        headers: basic_headers,
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)
    }

    
    static EmailVerification(body,key) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/registration/account-confirm-email/${key}`,
        headers: basic_headers,
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)
    }

    static PasswordReset(body) {
      const options = {
        method: 'post',
        url: `${BACKEND_URL}/auth/password/reset/`,
        headers: basic_headers,
        data :JSON.stringify(body)
      }
       return axios(options).then(resp => resp.data)
    }

    static ResendEmailVerification(body) {
      const options = {
        method: 'post',
        url: `${BACKEND_URL}/auth/registration/resend-email/`,
        headers: basic_headers,
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)

    }

    static PasswordResetForm(body) {
      const options = {
        method: 'post',
        url: `${BACKEND_URL}/auth/password/reset/confirm/`,
        rejectUnauthorized: false,
        headers: basic_headers,
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)

    }


    static LoginUser(body) {
        const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/login/`,
        rejectUnauthorized: false,
        withCredentials: false,
        crossdomain: true ,
        headers: basic_headers,
        data :JSON.stringify(body)
      }
      console.log(options);
      return axios(options).then(resp => resp.data)
    }

    static GoogleLoginUser(body) {
        const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/google/`,
        rejectUnauthorized: false,
        withCredentials: false,
        crossdomain: true ,
        headers: basic_headers,
        data :JSON.stringify(body)
      }
      console.log(options);
      return axios(options).then(resp => resp.data)
    }
    static FacebookLoginUser(body) {
        const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/facebook/`,
        rejectUnauthorized: false,
        withCredentials: false,
        crossdomain: true ,
        headers: basic_headers,
        data :JSON.stringify(body)
      }
      console.log(options);
      return axios(options).then(resp => resp.data)
    }

    static GoogleConnectUser(token,body) {
        const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/google/connect/`,
        rejectUnauthorized: false,
        withCredentials: false,
        crossdomain: true ,
        headers: authheaders(token),
        data :JSON.stringify(body)
      }
      console.log(options);
      return axios(options).then(resp => resp.data)
    }

    static FacebookConnectUser(token,body) {
        const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/facebook/connect/`,
        rejectUnauthorized: false,
        withCredentials: false,
        crossdomain: true ,
        headers: authheaders(token),
        data :JSON.stringify(body)
      }
      console.log(options);
      return axios(options).then(resp => resp.data)
    }

    static LinkedAccounts(token,body) {
        const options = {
        method: 'get',
        url:`${BACKEND_URL}/auth/socialaccounts/`,
        rejectUnauthorized: false,
        withCredentials: false,
        crossdomain: true ,
        headers: authheaders(token),
      }
      console.log(options);
      return axios(options).then(resp => resp.data)
    }

    static  GetUser(token) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/auth/user/`,
        headers: authheaders(token),
      }
      const data = axios(options).then(resp => resp.data)
      return  data
    }

    static GetUserProfile(token) {
        const options = {
        method: 'get',
        url:`${BACKEND_URL}/auth/profile/seeker/`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data
    }

    static DeletionRequest(token) {
        const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/delete-request/`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data
    }
    

    static GetHelperProfile(token,body) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/auth/profile/helper`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data
    }

    static PasswordChangeForm(token,body) {
      
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/password/change/`,
        headers: authheaders(token),
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)

    }


    static GetStripeDashboardUrl(token,body) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/profile/payment_dashbaord/`,
        headers: authheaders(token),
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)
    }

    static KycFlow(token,body) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/helper/kyc_flow/`,
        headers: authheaders(token) ,
        data :JSON.stringify(body)
      }

     return axios(options).then(resp => resp.data)

    }


    static AllEarnings(token,body) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/payment/earnings/`,
        headers: authheaders(token),
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)

    }

    static PayoutBooking(token,booking_id) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/payment/payout/`,
        headers:authheaders(token),
        data :JSON.stringify({"booking_id":booking_id})
      }
      return axios(options).then(resp => resp.data)

    }

    static PayoutAllBooking(token) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/payment/payoutall/`,
        headers:authheaders(token),
        data :JSON.stringify({})
      }
      return axios(options).then(resp => resp.data)

    }

    static EarningsTotal(token) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/payment/total-earnings`,
        headers:authheaders(token),
      }
      return axios(options).then(resp => resp.data)

    }

    static EarningsOverTime(token,months) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/payment/earnings-over-time/?months=${months}`,
        headers:authheaders(token),
      }
      return axios(options).then(resp => resp.data)

    }
    static Withdrawls(token) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/payment/withdrawls/`,
        headers:authheaders(token),
      }
      return axios(options).then(resp => resp.data)

    }
}
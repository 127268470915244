import React, { useState,useEffect } from 'react';
import useCheckAuth from '../../../commonuser/CheckAuth';
import JobsApi from '../JobsAPi';
import { IconButton,Pagination,Stack,  Card,Box,CardContent, Grid,Typography, Divider ,CardActionArea, CardHeader} from '@mui/material';
import Loading from '../../../NavBars/Loading';
import moment from "moment";
import IsMobile from '../../../Styles/IsMobile';
import TruncatedText from './TruncatedText';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
function JobTab({status}) {
    const token = useCheckAuth();
    const [jobs, setJobs] = useState([]);
    const [page, setPage] = useState(1);
    const fontSize = 15;
    const [loading, setLoading] = useState(true);
    const [jobsPerPage,] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const isMobile=IsMobile()
    // eslint-disable-next-line
    const [error, setError] = useState(false);
    const dateConvert = (ds) =>{
        return  moment(ds).format('MMM Do YY, HH:mm');
    }
  //   const handleJobsPerPageChange = (event) => {
  //   setJobsPerPage(event.target.value);
  //   setPage(1);
  //   // setJobsToShow(jobs.slice(0, event.target.value));
  // };
    const handleChangePage = (event, value) => {
    setPage(value);

  };
    const fetchJobs = async () => {
    setLoading(true);
    try {
      const  response = await JobsApi.JobList(token ,status,page,jobsPerPage)
      setJobs(response.results);
      setTotalPages(Math.ceil(response.count / jobsPerPage));
      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  } 
  useEffect(() => {
    fetchJobs();
  // eslint-disable-next-line
  }, [status, page,jobsPerPage]);
  const handleLike= (id,action)=>{
        JobsApi.jobInteractions(token ,id,action)
        .then( resp =>{
          if (action==="like") {
             setJobs(jobs =>
                  jobs.map(job => {
                    if (job.id === id) {
                      return { ...job, liked: true  ,disliked: false};
                    }
                    return job;
                  })
                );
          }
          if (action==="dislike") {
            setJobs(jobs =>
                  jobs.map(job => {
                    if (job.id === id) {
                      return { ...job, liked: false  ,disliked: true};
                    }
                    return job;
                  })
                );
          }
        }
          
        )
    }
    return ( 
    <div>
       {loading ? (
        <Loading />
      ) : (
        <div>
        {jobs.length === 0 ? (<Typography style={{ textAlign: 'center', marginTop: '50px' }}  variant="body1">No Jobs found.</Typography>): (
            <div>
              <Box 
        justifyContent= "space-evenly"
        alignItems="center"
         sx={{
        display: 'flex',
        justifyContent:'center',
        flexDirection:'column'
        }}
        >
            <Grid 
            direction="column"
            container 
            justifyContent= "center"
            alignItems="stretch"
            md={8} 
            lg={8}
            xl={8}
            spacing={2}>
            {jobs.map((job) => (
            <Grid 
            key={job.id} 
            item                
            xs={12} 
            md={8}  
            justifyContent= "space-evenly"
            alignItems="stretch">
            <Card style={{backgroundColor: "#0dcaf000"}} elevation={1} >
              <CardHeader
               title={<Typography sx={{ml:3,display:"flex",justifyContent:'center'}} variant="h6">{job.title}</Typography>}
              action={
                <React.Fragment>
                <IconButton
                  color={job.liked ? 'primary' : 'default'}
                  onClick={()=>(handleLike(job.id,"like"))}
                  aria-label="Like"
                >
                  {job.liked ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}
                </IconButton>
                <IconButton
                  color={job.disliked ? 'primary' : 'default'}
                  onClick={()=>(handleLike(job.id,"dislike"))}
                  aria-label="Dislike"
                >
                  {job.disliked ? <ThumbDownAltIcon /> : <ThumbDownOffAltIcon />}
                </IconButton>
                </React.Fragment>
              }
              >

              </CardHeader>
              <CardActionArea  href={`/helper/job/${job.id}`}>
                <CardContent>
                    <Grid  item xs={10} 
                    md={8}
                    >
                </Grid>
                <Divider component="p"/>
                <Grid container spacing={0.3}>
                  <Grid  item xs={8}>
                    {job.task_type==="IN_PERSON" ? (
                  <Typography sx={{ fontSize:fontSize  }}> <strong>In Person Appoinment </strong></Typography>
                  ) :
                  (<Typography sx={{ fontSize:fontSize   }}> <strong>Online Appoinment</strong></Typography>)}

                  <Typography sx={{ fontSize:10 ,mb:2  }}> Posted {moment(job.created_at).fromNow()}</Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid  item xs={6}>
                    <Typography sx={{ fontSize:12 }} variant="body1"><strong>{dateConvert(job.start_time)}</strong></Typography>
                    <Typography sx={{ fontSize:11 ,mb:2 }} variant="subtitle1">Start Time</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontSize:12 }} variant="body1"><strong>{dateConvert(job.end_time)}</strong></Typography>
                    <Typography sx={{ fontSize:11 ,mb:2  }} variant="subtitle1">End Time</Typography>

                  </Grid>
                  <Grid  item xs={6}>
                    <Typography sx={{ fontSize:12  }} variant="body1"><strong>{job.parent_category_name}</strong></Typography>
                    <Typography sx={{ fontSize:10 ,mb:2 }} variant="subtitle1">Category</Typography>

                  </Grid>
                  <Grid item xs={6}>
                  {job.task_type==="IN_PERSON" && (
                    <React.Fragment>
                  <Typography sx={{ fontSize:12  }} variant="body1"><strong>{job.pincode}, {job.city}</strong></Typography>
                  <Typography sx={{ fontSize:10 ,mb:2 }} variant="subtitle1">Location</Typography>
                  </React.Fragment>

                  )}
                </Grid>
                
{/* 
                <Grid  item xs={6}>
                  <Typography sx={{ fontSize:12  }} variant="body1"><strong>
                  <TaskStatus status={task.status}/>
                  </strong></Typography>
                <Typography sx={{ fontSize:10 ,mb:2 }} variant="subtitle1">Status</Typography>


                </Grid> */}
                </Grid>
                <Typography sx={{ fontSize:fontSize  }} variant="body1">
                <TruncatedText text={job.description} slice_no={isMobile ? 75 : 500}/>
                </Typography>

                </CardContent>
                </CardActionArea>
            </Card>
            </Grid>
            ))}
            
            
            </Grid>
            </Box>
            </div>
        )}
        {jobs && <div style={{ marginTop: '20px' }}> 
         <Stack sx={{display:'flex', alignItems: 'center'}} spacing={2}>
         <Pagination 
         sx={{ top: 'auto', bottom: 100 , position: 'fixed',}}
        showFirstButton
          showLastButton
          siblingCount={2}
          boundaryCount={2}
          size="small"
        count={totalPages}
         page={page}
         onChange={handleChangePage} 
         /> 
         </Stack>
         {/* <Select value={jobsPerPage} onChange={handleJobsPerPageChange}>
          <MenuItem value={10}>10 per page</MenuItem>
          <MenuItem value={20}>20 per page</MenuItem>
        </Select> */}
         </div> }
        
              </div>   
      )}
      
    </div> );
}

export default JobTab;
export const SET_USER_CREDIT = 'SET_USER_CREDIT';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_USER_FIRST_NAME = 'SET_USER_FIRST_NAME';
export const SET_USER_LAST_NAME = 'SET_USER_LAST_NAME';
export const RESET_USER_REDUCER = 'RESET_USER_REDUCER';
export const SET_USER_CUBES = 'SET_USER_CUBES';
export const SET_USER_ACCESS = 'SET_USER_ACCESS';
export const SET_HELPER_TYPE = 'SET_HELPER_TYPE';
export const SET_HELPER_KYC = 'SET_HELPER_KYC';


export const setHelperType = (helper_type) => ({
  type: SET_HELPER_TYPE,
  payload: helper_type,
});

export const setHelperKyc = (SET_HELPER_KYC) => ({
  type: SET_HELPER_KYC,
  payload: SET_HELPER_KYC,
});

export const ResetUserReducer = () => ({
  type: RESET_USER_REDUCER,
});

export const setUserAccess = (access) => ({
  type: SET_USER_ACCESS,
  payload: access,
});

export const setUserCredit = (token) => ({
  type: SET_USER_CREDIT,
  payload: token,
});

export const setUserCubes = (cubes) => ({
  type: SET_USER_CUBES,
  payload: cubes,
});

export const setUserId = (id) => ({
  type: SET_USER_ID,
  payload: id,
});

export const setUserEmail = (email) => ({
  type: SET_USER_EMAIL,
  payload: email,
});

export const setUserFirstName = (first_name) => ({
  type: SET_USER_FIRST_NAME,
  payload: first_name,
});

export const setUserLastName = (last_name) => ({
  type: SET_USER_LAST_NAME,
  payload: last_name,
});
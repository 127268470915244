import React, { useState, useEffect } from 'react';
import {  Divider,ListItemButton,ListItemText,CardContent, Button,Typography,Box,Select, MenuItem , Card, CardHeader} from '@mui/material';
import UserApiService from '../commonuser/UserApi';
import useCheckAuth from '../commonuser/CheckAuth';
import Loading from '../NavBars/Loading';
import NavBar from '../NavBars/navBar';
import WaitingForVerificationBanner from './WaitingForVerificationBanner';
import EarningsGraph from './EarningsGraph';
import EarningsTable from './components/EarningsTable';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Withdrawls from './components/Withdrawls';
import BottomBar from '../NavBars/BottomBar';
import { useSelector } from 'react-redux';
import ErrorToast from '../utils/ErrorToast';
function EarningsComponentMobile() {
  const helper_type = useSelector(x => x.user.helper_type);
  const [tasks, setTasks] = useState([]);
  const [TotalEarnings, setTotalEarnings] = useState({});
  const [withdrawls, setWithdrawls] = useState([]);
  const [earningsOvertime, setearningsOvertime] = useState([]);
  const [ earningMonths,setearningMonths]= useState(3);
  const token = useCheckAuth();
  
  const [loading, setLoading] = useState(true);
  const [showEarningsPage, setshowEarningsPage] = useState(true);
  const [showEarningsTable, setshowEarningsTable] = useState(false);
  const [showEarningsGraph, setshowEarningsGraph] = useState(false);
  const [showWithdrawls, setshowWithdrawls] = useState(false);


 
  useEffect(() => {
    UserApiService.AllEarnings(token,{})
    .then(response => {setTasks(response)
    }
    )
    UserApiService.EarningsTotal(token,{})
    .then(response => {
      setTotalEarnings(response.total_earnings_data)
      setLoading(false);
      
    }
    )
    UserApiService.Withdrawls(token)
    .then(response => {
      setWithdrawls(response)      
    }
    )

    
  }, [token]);

  const handleOptionChange = (event) => {
    setearningMonths(event.target.value);
  };
  useEffect(()=>{
    UserApiService.EarningsOverTime(token,earningMonths)
    .then(response => {setearningsOvertime(response.data)
      setshowEarningsGraph(true)
    }
    )

  },[token,earningMonths])
  const [errors, setErrors] = useState({});


  const handlePayoutAllClick = (event) => {
    // event.preventDefault()
    UserApiService.PayoutAllBooking(token).then(response => {
        window.location.reload(false);
      })
      .catch((error) => {
        const  data  = error.response.data["error"];
        console.log(data);
      
        const newErrors = {};
        newErrors["ERROR"] = data;

        setErrors(newErrors);
        })
  };
  

 

  return (
    <div>
    <NavBar/>
    <WaitingForVerificationBanner/>
    {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
{showEarningsPage && (
    <React.Fragment>
      <ErrorToast message={errors}/>
            
            <Card
              sx={{ maxWidth: 800,margin: "auto",}}
              style={{backgroundColor: "#0dcaf000"}}
              >
            <CardHeader
                action={
                <React.Fragment>
                  {!helper_type.includes("ORG_USER") && (      
                      <Button  
                        disabled={TotalEarnings.total_payout_amount_ready_to_withdraw <= 0}
                        variant="text" 
                        align="right"
                        color="primary"
                        onClick={(e) => handlePayoutAllClick(e)}
                      >
                        Withdraw
                      </Button>
                    )}
                </React.Fragment>
                
            }
            ></CardHeader>
                <Typography align="center"variant="h5">{TotalEarnings.total_payout_amount_ready_to_withdraw.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
                <Typography align="center" variant="subtitle1">Total Amount Ready to Withdraw</Typography>
            <CardContent>
                <Typography>
                    Overview
                </Typography>
            <Select fullWidth value={earningMonths} onChange={handleOptionChange} variant="standard">
                <MenuItem value="2">This Month</MenuItem>
                <MenuItem value="3">3 Months</MenuItem>
                <MenuItem value="6">6 Months</MenuItem>
                <MenuItem value="12">Year to Date</MenuItem>
            </Select>
            {showEarningsGraph &&<EarningsGraph data={earningsOvertime}/>}
            <Typography variant="h5">
                Earnings
            </Typography>
            <Divider sx={{ borderBottomWidth: '2px', borderColor: 'black' }} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Total Amount Ready to Withdraw</Typography>
                <Typography variant="body1">{TotalEarnings.total_payout_amount_ready_to_withdraw.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
            </Box>
            <Box my={2} />
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Total Amount Pending Verification</Typography>
                <Typography variant="body1">{TotalEarnings.total_payout_amount_pending_withdraw.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
            </Box>
            <Box my={2} />
            {/* <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Total Earnings</Typography>
                <Typography variant="body1">€{TotalEarnings.total_earnings}</Typography>
            </Box> */}
            <Box my={2} />
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Total Earnings</Typography>
                <Typography variant="body1">{TotalEarnings.total_payout_amount.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
            </Box>
            <Box my={2} />
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Total Booking Completed</Typography>
                <Typography variant="body1">{TotalEarnings.total_bookings_completed}</Typography>
            </Box>
            <Box my={2} />
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            <ListItemButton disableGutters onClick={() => {
                 setshowEarningsPage(false)
                 setshowEarningsTable(true)
                }} >
                <ListItemText primary="All Earnings" justifyContent="space-between"/>
                <ArrowForwardIosIcon/>
              </ListItemButton>
              {!helper_type.includes("ORG_USER") && (      
                <React.Fragment>
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            <ListItemButton disableGutters onClick={() => {
                 setshowEarningsPage(false)
                 setshowWithdrawls(true)
                }} >
                <ListItemText primary="Withdrawls" justifyContent="space-between"/>
                <ArrowForwardIosIcon/>
              </ListItemButton>
              </React.Fragment> 
              )}
            <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} />
            </CardContent>


            </Card>
            
        
    
      </React.Fragment>

)}
{showEarningsTable && <EarningsTable tasks={tasks} setshowEarningsPage={setshowEarningsPage} setshowEarningsTable={setshowEarningsTable}/>}
{showWithdrawls && <Withdrawls withdrawls={withdrawls} setshowEarningsPage={setshowEarningsPage} setshowWithdrawls={setshowWithdrawls}/>}
        </React.Fragment>
      )}
      <BottomBar/>
    </div>
  );
}

export default EarningsComponentMobile;

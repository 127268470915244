import React, { useState,useEffect } from 'react';
import JobsApi from './JobsAPi';
import useCheckAuth from '../../commonuser/CheckAuth';

const CustomPagination = ({ page, setPage, totalPages }) => {
  const handlePreviousPage = () => {
    setPage(page - 1);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  return (
    <div>
      <button disabled={page === 1} onClick={handlePreviousPage}>
        Previous
      </button>
      <span>
        Page {page} of {totalPages}
      </span>
      <button disabled={page === totalPages} onClick={handleNextPage}>
        Next
      </button>
    </div>
  );
};

function JobList() {
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState('closed');
  const token  = useCheckAuth();
  const fetchJobs = async () => {
    setLoading(true);
    try {
      const  response = await JobsApi.JobList(token ,activeTab,page,10)
      console.log(response);
      setJobs(response.results);
      setTotalPages(Math.ceil(response.count / response.page_size));
      setLoading(false);
      setError(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
    }
  } 
  useEffect(() => {
    fetchJobs();
  // eslint-disable-next-line
  }, [activeTab, page]);

   useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        console.log(event);
        if (event.data.sub_type === 'JOB_CREATED') {
          fetchJobs();
        }
      });
    }
    // eslint-disable-next-line
  }, []);

   if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Something went wrong!</div>;
  }

  return ( 
  <div>
    <ul>
      <li onClick={() => setActiveTab('closed')}>Cancelled by Admin</li>
    </ul>
    <div>
      {jobs.map((job) => (
          <div key={job.id}>
            <h2>{job.title}</h2>
            <p>{job.description}</p>
            <p>Status: {job.status}</p>
            {job.quote && (
              <>
                <p>Quote Amount: {job.quote.amount}</p>
                <p>Quote Status: {job.quote.status}</p>
              </>
            )}
                  <CustomPagination page={page} setPage={setPage} totalPages={totalPages} />

          </div>

        ))}
    </div>
  </div> );
}

export default JobList;
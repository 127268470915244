import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectHome() {
  let navigate = useNavigate()

  useEffect(() => {
    const timeout = setTimeout(() => {
        navigate('/');
    }, 5000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <div>
      <h2>Redirecting to home page in 5 seconds...</h2>
    </div>
  );
}

export default RedirectHome;

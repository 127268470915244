const TaskStatus = ({ status }) => {
  let text;

  switch (status) {
    case 'CREATED':
      text = 'Task Created';
      break;
    case 'TASK_TIMEOUT':
      text = 'Task timedout Without offers';
      break;
    case 'QUOTE_ACCEPTED':
      text = 'Offer Accepted Waiting for Payment';
      break;
    case 'RESCHEDULE_REQUESTED_BY_SEEKER':
      text = 'Reschedule Requested';
      break;
    case 'RESCHEDULE_REQUESTED_BY_HELPER':
      text = 'Reschedule Requested';
      break;
    case 'BOOKED':
      text = 'Booking Confirmed';
      break;
    case 'CANCELLED_BY_SEEKER':
      text = 'Booking Cancelled';
      break;
    case 'CANCELLED_BY_HELPER':
      text = 'Booking Cancelled';
      break;
    case 'TASK_COMPLETED':
      text = 'Task Completed';
      break;
    case 'WAITING_FOR_CONFIRMATION':
      text = 'Task Waiting For Confirmation';
      break;
    case 'TASK_IN_PROGRESS':
      text = 'Task In Progress';
      break;
    default:
      text = status;
  }

  return text;
};

export default TaskStatus;

import React, { useState,useEffect } from 'react';
import useCheckAuth from '../../../../commonuser/CheckAuth';
import { Pagination,Stack,  Card,Box, CardHeader,CardContent, Grid,Typography, Divider ,CardActionArea} from '@mui/material';
import Loading from '../../../../NavBars/Loading';
import moment from "moment";
import IsMobile from '../../../../Styles/IsMobile';
import BookingApi from '../../BookingApi';
import UnreadMessages from '../../../Tasks/components/TaskDetailComponents/UnreadMessages';
import TruncatedText from '../../../Jobs/components/TruncatedText';
function BookingTab({status}) {
    const token = useCheckAuth();
    const [bookings, setBookings] = useState([]);
    const [page, setPage] = useState(1);
    const fontSize = 15;
    const [loading, setLoading] = useState(true);
    const [bookingsPerPage, ] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const isMobile=IsMobile() // eslint-disable-next-line 
    const [error, setError] = useState(false); 
    const dateConvert = (ds) =>{
        return  moment(ds).format('MMM Do YY, HH:mm');
    }
  //   const handleJobsPerPageChange = (event) => {
  //   setBookingsPerPage(event.target.value);
  //   setPage(1);
  //   // setJobsToShow(jobs.slice(0, event.target.value));
  // };
    const handleChangePage = (event, value) => {
    setPage(value);

  };
    const fetchBookings = async (status,page,bookingsPerPage) => {
    setLoading(true);
    try {
      const  response = await BookingApi.BookingList(token ,status,page,bookingsPerPage)
      setBookings(response.results);
      setTotalPages(Math.ceil(response.count / bookingsPerPage));
      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  } 
  
  useEffect(() => {
    fetchBookings(status,page,bookingsPerPage);
    // eslint-disable-next-line 
  }, [status, page,bookingsPerPage]);
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        let sub_types = ['BOOKING_CREATED'];
        if (sub_types.includes(event.data.sub_type)){
          fetchBookings(status,1,bookingsPerPage);
        }
      });
    }
    // eslint-disable-next-line
  }, []);
    return ( 
    <div>
       {loading ? (
        <Loading />
      ) : (
        <div>
        {bookings.length === 0 ? (<Typography style={{ textAlign: 'center', marginTop: '50px' }}  variant="body1">No Bookings found.</Typography>): (
            <div>
              <Box 
        justifyContent= "space-evenly"
        alignItems="center"
         sx={{
        display: 'flex',
        justifyContent:'center',
        flexDirection:'column'
        }}
        >
            <Grid 
            direction="column"
            container 
            justifyContent= "center"
            alignItems="stretch"
            md={8} 
            lg={8}
            xl={8}
            spacing={2}>
            {bookings.map((booking) => (
            <Grid 
            key={booking.id} 
            item                
            xs={12} 
            md={8}  
            justifyContent= "space-evenly"
            alignItems="stretch">
            <Card style={{backgroundColor: "#0dcaf000"}} elevation={1} >
              <CardActionArea  href={`/Booking/${booking.external_id}`}>
                <CardContent>
                  <Grid  
                    container
                    item xs={10} 
                    md={8}
                    justifyContent="space-evenly"
                    spacing={0.3}
                    >
                  <Grid item xs={10}>
                         <Typography sx={{
                    display:"flex",
                    fontSize:15 ,
                  justifyContent:'center'
                }} 
                variant="h6"
                >{booking.title}

                </Typography>

                </Grid>
                  <Grid item xs={2}>
                { booking.conversation && booking.conversation.chat_enabled  &&  
                <CardHeader sx={{position:"absolute",right:0}} 
                component={UnreadMessages}
                 count={booking.conversation.no_unread_messages}
                  task_id={booking.external_id}>
                  
                </CardHeader> } 
                </Grid>      


                </Grid>
                <Divider component="p"/>
                <Grid container spacing={0.3}>
                  <Grid  item xs={8}>
                    {booking.booking_type==="IN_PERSON" ? (
                  <Typography sx={{ fontSize:fontSize  }}> <strong>In Person Appoinment </strong></Typography>
                  ) :
                  (<Typography sx={{ fontSize:fontSize   }}> <strong>Online Appoinment</strong></Typography>)}

                  <Typography sx={{ fontSize:10 ,mb:2  }}> Created {moment(booking.created_at).fromNow()}</Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid  item xs={6}>
                    <Typography sx={{ fontSize:12 }} variant="body1"><strong>{dateConvert(booking.start_time)}</strong></Typography>
                    <Typography sx={{ fontSize:11 ,mb:2 }} variant="subtitle1">Start Time</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontSize:12 }} variant="body1"><strong>{dateConvert(booking.end_time)}</strong></Typography>
                    <Typography sx={{ fontSize:11 ,mb:2  }} variant="subtitle1">End Time</Typography>

                  </Grid>
                  <Grid  item xs={6}>
                    <Typography sx={{ fontSize:12  }} variant="body1"><strong>{booking.parent_category_name}</strong></Typography>
                    <Typography sx={{ fontSize:10 ,mb:2 }} variant="subtitle1">Category</Typography>

                  </Grid>
                  <Grid item xs={6}>
                  {booking.booking_type==="IN_PERSON" && (
                    <React.Fragment>
                  <Typography sx={{ fontSize:12  }} variant="body1"><strong>{booking.pincode}, {booking.city}</strong></Typography>
                  <Typography sx={{ fontSize:10 ,mb:2 }} variant="subtitle1">Location</Typography>
                  </React.Fragment>

                  )}
                </Grid>
                </Grid>
                <Typography sx={{ fontSize:fontSize  }} variant="body1">
                <TruncatedText text={booking.description} slice_no={isMobile ? 75 : 500}/>
                </Typography>

                </CardContent>
                </CardActionArea>
            </Card>
            </Grid>
            ))}
            
            
            </Grid>
            </Box>
            </div>
        )}
        {bookings && <div style={{ marginTop: '20px' }}> 
         <Stack sx={{display:'flex', alignItems: 'center'}} spacing={2}>
         <Pagination 
         sx={{ top: 'auto', bottom: 100 , position: 'fixed',}}
        showFirstButton
          showLastButton
          siblingCount={2}
          boundaryCount={2}
          size="small"
        count={totalPages}
         page={page}
         onChange={handleChangePage} 
         /> 
         </Stack>
         {/* <Select value={jobsPerPage} onChange={handleJobsPerPageChange}>
          <MenuItem value={10}>10 per page</MenuItem>
          <MenuItem value={20}>20 per page</MenuItem>
        </Select> */}
         </div> }
        
              </div>   
      )}
      
    </div> );
}

export default BookingTab;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useCheckAuth from '../../commonuser/CheckAuth';
import NavBar from '../../NavBars/navBar';
import TaskAPi from './TaskApi';
import Loading from '../../NavBars/Loading';
import TaskDetail from './components/TaskDetailComponents/TaskDetail';
import BottomBar from '../../NavBars/BottomBar';

function TaskDetailView() {
    const token  = useCheckAuth();
    const [task, setTask] = useState(null);
    const { taskId } = useParams();
    const [loading, setLoading] = useState(true);
    const [taskOtp, settaskOtp] = useState('');
    useEffect(() => {
      
    TaskAPi.TaskOtp(token,taskId)
    .then((data) => {
      if (data.show_otp) {
        settaskOtp(data.otp);
      }
    });
  }, [token,taskId]);

  
    useEffect(() => {
      
    TaskAPi.TaskDetail(token,taskId)
    .then((data) => {
      setTask(data);
      setLoading(false);
    });
  }, [token,taskId]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        let sub_types = [
        "QUOTE_CREATED",
        "BOOKING_CREATED",
        'BOOKING_CANCELLED_BY_USER_FOR_HELPER',
        'BOOKING_CANCELLED_BY_USER_FOR_USER',
        'BOOKING_CANCELLED_BY_HELPER_FOR_HELPER',
        'BOOKING_CANCELLED_BY_HELPER_FOR_USER',
        'RESCHEDULE_REQUESTED_BY_HELPER',
        'RESCHEDULE_REQUESTED_BY_SEEKER',
        'RESCHEDULE_DECLINED_BY_HELPER',
        'RESCHEDULE_DECLINED_BY_SEEKER',
        'RESCHEDULE_APPROVED_BY_HELPER',
        'RESCHEDULE_APPROVED_BY_SEEKER',
        'ADDITIONAL_TIME_REQUESTED_BY_HELPER',
        'ADDITIONAL_TIME_CANCELLED_BY_USER',
        'ADDITIONAL_TIME_ACCEPTED_BY_USER',
        'MARK_BOOKING_AS_COMPLETE',
        'TASK_OTP'
    ];
        if (sub_types.includes(event.data.sub_type)){
          TaskAPi.TaskDetail(token,taskId)
          .then((data) => {
            setTask(data);
            setLoading(false);
          });
        }
      });
    }
    // eslint-disable-next-line
  }, []);



    return ( <div>
        <NavBar/>

         {loading ? (
        <Loading />
      ) : ( 
        <div>
            <TaskDetail 
            task={task}
            token={token}
            otp={taskOtp}
          />
        </div> 
      )}
      <BottomBar/>
    </div> );
}

export default TaskDetailView;
import React, { useState, useEffect } from 'react';
import OrgApi from './OrgApi';
import useCheckAuth from '../commonuser/CheckAuth';
import OrgEarningComponent from './components/OrgEarningComponent';
import Loading from '../NavBars/Loading';
import NavBar from './PopOverMenu';
import BottomBar from './BottomBar';
import AllowOrgAdmin from '../permissions/AllowOrgAdmin';
function OrgEarningsPage() {
    const [AllEarnings, setAllEarnings] = useState(null);
    const [withdrawls, setWithdrawls] = useState(null);
    const token = useCheckAuth();
    const [loading, setLoading] = useState(true);


    useEffect(()=>{
    OrgApi.GetOrgEarnings(token)
    .then((resp)=>{
        setAllEarnings(resp)
        setLoading(false)
    })
    OrgApi.GetWithdrawls(token)
    .then((resp)=>(setWithdrawls(resp)))
    },[])
    return (
        <React.Fragment>
          <AllowOrgAdmin/>
          <NavBar/>
    {loading ? (
        <Loading />
      ) : (
        <div>
           <OrgEarningComponent 
           AllEarnings={AllEarnings.total_earnings_data} 
           token={token}
           withdrawls = {withdrawls} 
           />
        </div>
      )}
      <BottomBar/>
      </React.Fragment>
      );
}

export default OrgEarningsPage;
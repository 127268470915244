import React, { useEffect } from 'react';
import { Container, Typography, Button } from '@mui/material';
// import { ReactComponent as NotFoundImage } from './path/to/404-image.svg';
import { useNavigate } from "react-router-dom";
const NotFoundPage = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    if (window.history.length > 1) {
      const previousPageOrigin = document.referrer.split('/')[2] || '';
      const currentOrigin = window.location.origin;
      if (previousPageOrigin === currentOrigin) {
        navigate(-1);
        return;
      }
    }

    navigate('/');
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        Oops! Page not found.
      </Typography>
      {/* <NotFoundImage style={{ width: '300px', height: '300px', marginBottom: '20px' }} /> */}
      <Typography variant="body1" gutterBottom>
        The page you are looking for does not exist.
      </Typography>
      <Button variant="outlined" color="primary" onClick={handleGoBack}>
        Go Back
      </Button>
    </Container>
  );
};

export default NotFoundPage;

// src/utils/webPush.js
import { applicationServerKey, BACKEND_URL} from "../../config";
export const subscribeUser = async (token) => {
    const serviceWorkerRegistration = await navigator.serviceWorker.register('/sw.js');
    const subscription = await serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey:  applicationServerKey
    });
    const endpoint = subscription.endpoint;
    const auth = arrayBufferToBase64(subscription.getKey('auth'));
    const p256dh = arrayBufferToBase64(subscription.getKey('p256dh'));
    await fetch(`${BACKEND_URL}/notify/api/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': `Token ${token}`, },
        body: JSON.stringify({ endpoint, auth, p256dh }),
    });
    return subscription;
}

export const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}

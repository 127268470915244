import React, { useEffect } from 'react';
import { Badge, IconButton } from  '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import { Link } from 'react-router-dom';

function UnreadMessages({task_id,count}) {
    useEffect(()=>{
        console.log(count);
    })
  return (
    <IconButton component={Link} to={`/message/${task_id}`} color="inherit">
      <Badge badgeContent={count} color="error">
        <MessageIcon fontSize="large"/>
      </Badge>
    </IconButton>
  );
}

export default UnreadMessages;

let GA_TRACKING_ID, BACKEND_URL,GOOGLE_CLIENT_ID,MAX_NO_OF_FILE,MAX_FILE_SIZE_MB,FB_APP_ID,Listlanguages,PAYOUT_DAYS, WEBSOCKET_URL,GOOGLE_REDIRECT_URI, GOOGLE_MAPS_API_KEY,applicationServerKey, STRIPE_PUBLIC_KEY, authheaders, basic_headers;

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  BACKEND_URL = "https://api.localelingo.com";
  WEBSOCKET_URL = "wss://api.localelingo.com";
  applicationServerKey = "BDEFiZbdjX21FYjtejQPX4jr7l2s5ZT1atUdvSF6PSComYHyr0onKfm70O_1z_9RtY7VXbN9sFDbmR4TnyjPbOI";
  STRIPE_PUBLIC_KEY = "pk_live_51NFBlBA88kXyLRMTPDQ6drpJeIu5d0qAKuGkrVZEd0xg2NWVYST0S57UxullH4MiqAVRQeVEFQDnuUNhLSuNSklK00gXYUf061";
  GOOGLE_MAPS_API_KEY= "";
  GOOGLE_REDIRECT_URI= "" ;
  FB_APP_ID= "";
  GOOGLE_CLIENT_ID='854101515365-rbfi35aenusgd4anj4c3a5dgrph36blf.apps.googleusercontent.com';
  GA_TRACKING_ID='G-WHWT56X1GW'
  Listlanguages = [
  "ENGLISH",
  "GERMAN",
  "SPANISH",
  "ITALIAN",
  "TURKISH",
  "FRENCH",
  
]

  PAYOUT_DAYS = 15;
  MAX_FILE_SIZE_MB = 50;
  MAX_NO_OF_FILE = 10;
  authheaders = (token) => ({
    'Content-Type': 'application/json',
    'Authorization': `Token ${token}`,
    'ngrok-skip-browser-warning': 'true',
  });

  basic_headers = {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'true',
  };
} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  BACKEND_URL = "https://llapi.eu.loclx.io";
  WEBSOCKET_URL ="wss://llapi.eu.loclx.io";
  applicationServerKey =  "BDEFiZbdjX21FYjtejQPX4jr7l2s5ZT1atUdvSF6PSComYHyr0onKfm70O_1z_9RtY7VXbN9sFDbmR4TnyjPbOI";
  STRIPE_PUBLIC_KEY = "pk_test_51MsOqDBCELF65GUwcSbWYyP2KunWDP7IHmmFN69trxUP0pHe89eoHX2is9dilVBJfJPgjc3rXCXZyyVgz3dXvVu100KYTRbTD5";
  GOOGLE_MAPS_API_KEY=  "AIzaSyCljRBIkIncwnADdbbZpoe-W-GposOmmFk";
  GOOGLE_REDIRECT_URI= "";
  GOOGLE_CLIENT_ID='854101515365-rbfi35aenusgd4anj4c3a5dgrph36blf.apps.googleusercontent.com';
  FB_APP_ID=  "258493903223439";
  PAYOUT_DAYS = 15;
  MAX_FILE_SIZE_MB = 50;
  MAX_NO_OF_FILE = 10;
  GA_TRACKING_ID='G-TBE6SGCPGZ'
  Listlanguages = [
  "ENGLISH",
  "GERMAN",
  "SPANISH",
  "ITALIAN",
  "TURKISH",
  "FRENCH",
  
];
  

  authheaders = (token) => ({
    'Content-Type': 'application/json',
    'Authorization': `Token ${token}`,
    'ngrok-skip-browser-warning': 'true',
  });

  basic_headers = {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'true',
  };
} else {
  // Development environment
  BACKEND_URL = process.env.REACT_APP_BACKEND_URL
  WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL
  applicationServerKey = process.env.REACT_APP_APPLICATION_SERVER_KEY
  STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  GOOGLE_MAPS_API_KEY= process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  GOOGLE_REDIRECT_URI= process.env.REACT_APP_GOOGLE_REDIRECT_URI ;
  FB_APP_ID= process.env.REACT_APP_FB_APP_ID;
  GOOGLE_CLIENT_ID='854101515365-rbfi35aenusgd4anj4c3a5dgrph36blf.apps.googleusercontent.com';
  GA_TRACKING_ID=''
  PAYOUT_DAYS = 15;
  MAX_FILE_SIZE_MB = 50;
  MAX_NO_OF_FILE = 10;
  Listlanguages = [
  "ENGLISH",
  "SPANISH",
  "ITALIAN",
  "TURKISH",
  "FRENCH",
];

  authheaders = (token) => ({
    'Content-Type': 'application/json',
    'Authorization': `Token ${token}`,
    'ngrok-skip-browser-warning': 'true',
  });

  basic_headers = {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'true',
  };
}

export {
  BACKEND_URL,
  WEBSOCKET_URL,
  applicationServerKey,
  STRIPE_PUBLIC_KEY,
  authheaders,
  basic_headers,
  GOOGLE_MAPS_API_KEY,
  GOOGLE_REDIRECT_URI,
  FB_APP_ID,
  PAYOUT_DAYS,
  Listlanguages,
  MAX_FILE_SIZE_MB,
  MAX_NO_OF_FILE,
  GOOGLE_CLIENT_ID,
  GA_TRACKING_ID,
};

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PaymentApi from './PaymentApi';
import useCheckAuth from '../commonuser/CheckAuth';
import RedirectHome from './redirect';

function PaymentCancel () {
    const location = useLocation();
    const token  = useCheckAuth();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    const task_id = searchParams.get('task_id');
    useEffect( () => {
        PaymentApi.PaymentCancel(sessionId,task_id,token).then(resp => console.log(resp))
    },[sessionId,task_id,token])

    return (
    <div>
      <h2>Payment Cancelled</h2>
      <RedirectHome/>
    </div>
      );
}

export default PaymentCancel ;
import React, { useState,useEffect } from 'react';
import { Box,TextField, Button, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import PasswordIcon from '@mui/icons-material/Password';
import LoginIcon from '@mui/icons-material/Login';
import { useDispatch } from 'react-redux';
import { setUserEmail,setUserId ,setHelperType,setUserFirstName, setUserLastName, setUserCredit,setUserCubes,setUserAccess,setHelperKyc} from '../reducers/userActions';
import Cookies from 'js-cookie';
import UserApiService from './UserApi';
import { useNavigate } from "react-router-dom";
import SocialLogin from './SocialLogin';
import ErrorToast from '../utils/ErrorToast';
import { BACKEND_URL } from '../../config';
import mainLogo from'./ll_192.png';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [userType, setUserType ] = useState('');
  const [helperTypes, setHelperTypes ] = useState('');

  const [helperaccess, setHelperAccess ] = useState(false);
  const [helperredirectaccess, setHelperRedirectAccess ] = useState(false);
   const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch(); 
    useEffect(() => {
        const authToken = Cookies.get('authtoken');
        if (authToken) {
            setToken(authToken);
        }
    }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  useEffect(() => {   
      UserApiService.GetUser(token)
                .then(resp => {
                    if (resp.is_seeker) {
                        UserApiService.GetUserProfile(token).then(res => {
                           dispatch(setUserEmail(res.email));
                           dispatch(setUserCredit(res.user_credit));
                           dispatch(setUserFirstName(res.first_name));
                           dispatch(setUserLastName(res.last_name));
                        }
                        );
                         setUserType('user');
                        cookisetup('UserType', 'user')
                         dispatch(setUserId(resp.pk));
                    } else if (resp.is_helper) {
                        UserApiService.GetHelperProfile(token).then(res => {
                           dispatch(setUserEmail(res.email));
                           dispatch(setUserCredit(res.user_credit));
                           dispatch(setUserCubes(res.connects_amount));
                           dispatch(setUserFirstName(res.first_name));
                           dispatch(setUserLastName(res.last_name));
                           dispatch(setUserAccess(res.is_access_enabled))
                           setHelperAccess(res.is_access_enabled)
                           dispatch(setHelperType(res.helper_type))
                           setHelperRedirectAccess(true)
                           setHelperTypes(res.helper_type)
                           setHelperKyc(res.kyc_verified)
                        }
                        );
                    setUserType('helper');
                    cookisetup('UserType', 'helper')
                    dispatch(setUserId(resp.pk));   
                    }
                })
                .catch((resp) => {});
        
    }, [dispatch,token]);
    useEffect(() => {
        if (userType) {
            if (userType === 'user') {
                navigate('/user/task/create');
            } else 
            if (userType === 'helper') {
              if (helperredirectaccess) { 
                if (helperTypes ==="ORG_ADMIN")  { navigate('/org/home')}  
                else 
                  if (helperaccess)  { navigate('/helper/home')}  
                  else { navigate('/profile/helper') }
                
               
              }
              
               
            }
        }
      
    }, [navigate,userType,helperaccess,helperredirectaccess]);

  

  const handleLogin = (event) => {
    event.preventDefault();
    UserApiService.LoginUser({ email, password })
            .then(resp => {
              setToken(resp.key)
              cookisetup('authtoken', resp.key)
            })
            .catch((error) => {
                const { data } = error.response;
                const errorFields = Object.keys(data);
                const newErrors = {};
                errorFields.forEach(field => {
                const [errorMessage] = data[field];
                newErrors[field] = errorMessage;
                });
                setErrors(newErrors);
        })
  };

  return (
    <div>
      
      
    <ErrorToast message={errors}/>
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 30, px: 2 ,justifyContent:'center'}}>
    <form onSubmit={handleLogin}>
       <Typography align='center' variant="h5">{ <img src={mainLogo} alt="Company Logo" style={{ width: 50, marginRight: 10 }} />}</Typography>
      <Typography align='center' variant="h5">Login to LocaleLingo</Typography>
      <TextField
        label="Email"
        value={email}
        onChange={handleEmailChange}
        margin="normal"
        required
        fullWidth
        error={Boolean(errors.email)}
        helperText={errors.email}
        InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
      />
      <TextField
        label="Password"
        value={password}
        onChange={handlePasswordChange}
        margin="normal"
        type="password"
        required
        fullWidth
        error={Boolean(errors.password)}
        helperText={errors.password}
        InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordIcon />
                </InputAdornment>
              ),
            }}
      />
      <Button startIcon={<LoginIcon />} variant="contained" color="primary" type="submit" fullWidth>
        Login
      </Button>
      <div style={{margin:10}}></div>
      {/* <SocialLogin setToken={setToken} cookisetup={cookisetup}/> */}
      {errors.non_field_errors && (
        <React.Fragment>
            <Typography variant="body1" color="error" sx={{ mb: 2 }}>
              {errors.non_field_errors}
              
            </Typography>
            {errors.non_field_errors === "E-mail is not verified." && 
              <Button  
              onClick={() => navigate('/auth/registration/resend-email')} 
              sx={{ textTransform: 'none', fontSize: 16 }}>
                Resend Email Verification
              </Button>
              }
              </React.Fragment>
          )}          
          <Button fullWidth onClick={() => navigate('/register/user')} sx={{ textTransform: 'none', fontSize: 16, mb: 2 }}>
            Register as User
      </Button>
      <Button fullWidth onClick={() => navigate('/register/helper')} sx={{ textTransform: 'none', fontSize: 16, mb: 2 }}>
        Register as Helper
      </Button>
      <Button fullWidth onClick={() => navigate('/auth/passwordreset')} sx={{ textTransform: 'none', fontSize: 16 }}>
        Password Reset
      </Button>
    </form>
     </Box>
   </div>

  );
};


const cookisetup = (cookiename,value) => {
  const myCookieValue = Cookies.get(cookiename);
  if (myCookieValue) {
    // Cookie exists, update the value
    Cookies.set(cookiename, value);
  } else {
    // Cookie doesn't exist, create a new one
    Cookies.set(cookiename, value);
  }
}

export default LoginForm;

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const TermsAndConditionsPopup = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>Terms and Conditions</Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="terms-and-conditions-title">
        <DialogTitle id="terms-and-conditions-title">Terms and Conditions</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Terms and Conditions
            <br /><br />
            These Terms and Conditions ("Terms") govern your access to and use of Localelingo ("Platform"). By accessing or using the Platform, you agree to comply with these Terms. If you do not agree with any part of these Terms, you may not use the Platform.
            <br /><br />
            1. User Conduct:
            <br />
            - You agree to use the Platform in compliance with all applicable laws and regulations. You shall not engage in any activity that may disrupt or interfere with the operation or security of the Platform or infringe upon the rights of other users.
            <br /><br />
            2. Account Registration:
            <br />
            - To access certain features of the Platform, you may be required to create an account. You must provide accurate and complete information during the registration process and keep your account credentials confidential. You are responsible for all activities that occur under your account.
            <br /><br />
            3. Intellectual Property:
            <br />
            - The Platform and its content, including but not limited to text, graphics, logos, and software, are protected by intellectual property laws. You may not copy, modify, distribute, or reproduce any part of the Platform without prior written consent from Localelingo.
            <br /><br />
            4. User Content:
            <br />
            - By submitting any content on the Platform, including reviews, feedback, or messages, you grant Localelingo a non-exclusive, worldwide, royalty-free license to use, reproduce, and display such content for the purpose of providing and promoting the Platform.
            <br /><br />
            5. Privacy:
            <br />
            - Localelingo is committed to protecting your privacy and handles your personal information in accordance with applicable privacy laws. Please refer to our Privacy Policy for more information on how we collect, use, and protect your personal information.
            <br /><br />
            6. Dispute Resolution:
            <br />
            - Any disputes arising from these Terms shall be resolved amicably through negotiation. In the event that a resolution cannot be reached, the dispute may be escalated to the courts in accordance with EU laws.
            <br /><br />
            7. Limitation of Liability:
            <br />
            - To the extent permitted by law, Localelingo shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the Platform or any actions or transactions conducted through the Platform.
            <br /><br />
            8. Indemnification:
            <br />
            - You agree to indemnify and hold Localelingo and its affiliates, directors, officers, employees, and agents harmless from any claims, losses, damages, liabilities, or expenses arising from your use of the Platform or violation of these Terms.
            <br /><br />
            9. Modifications:
            <br />
            - Localelingo reserves the right to modify, suspend, or terminate the Platform or these Terms at any time, with or without notice. You are responsible for regularly reviewing the Terms to stay informed of any changes.
            <br /><br />
            10. Severability:
            <br />
            - If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.
            <br /><br />
            11. Governing Law:
            <br />
            - These Terms shall be governed by and construed in accordance with the laws of the European Union.
            <br /><br />
            12. Service Fees:
            <br />
            - Users of the Platform agree to pay a service fee as determined by Localelingo for the use of the services provided. The service fee will be clearly communicated prior to the completion of any transaction. Users are responsible for any additional taxes or fees that may apply. Please note that the service fee is subject to change and it is your responsibility to review the current fees before using the Platform.
            <br /><br />
            13. Platform Fees:
            <br />
            - Helpers and translators who offer their services through the Platform may be subject to platform fees, as determined by Localelingo. These fees will be deducted from the compensation paid to the helpers or translators for their services. Localelingo reserves the right to modify the platform fees at any time, and the updated fees will be applicable to future transactions.
            <br /><br />
            If you have any questions or concerns regarding these Terms and Conditions, please contact us through the provided channels.
            <br /><br />
            Privacy Policy:
            <br /><br />
            At Localelingo, we are committed to protecting the privacy and rights of our users in accordance with the General Data Protection Regulation (GDPR). This Privacy Policy outlines how we collect, use, disclose, and protect personal information gathered through our platform. By accessing or using Localelingo, you consent to the practices described in this policy.
            <br /><br />
            Information Collection:
            <br /><br />
            We collect personal information, such as name, email address, and contact details, in compliance with GDPR regulations. We may also collect non-personal information, such as browser type and usage statistics, to improve our services.
            <br /><br />
            Lawful Basis for Processing:
            <br /><br />
            We process personal information based on the lawful grounds permitted under the GDPR, including consent, contractual necessity, and legitimate interests. You have the right to withdraw consent and exercise your other rights as outlined in this policy.
            <br /><br />
            Information Use:
            <br /><br />
            We use the collected information to provide and enhance our platform's functionality, personalize user experience, and communicate with you regarding our services, updates, and promotions. We may also analyze data to improve our services and ensure compliance with applicable laws.
            <br /><br />
            Information Sharing:
            <br /><br />
            We may share personal information with trusted third-party service providers who assist us in delivering our services, subject to confidentiality obligations. We do not sell, rent, or trade personal information to third parties for their marketing purposes without your consent.
            <br /><br />
            Data Transfers:
            <br /><br />
            We may transfer personal information to recipients in countries outside the European Economic Area (EEA) that may not provide the same level of data protection. In such cases, we will ensure appropriate safeguards are in place to protect your information.
            <br /><br />
            Data Subject Rights:
            <br /><br />
            You have the right to access, rectify, erase, and restrict the processing of your personal information. You can also request the portability of your data and object to its processing under certain circumstances. We will respond to such requests in accordance with GDPR requirements.
            <br /><br />
            Data Security:
            <br /><br />
            We implement technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We regularly review and enhance our security practices to ensure the ongoing integrity of your data.
            <br /><br />
            Cookies and Tracking Technologies:
            <br /><br />
            We may use cookies and similar tracking technologies to enhance user experience, gather usage information, and customize content. You can manage your cookie preferences through your browser settings.
            <br /><br />
            Third-Party Links:
            <br /><br />
            Our platform may contain links to third-party websites or services that have their own privacy policies. We are not responsible for the practices or content of such linked sites and encourage you to review their privacy policies.
            <br /><br />
            Children's Privacy:
            <br /><br />
            Localelingo is not intended for use by individuals under the age of 16. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child, we will take steps to delete it promptly.
            <br /><br />
            Updates to Privacy Policy:
            <br /><br />
            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review the policy periodically for any updates.
            <br /><br />
            If you have any questions or concerns regarding our GDPR compliance or the handling of your personal information, please contact us through the provided channels. By using Localelingo, you consent to the collection and use of your personal information as described in this Privacy Policy.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small" variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default TermsAndConditionsPopup;

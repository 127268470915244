import React from 'react';
import { Box } from '@mui/material';
// eslint-disable-next-line
import { Chart }            from 'react-chartjs-2'
// eslint-disable-next-line
import { Chart as ChartJS } from 'chart.js/auto'
import { Line } from 'react-chartjs-2';

const BookingsGraph = ({ data }) => {
  // const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  console.log(data);

  const labels = Object.keys(data.cb_over_time);
  const bookings = Object.values(data.cb_over_time);

  const earningsData = {
    labels,
    datasets: [
      {
        label: 'Bookings',
        data: bookings,
        fill: true,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };

  const options = {
    responsive: true,
     plugins: {
      legend: {
        display: false, // Disable the legend
      },
      title: {
        display: true,
        text: 'Bookings Over Time', // Specify the graph title
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Box>
      {/* <Box height={isSmallerScreen ? 200 : 400}> */}
        <Line data={earningsData} options={options} />
      {/* </Box> */}
    </Box>
  );
};

export default BookingsGraph;

import React, { useEffect ,useState} from 'react';
import { useSelector } from 'react-redux';

function CreateBadge() {
   const notif = useSelector(x => x.notify.notifications);
   const [notifications, setNotifications] = useState([]);
   const unmsg =  useSelector(x => x.notify.unread_messages);
   const [messages, setMessages] = useState([]);
   const NewNotifyStatusList = ["CREATED",'NOTIFICATION_SENT'];
   useEffect(() => {
     setNotifications(notif.length ? notif.filter(notify => NewNotifyStatusList.includes(notify.notification_status)): [])
     setMessages(unmsg)
   },
   [notif,unmsg])
   useEffect(()=>{
    const badgecount = messages.length + notifications.length
    if ('serviceWorker' in navigator) {
        try {
        navigator.setAppBadge(badgecount).then(() => {
            console.log("The badge was added",badgecount);
        }).catch(e => {
            console.error("Error displaying the badge", e);
        });
      } catch(e) {  }
    }
  },[notifications,messages])

    return null ;
}

export default CreateBadge ;
import React, { useState } from 'react';
import axios from 'axios';
import { BACKEND_URL,authheaders } from '../../config';
import {Button, InputLabel, Checkbox,TextField,Box, CardContent, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import RefreshPage from '../utils/refreshPage';
import TaskAPi from '../marketplacev2/Tasks/TaskApi';
import { Listlanguages } from '../../config';

function ProfileUpdate({user,user_type,token,setEditMode,setLoading}) {
    const [phone_number, setPhoneNumber] = useState(user.phone_number);
    const [first_name, setFirstName] = useState(user.first_name)
    const [last_name, setLastName] = useState(user.last_name)
    const [address, setAddress] = useState(user.address);
    const [pincode, setPincode] = useState(user.pincode);
    const [city, setCity] = useState(user.city);
    const [Languages, setLanguages] = useState(user.verified_languages);
    const ListPreference = [
        "ONLINE",
        "IN_PERSON",
      ];
    const [InputLanguages, setInputLanguagess] = useState(user_type === "user" || user.verified_languages === '' ? '[]' : JSON.parse(user.verified_languages));
    const [JobPreferences, setJobPreferences] = useState(user.job_preferences === '' ? '[]' :JSON.parse(user.job_preferences));
    const [Preferences, setPreferences] = useState(user.job_preferences);

    const handleChange = (event) => {
    const selectedValues = event.target.value;
    const selectedJSON = JSON.stringify(selectedValues);
    setInputLanguagess(event.target.value);
    setLanguages(selectedJSON)
    
    };

    const handlePrefChange = (event) => {
    const selectedValues = event.target.value;
    const selectedJSON = JSON.stringify(selectedValues);
    setJobPreferences(event.target.value);
    setPreferences(selectedJSON)
    
    };
    // console.log(Languages);
    const isMinSelectionMet = Languages && Languages.length >= 2;

    const [errors, setErrors] = useState({});
      const handlePincodeInput = async (event) => {
        const inputValue = event.target.value;
        setPincode(inputValue);
        if (inputValue.length >= 5) {
            errors["pincode"]="";
            setErrors(errors);
        TaskAPi.GetCity(inputValue,{})
        .then((resp) => {setCity(resp.city)})
            .catch((error) => {
            const newErrors = {};
            newErrors["pincode"] = "Please Enter a valid Pincode";
            console.log(newErrors);
            setErrors(newErrors);
        setErrors(newErrors);
        })
        }
    };

    const handleSubmit = (event) => {
    setLoading(true)
    event.preventDefault();
    let url;
    if (user_type==="user"){
         url = `${BACKEND_URL}/auth/profile/seeker/`
    }
    else  { url = `${BACKEND_URL}/auth/profile/helper`}
    axios
      .put(
        url ,
        {
          phone_number: phone_number,
          first_name: first_name,
          last_name: last_name,
          address: address,
          pincode: pincode,
          city: city,
          job_preferences: Preferences,
          languages_selected: Languages,
        },
        {
          headers: authheaders(token),
        }
      )
      .then((resp) => {
        RefreshPage()
      })
      .catch((error) => {
        setLoading(false)
        const { data } = error.response;
        const errorFields = Object.keys(data);
        const newErrors = {};
        errorFields.forEach((field) => {
          const [errorMessage] = data[field];
          newErrors[field] = errorMessage;
        });
        setErrors(newErrors);
      });
  };
    return ( 
        
        
         <CardContent>
                <TextField
                  variant="outlined"
                  label="First Name"
                  sx={{paddingRight:1 , paddingBottom:1}}
                  margin="normal"
                  fullWidth
                  value={first_name}
                  onChange={event => setFirstName(event.target.value)}
                  error={errors.first_name ? true : false}
                  helperText={errors.first_name && errors.first_name}
                />
              <TextField
                variant="outlined"
                label="last Name"
                margin="normal"
                fullWidth
                sx={{paddingRight:1 , paddingBottom:1}}
                value={last_name}
                onChange={event => setLastName(event.target.value)}
                error={errors.last_name ? true : false}
                helperText={errors.last_name && errors.last_name}
              />
                
              <TextField
                fullWidth
                label="Phone Number"
                value={phone_number}
                onChange={(event) => setPhoneNumber(event.target.value)}
                error={Boolean(errors.phone_number)}
                helperText={errors.phone_number}
                sx={{paddingRight:1 , paddingBottom:1}}
              />
              <TextField
                variant="outlined"
                fullWidth
                label="Address"
                margin="normal"
                value={address}
                onChange={event => setAddress(event.target.value)}
                error={errors.address ? true : false}
                helperText={errors.address && errors.address}
              />
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              label="Pincode"
              value={pincode}
              onChange={handlePincodeInput}
              error={errors.pincode ? true : false}
              helperText={errors.pincode && errors.pincode}
            />
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              label="City"
              value={city}
              readonly
              error={errors.city ? true : false}
              helperText={errors.city && errors.city}
            />

        {user_type==="helper" && (
          <React.Fragment>
          <InputLabel>Select minimum of two languages spoken...</InputLabel>
        <Select
          label="Languages"
          multiple
          fullWidth
          mb={2}
            sx={{ p: 1,mb:2 ,ml:1,mr:2}}
          value={InputLanguages}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
        >

          {Listlanguages.map((language) => (
            <MenuItem key={language} value={language}>
              <Checkbox checked={InputLanguages.indexOf(language) > -1} />
              <ListItemText primary={language} />
            </MenuItem>
          ))}
        </Select>
 <InputLabel>Job Preferences...</InputLabel>
         <Select
          label="Job Preferences"
          multiple
          fullWidth
          mb={2}
            sx={{ p: 1,mb:2 ,ml:1,mr:2}}
          value={JobPreferences}
          onChange={handlePrefChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
        >

          {ListPreference.map((pref) => (
            <MenuItem key={pref} value={pref}>
              <Checkbox checked={JobPreferences.indexOf(pref) > -1} />
              <ListItemText primary={pref} />
            </MenuItem>
          ))}
        </Select>
      
</React.Fragment>
        )}


            
              <Box display="flex" justifyContent="space-between">
            <Button  variant="contained" onClick={handleSubmit} color="primary" type="submit">
              Update Profile
            </Button>
            <Button onClick={()=>setEditMode(false)} variant="contained" color="primary" type="submit">
              Close
            </Button>
            </Box>
              {errors.non_field_errors && (
              <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                {errors.non_field_errors}
    </Typography>
              )}
    </CardContent>
     );
}

export default ProfileUpdate;
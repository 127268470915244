import React,{useState,useEffect}  from 'react';
import {useCookies} from 'react-cookie';
import { connect } from 'react-redux';
import App from './PopOverMenu';
import { subscribeUser } from '../utils/webPush';
import useCheckAuth from '../commonuser/CheckAuth';
function NavBar(props) {
  
  const { first_name } = props;
  const [userType, , ] = useCookies(['UserType']);
  const token =useCheckAuth();
  const [, setSubcription] = useState(false);
   useEffect(()=>{
    const subscription = subscribeUser(token);
    setSubcription({ subscription });
  },[token])

  return (
    <React.Fragment>
      <App first_name={first_name} user_type={userType.UserType} />
      </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    first_name: state.user.first_name, 
  };
};

export default connect(mapStateToProps)(NavBar);


import axios from "axios"
import { BACKEND_URL,authheaders } from "../../../config"
export default class BookingApi {

    static GetBookings(token,body) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/booking/`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data
    }

    static JobDetail(token ,booking_id) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/booking/${booking_id}`, 
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data
    }

     static OnSpotBooking(token,body) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/booking/onspot/create/`,
        headers:authheaders(token),
        data :JSON.stringify(body)
      }

     return axios(options).then(resp => resp.data)
    
    }

    static UpdateBookingTime(token,task_id,body) {
       const options = {
        method: 'post',
        url:`${BACKEND_URL}/reschedule/${task_id}/`,
        headers: authheaders(token) ,
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }
    static BookingList(token ,status,page,per_page) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/booking/list/?page=${page}&per_page=${per_page}&status=${status}`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data  
      }
    static GetRequestForConfirmation(token ,task_id) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/marketplace/task/${task_id}/confirm/`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data  
      }
    static PostRequestForConfirmation(token ,task_id) {
       const options = {
        method: 'post',
        url:`${BACKEND_URL}/marketplace/task/${task_id}/confirm/`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data  
      }

    static StartBooking(token ,booking_id,body) {
       const options = {
        method: 'post',
        url:`${BACKEND_URL}/booking/start-booking/${booking_id}/`,
        headers:authheaders(token) ,
        data :JSON.stringify(body)
      }
      const data = axios(options).then(resp => resp.data)
      return  data  
      }
    static ShowStartBooking(token ,booking_id) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/booking/start-booking/${booking_id}/`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data  
      }

    static EndBooking(token ,booking_id) {
       const options = {
        method: 'post',
        url:`${BACKEND_URL}/booking/end-booking/${booking_id}/`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data  
      }
    static ShowEndBooking(token ,booking_id) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/booking/end-booking/${booking_id}/`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data  
      }
}
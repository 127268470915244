import React, { useState, useEffect } from "react";
import "./index.css";
import TranslateIcon from '@mui/icons-material/Translate';
import {useCookies} from 'react-cookie';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';



const LandingPage = () => {
  const access_enabled = useSelector(x => x.user.access_enabled);
  const helper_type = useSelector(x => x.user.helper_type);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const [token] = useCookies(['authtoken','UserType']);


   useEffect(() => {
    const timeout = setTimeout(() => {
       const authToken = Cookies.get('authtoken');
       console.log(authToken);
       const UserType = Cookies.get('UserType');
      if(!authToken || !UserType) {
            navigate('/login')           
        } else if(token['UserType'] === "user") {
            navigate('/user/task/create');
        } else if(token['UserType'] === "helper") {
           if (helper_type ==="ORG_ADMIN")  { navigate('/org/home')}  
           else {
            if (access_enabled) 
              { navigate('/helper/home')}  
            else { navigate('/profile/helper') }
           }
        } 
    }, 100);

    return () => clearTimeout(timeout);
  }, [token,access_enabled,navigate]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div className='icon-container' style={{
      marginTop: windowWidth < 800 ? "80%" : "25%",
      width: windowWidth < 800 ? "95%" : "80%",
    }}>
      {/*to write LOCALELINGO */}
      <span  style={{ fontSize: windowWidth < 800 ? 40 : 100 }}>
        L
      </span>
      <span  style={{ fontSize: windowWidth < 800 ? 40 : 100 }}>
        O
      </span>
      <span  style={{ fontSize: windowWidth < 800 ? 40 : 100 }}>
        C
      </span>
      <span style={{ fontSize: windowWidth < 800 ? 40 : 100 }}>
        A
      </span>
      <span  style={{ fontSize: windowWidth < 800 ? 40 : 100 }}>
        L
      </span>
      <span  style={{ fontSize: windowWidth < 800 ? 40 : 100 }}>
        E
      </span>
      <span style={{ fontSize: windowWidth < 800 ? 40 : 100 }}>
        L
      </span>
      <span  style={{ fontSize: windowWidth < 800 ? 40 : 100 }}>
        I
      </span>
      <span  style={{ fontSize: windowWidth < 800 ? 40 : 100 }}>
        N
      </span>
      <span  style={{ fontSize: windowWidth < 800 ? 40 : 100 }}>
        G
      </span>
      <span  style={{ fontSize: windowWidth < 800 ? 40 : 100 }}>
        O
      </span>
      {/* <TranslateIcon className="text-icon" style={{ fontSize: windowWidth < 800 ? 50 : 100 }} /> */}

    </div>
  );
};

export default LandingPage;

import React from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const NoDriversAvailable = ({ open, onClose, message, buttonText }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>No Helpers Available</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoDriversAvailable;

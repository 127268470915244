import React, { useState } from 'react';
import axios from 'axios';
import useCheckAuth from '../../../../commonuser/CheckAuth';
import { Box, Dialog,DialogTitle,DialogContent, Button, TextField } from '@mui/material';
import DatePicker from '../../../utility/DatePicker';
import { BACKEND_URL } from '../../../../../config';
function UpdateTaskTime({taskId,old_start_time,old_end_time,openReschuleTask,setOpenReschuleTask}) {
    const [start_time, setStartTime] = useState(old_start_time);
    const [end_time, setEndTime] = useState(old_end_time);
    const [description, setDescription] = useState('');
    const task_id =taskId;
    const [errors, setErrors] = useState({});
    const [minTime] = useState(30)

    const token  = useCheckAuth();
    const handledescription = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = event => {
    console.log(description);
    event.preventDefault();
    axios
      .post(`${BACKEND_URL}/booking/reschedule/${task_id}/`, {
        end_time: end_time,
        start_time: start_time,
        description: description,
      }, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) =>  {
            window.location.reload();
        })
      .catch((error) => {
        
          const { data } = error.response;
          const errorFields = Object.keys(data);
          const newErrors = {};
          console.log(errorFields);
          errorFields.forEach(field => {
          const [errorMessage] = data[field];
          newErrors[field] = errorMessage;
          
          });
          setErrors(newErrors);
        })
  };
    return (
      <React.Fragment>
        
      <Dialog  open={openReschuleTask} onClose={() => setOpenReschuleTask(false)}>
          <DialogTitle sx={{margin: "auto" }}>Reschedule Booking</DialogTitle>
          <DialogContent>
            <Box sx={{flexGrow: 1 , display:'flex',margin: "auto" }} p={2}>
    <form style ={{flexGrow: 1 , display:'flex',margin: "auto" , flexDirection:"column" }} onSubmit={handleSubmit}>
      <TextField
      fullWidth
      label="Message"
      sx={{mb:2}}
      value={description}
      onChange={handledescription}
      error={Boolean(errors.description)}
      helperText={errors.description}
      required
        />
       <DatePicker
        minutes = {minTime}
        hasMaxDate = {false}
        onStartChange={(date) => setStartTime(date)}
        onEndChange={(date) => setEndTime(date)}
        />      
      <Button variant='contained' sx={{mt:2}} type="submit"> Send Reschedule Request</Button>
      {errors.message && <div className="alert alert-danger">{errors.message}</div>}
      {errors.description && <div className="alert alert-danger">{errors.description}</div>}
    </form>
    </Box>
    </DialogContent>
    </Dialog>
    </React.Fragment>
  );

}

export default UpdateTaskTime;
import React, { useState,useEffect } from 'react';
import useCheckAuth from '../../../../commonuser/CheckAuth';
import { Pagination,Stack,  Card,Box, CardHeader,CardContent, Grid,Typography, Divider ,CardActionArea} from '@mui/material';
import Loading from '../../../../NavBars/Loading';
import moment from "moment";
import IsMobile from '../../../../Styles/IsMobile';
import UnreadMessages from '../TaskDetailComponents/UnreadMessages';
import TaskStatus from '../../../status/TaskStatus';
import TaskAPi from '../../TaskApi';
import TruncatedText from '../../../Jobs/components/TruncatedText';
function TaskTab({status}) {
    const token = useCheckAuth();
    const [tasks, setTasks] = useState([]);
    const [page, setPage] = useState(1);
    const fontSize = 15;
    const [loading, setLoading] = useState(true);
    const [tasksPerPage, ] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const isMobile=IsMobile()
    // eslint-disable-next-line
    const [error, setError] = useState(false);
    const dateConvert = (ds) =>{
        return  moment(ds).format('MMM Do YY, HH:mm');
    }
  //   const handleJobsPerPageChange = (event) => {
  //   setTasksPerPage(event.target.value);
  //   setPage(1);
  //   // setJobsToShow(jobs.slice(0, event.target.value));
  // };
    const handleChangePage = (event, value) => {
    setPage(value);

  };
    const fetchTasks = async () => {
    setLoading(true);
    try {
      const  response = await TaskAPi.TaskList(token ,status,page,tasksPerPage)
      setTasks(response.results);
      setTotalPages(Math.ceil(response.count / tasksPerPage));
      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  } 
  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line
  }, [status, page,tasksPerPage]);
    return ( 
    <div>
       {loading ? (
        <Loading />
      ) : (
        <div>
        {tasks.length === 0 ? (<Typography style={{ textAlign: 'center', marginTop: '50px' }}  variant="body1">No Tasks found.</Typography>): (
            <div>
              <Box 
        justifyContent= "space-evenly"
        alignItems="center"
         sx={{
        display: 'flex',
        justifyContent:'center',
        flexDirection:'column'
        }}
        >
            <Grid 
            direction="column"
            container 
            justifyContent= "center"
            alignItems="stretch"
            md={8} 
            lg={8}
            xl={8}
            spacing={2}>
            {tasks.map((task) => (
            <Grid 
            key={task.id} 
            item                
            xs={12} 
            md={8}  
            justifyContent= "space-evenly"
            alignItems="stretch">
            <Card style={{backgroundColor: "#0dcaf000"}} elevation={1} >
              <CardActionArea  href={`/user/task/${task.external_id}`}>
                <CardContent>
                  <Grid  
                    container
                    item xs={10} 
                    md={8}
                    justifyContent="space-evenly"
                    spacing={0.3}
                    >
                  <Grid item xs={10}>
                         <Typography sx={{
                    display:"flex",
                    fontSize:15 ,
                  justifyContent:'center'
                }} 
                variant="h6"
                >{task.title}

                </Typography>

                </Grid>
                  <Grid item xs={2}>
                { task.conversation && task.conversation.chat_enabled  &&  
                <CardHeader sx={{position:"absolute",right:0}} 
                component={UnreadMessages}
                 count={task.conversation.no_unread_messages}
                  task_id={task.external_id}>
                  
                </CardHeader> } 
                </Grid>      


                </Grid>
                <Divider component="p"/>
                <Grid container spacing={0.3}>
                  <Grid  item xs={8}>
                    {task.task_type==="IN_PERSON" ? (
                  <Typography sx={{ fontSize:fontSize  }}> <strong>In Person Appoinment </strong></Typography>
                  ) :
                  (<Typography sx={{ fontSize:fontSize   }}> <strong>Online Appoinment</strong></Typography>)}

                  <Typography sx={{ fontSize:10 ,mb:2  }}> Created {moment(task.created_at).fromNow()}</Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid  item xs={6}>
                    <Typography sx={{ fontSize:12 }} variant="body1"><strong>{dateConvert(task.start_time)}</strong></Typography>
                    <Typography sx={{ fontSize:11 ,mb:2 }} variant="subtitle1">Start Time</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontSize:12 }} variant="body1"><strong>{dateConvert(task.end_time)}</strong></Typography>
                    <Typography sx={{ fontSize:11 ,mb:2  }} variant="subtitle1">End Time</Typography>

                  </Grid>
                  <Grid  item xs={6}>
                    <Typography sx={{ fontSize:12  }} variant="body1"><strong>{task.parent_category_name}</strong></Typography>
                    <Typography sx={{ fontSize:10 ,mb:2 }} variant="subtitle1">Category</Typography>

                  </Grid>
                  <Grid item xs={6}>
                  {task.task_type==="IN_PERSON" && (
                    <React.Fragment>
                  <Typography sx={{ fontSize:12  }} variant="body1"><strong>{task.pincode}, {task.city}</strong></Typography>
                  <Typography sx={{ fontSize:10 ,mb:2 }} variant="subtitle1">Location</Typography>
                  </React.Fragment>

                  )}
                </Grid>
                <Grid  item xs={6}>
                {/* <Typography sx={{ fontSize:12  }} variant="body1"><strong>{task.status}</strong></Typography> */}
                <Typography sx={{ fontSize:12  }} variant="body1"><strong>
                  <TaskStatus status={task.status}/>
                  </strong></Typography>
                <Typography sx={{ fontSize:10 ,mb:2 }} variant="subtitle1">Status</Typography>
                  </Grid>
                </Grid>
                <Typography sx={{ fontSize:fontSize  }} variant="body1">
                <TruncatedText text={task.description} slice_no={isMobile ? 75 : 500}/>
                </Typography>

                </CardContent>
                </CardActionArea>
            </Card>
            </Grid>
            ))}
            
            
            </Grid>
            </Box>
            </div>
        )}
        {tasks && <div style={{ marginTop: '20px' }}> 
         <Stack sx={{display:'flex', alignItems: 'center'}} spacing={2}>
         <Pagination 
         sx={{ top: 'auto', bottom: 100 , position: 'fixed',}}
        showFirstButton
          showLastButton
          siblingCount={2}
          boundaryCount={2}
          size="small"
        count={totalPages}
         page={page}
         onChange={handleChangePage} 
         /> 
         </Stack>
         {/* <Select value={jobsPerPage} onChange={handleJobsPerPageChange}>
          <MenuItem value={10}>10 per page</MenuItem>
          <MenuItem value={20}>20 per page</MenuItem>
        </Select> */}
         </div> }
        
              </div>   
      )}
      
    </div> );
}

export default TaskTab;
import React from 'react';
import moment from "moment";
import { IconButton,CardContent,Card,Typography, Box,Divider } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OnSpotBookings from './OnSpotBookings';

function BoookingsLists({task,token,setShowTaskDetail,setshowTaskBookigns}) {

    const dateConvert = (ds) =>{
        return  moment(ds).format('MMMM Do YYYY, h:mm:ss a');
    }
    const handleGoBack = (event) => {
    event.preventDefault();
    setShowTaskDetail(true)
    setshowTaskBookigns(false)
  }

    const taskData=task
    return ( 
        <Box   p={2}>
        <IconButton sx={{p:3}} onClick={handleGoBack}>
          <ArrowBackIcon/>
        </IconButton>
        <Card
        style={{backgroundColor: "#0dcaf000"}}
        elevation={0}
          >
            <Typography  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}variant="h4">Booking Detail</Typography>
            <Divider sx={{ bgcolor: "secondary.light" }} component="p" />
            <CardContent  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}>
                <Typography variant="h5">{taskData.title}</Typography>
                <Typography  variant="subtitle1">
                    <strong>Start Time: </strong> {dateConvert(taskData.start_time)}
                </Typography>
                <Typography variant="subtitle1">
                    <strong>End Time:  </strong> {dateConvert(taskData.end_time)}
                </Typography>
            </CardContent>
          </Card>
            <Typography  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}variant="h5">Additonal Booking Details</Typography>
            <Divider sx={{ bgcolor: "secondary.light" }} component="p" />
        <CardContent  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}>
            {
                task.onspot_booking_data.length === 0 && <div>No Bookings Found</div>
            }
            {
                task.onspot_booking_data.length !== 0 && <OnSpotBookings onsbks={task.booking.onspot_booking_data} token={token} taskId={task.external_id}/>
            }
        </CardContent>
         
        </Box>
        
     );
}

export default BoookingsLists;
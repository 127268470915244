import React, { useState } from 'react';
import { Box,Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import UserApiService from './UserApi';
import useCheckAuth from './CheckAuth';
const DeleteAccountRequest = () => {
  const [open, setOpen] = useState(false);
  const [deletionInProgress, setDeletionInProgress] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const token  = useCheckAuth();

  const handleDeleteAccount = () => {
    // Simulate deletion in progress
    setDeletionInProgress(true);
    UserApiService.DeletionRequest(token)

    // Simulate deletion request delay
    setTimeout(() => {
      // Handle delete account logic here
      // ...
      // After deleting the account, you can redirect or show a success message

      // Simulate deletion completed
      setDeletionInProgress(false);
      setOpen(false);
    }, 1000);
  };

  return (
    <div>
        <Box sx={{flexGrow: 1}} p={2}>
      <Button variant="outlined" color="error" fullWidth onClick={handleOpen}>
        Delete Account
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete Account</DialogTitle>
        <DialogContent>
          {deletionInProgress ? (
            <Typography variant="body1">Your request for account deletion has been submitted.</Typography>
          ) : (
            <Typography variant="body1">Are you sure you want to delete your account? This action cannot be undone.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          {!deletionInProgress && (
            <>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteAccount} color="error">
                Delete Account
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {/* {deletionInProgress && <Typography variant="body1">your account deletion request is in progress.</Typography>} */}
      </Box>
    </div>
  );
};

export default DeleteAccountRequest;

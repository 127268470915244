import React, { Component } from 'react';
import MapWithPolygonDrawing from './components/MapComponent';
function HelperPreference() {
    return ( 
        <div>
            <MapWithPolygonDrawing/>
        </div>
     );
}

export default HelperPreference;
import { BACKEND_URL ,authheaders } from "../../config"
import axios from 'axios';
export default class HelperApiService {
  
    static HelperStats(token) {
       const options = {
        method: 'post',
        url:`${BACKEND_URL}/stats/`,
        headers: authheaders(token),
        data :JSON.stringify({})
      }
     return axios(options).then(resp => resp.data)
    }

}
import React ,{ useState}from 'react';
import { Link,FormControlLabel,Radio,TextField,FormControl,RadioGroup, Dialog, DialogTitle , DialogContent, DialogActions, Button, Typography } from '@mui/material';
import TaskAPi from '../../TaskApi';
function Canceltask({taskId,token,showTaskCancel, setshowTaskCancel}) {
    const [reason, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [showOtherReason, setShowOtherReason] = useState(false);
    const [showConfirmbutton, setShowConfirmbutton] = useState(false);
    const reasons = ['Third Party Cancelled the appoinment', 'Change of Plans', 'Helper requested to Cancel', 'Other'];

    const handleReasonChange = (e) => {
    const selectedReason = e.target.value;
    setReason(selectedReason);
    setShowConfirmbutton(true)

    if (selectedReason === 'Other') {
      setShowOtherReason(true);
      setShowConfirmbutton(false)
    } else {
      setShowOtherReason(false);
      setOtherReason('');
    }
  };
  const handleOtherReasonChange = (e) => {
    setOtherReason(e.target.value);
    setShowConfirmbutton(true)
    
  };

    const TaskCancel = (task_id) => {
      if (reason === 'Other') {
        const  cancellation_reason = otherReason
        TaskAPi.TaskCancel(token,task_id,{cancellation_reason})
          .then(resp => {
              console.log(resp)
              window.location.reload(false);
          }
          )
      } else{
        const  cancellation_reason = reason
        TaskAPi.TaskCancel(token,task_id,{cancellation_reason})
          .then(resp => {
              console.log(resp)
              window.location.reload(false);
          }
          )
      }
    
    }
    // const handleOpen = () => {
    //         setshowTaskCancel(true);
    //     };

    const handleClose = () => {
            setshowTaskCancel(false);
        };

    return ( 

    <React.Fragment>
      
      <Dialog open={showTaskCancel} variant="contained" onClose={handleClose}>
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
            <Typography sx={{p:1}}><strong>Are you sure you want to cancel this Task?</strong></Typography>
            <Typography sx={{p:1}}>Please Check out Cancellation Policy  <Link href="https://www.localelingo.com/">here</Link></Typography>
            <form >
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="reason"
            name="reason"
            value={reason}
            onChange={handleReasonChange}
          >
            {reasons.map((r) => (
              <FormControlLabel
                key={r}
                value={r}
                control={<Radio />}
                label={r}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {showOtherReason && (
          <TextField
            id="other-reason"
            label="Other Reason"
            value={otherReason}
            onChange={handleOtherReasonChange}
            fullWidth
            margin="normal"
          />
        )}
       </form>
            </DialogContent>
        <DialogActions>
          
          <Button 
          
          onClick={handleClose} 
          color="primary">
            Cancel
          </Button>
          <Button 
          disabled={!showConfirmbutton}
          onClick={() => {
            TaskCancel(taskId);
            handleClose();
          }} variant="contained"  color='error'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>    
    </React.Fragment>

    );
}

export default Canceltask;
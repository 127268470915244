import React ,{ useState,useRef}from 'react';
import { Grid,TextField, Dialog, DialogTitle , DialogContent, Button, Typography } from '@mui/material';
import BookingApi from '../../BookingApi';
import RefreshPage from '../../../../utils/refreshPage';
const SlideButton = ({bookingId,token,showBookingStart, setshowBookingStart}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);

  const [, setShowError] = useState(false);
  const [errors, setErrors] = useState({})

  const handleClose = () => {
    setshowBookingStart(false);
};
  const StartBooking = (e) =>{
      e.preventDefault();
      console.log(otp);
      const n_otp = otp.join('')
      BookingApi.StartBooking(token,bookingId,{otp:n_otp})
      .then(resp=>{
        console.log(resp);
        handleClose();
        RefreshPage()
      })
      .catch((error)=>{
        setErrors(error.response.data);
        setShowError(true)
      })

    }
  const handleOtpChange = (index, event) => {
    const { value } = event.target;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
   const handleOtpKeyDown = (index, event) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <React.Fragment>
        <Dialog open={showBookingStart} variant="contained" onClose={handleClose}>
         <DialogTitle>Start Booking</DialogTitle>
          <DialogContent>
            <Typography sx={{p:1}}>Enter OTP from User</Typography>
            <React.Fragment>
              <Grid sx={{p:1}} container spacing={0.5} alignItems="center" flexWrap="nowrap">
              {otp.map((digit, index) => (
                <Grid item key={index}>
        <TextField
          key={index}
          sx={{width:"100%"}}
          inputRef={(ref) => (inputRefs.current[index] = ref)}
          // variant="outlined"
           size="small"
           variant="filled"
          value={digit}
          onChange={(event) => handleOtpChange(index, event)}
          onKeyDown={(event) => handleOtpKeyDown(index, event)}
          inputProps={{
            pattern: '[0-9]', // Allow only numeric input
            maxLength: 1, // Limit input to a single digit
          }}
        />
        </Grid>
      ))}
      </Grid>
          {/* <Grid sx={{p:1}} container spacing={0.5} alignItems="center" flexWrap="nowrap">
        {otp.map((digit, index) => (
          <Grid item key={index}>
            <TextField
             sx={{width:"100%"}}
              size="small"
              type="text"
              maxLength={1}
              inputMode="numeric"
              pattern="[0-9]*"
              value={digit}
              variant="filled"
              onChange={(e) => handleOtpChange(e, index)}
            />
          </Grid>
        ))}
      </Grid> */}
      {errors.error && <div className="alert alert-danger">{errors.error}</div>}
      </React.Fragment>         
            <Button 
            fullWidth
            onClick={(e) => {
                StartBooking(e);
                
            }} variant="contained"  color='success'>
                Confirm
          </Button>
          

          </DialogContent>
          </Dialog>

    </React.Fragment>
  );
};

export default SlideButton;

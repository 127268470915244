import React ,{useEffect, } from 'react';
import {Stack} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ErrorToast = ({message }) => {

   useEffect(()=>{
    Object.entries(message).map(([field, message]) => (
      toast.error(message)
  ))

   },[message])

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <ToastContainer  />
      </Stack>
  );
};

export default ErrorToast;

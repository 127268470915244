import React, {  useState } from 'react';
import { Button, TextField, Checkbox,Dialog, DialogActions, DialogContent, DialogTitle, Stepper, Step, StepLabel, Typography } from '@mui/material';
import Checkout from './Payment';
import { useSelector } from 'react-redux';
import PaymentApi from './PaymentApi';
import useCheckAuth from '../commonuser/CheckAuth';
function PaymentConnect() {
    const credit = useSelector(x => x.user.credit);
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [totalCost, setTotalCost] = useState(11.9);
    const [total_price_exc_vat,setTotalPriceExcVat] = useState(10);
    const [total_price_inc_vat,setTotalPriceIncVat] = useState(11.9);

    
    const [vat_amount,setVatAmount] = useState(10);
    const [number_of_connect, setNumberOfConnect] = useState(100);
    const token = useCheckAuth();
    const steps = ['Total Cost', 'Payment'];
    const [checked, setChecked] = useState(false);
    
    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
          setTotalCost(totalCost - credit);
          console.log(totalCost - credit);
        } else {
          setTotalCost(total_price_inc_vat);
        }
    };

    const handleConnectsChange = (event) => {
    
    const number_of_connects = event.target.value
    setNumberOfConnect(number_of_connects);
    PaymentApi.ConnectsCalculate(token,{number_of_connects}).then((resp) => {
      console.log(resp);
      setTotalPriceExcVat(resp.total_price_exc_vat)
      setVatAmount(resp.vat_amount)
      setTotalPriceIncVat(resp.total_price_inc_vat)

    });
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return (
    <React.Fragment>
      <Button sx={{fontSize:10}} onClick={handleOpen}>
        Buy more
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Payment</DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === 0 ? (
              <div>
                <TextField
                    type="number"
                    label="Connects"
                    value={number_of_connect}
                    onChange={handleConnectsChange}
                    required
                  />
                <Typography variant="h5">Total Price exc Vat: {total_price_exc_vat}</Typography>
                <Typography variant="h5">Total Price inc Vat: {total_price_inc_vat}</Typography>
                <Typography variant="h5">Vat Amount: {vat_amount}</Typography>
                {credit>0 && <Typography variant="h5">Use credits({credit}) 
                 <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                </Typography> }
                {checked && <Typography variant="h5">Total Cost(Reduced Credits): {totalCost}</Typography> }
              </div>
            ) : (
              <div>
                <Checkout number_of_connects={number_of_connect} type={"connect"} useCredits={checked}/>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          {activeStep === steps.length - 1 ? null :
           <Button variant="contained" color="primary" onClick={activeStep === steps.length - 1 ? handleClose : handleNext}>
            Next
          </Button>
          }
          
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default PaymentConnect;


import { BACKEND_URL,authheaders } from "../../../config"
import axios from "axios"
export default class ChatAPi {
  

    static ChatHistory(token,id,page,pageSize) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/chat/${id}/?page=${page}&page_size=${pageSize}`, 
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data
    }
    static MessageCreate(token ,id,body) {
      const options = {
        method: 'post',
        url: `${BACKEND_URL}/chat/create/${id}/`,
        headers:authheaders(token),
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }
}

import axios from 'axios';
import { BACKEND_URL , authheaders } from "../../../config"
export default class TaskAPi {

  static GetNumberOfHelpers(token,body) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/marketplace/locations/helpers/`,
        headers:authheaders(token),
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }

  static ValidateCoupon(token,body) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/payment/validate-coupon/`,
        headers:authheaders(token),
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }


  static GetCity(pincode,body) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/marketplace/locations/${pincode}/`,
        headers: {
            'Content-Type':'application/json',
          },
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }


    static GetCategories(token,body) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/marketplace/service/`,
        headers: authheaders(token),
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }

    static GetTaskList(token,body) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/marketplace/task/create`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data
    }
    
    static CreateTask(token,body) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/marketplace/task/create`,
        headers:authheaders(token),
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }

    static TaskDetail(token,id) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/marketplace/task/${id}/`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data
    }

    static GetFixedPrice(token ,body) {
      const options = {
        method: 'post',
        url: `${BACKEND_URL}/marketplace/calculate-service/`,
        headers: authheaders(token),
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }

    static QuoteAccept(token ,quote_id) {
      const options = {
        method: 'post',
        url: `${BACKEND_URL}/marketplace/quotes/${quote_id}/accept/`,
        headers: authheaders(token),
        data :JSON.stringify({})
      }
      return axios(options).then(resp => resp.data)
    }

    static RescheduleAction(token ,rschbk_id,body) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/booking/reschedule/action/${rschbk_id}/`,
        headers: authheaders(token) ,
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }

    static TaskCancel(token ,task_id,body) {
      console.log("test",body);
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/marketplace/task/${task_id}/cancel/`,
        headers: authheaders(token) ,
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }

    static OnspotBookingCancel(token ,onspot_booking_id) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/booking/onspot/cancel/${onspot_booking_id}/`,
        headers: authheaders(token) ,
        data :JSON.stringify({})
      }
      return axios(options).then(resp => resp.data)
    }

    static TaskFeedback(token ,body) {
      const options = {
        method: 'post',
        url: `${BACKEND_URL}/marketplace/rating/create/`,
        headers:authheaders(token),
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }

    static TaskList(token ,status,page,per_page) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/marketplace/task/list?page=${page}&per_page=${per_page}&status=${status}`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data  
      }

    static TaskOtp(token ,task_id) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/booking/booking-otp/${task_id}/`,
        headers:authheaders(token),
        data :JSON.stringify({})
      }
      return axios(options).then(resp => resp.data)
    }

}
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import UserApiService from './UserApi';
const VerifyEmail = () => {
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  const { key } = useParams();
  
  const handleVerifyEmail = () => {
    UserApiService.EmailVerification({key},key).then(setIsVerified(true));
  };

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Verify Your Email
      </Typography>
      {!isVerified ? (
        <>
          <Typography align="center" gutterBottom>
            Please click the link in the email we sent you to verify your account.
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleVerifyEmail}
            sx={{ mt: 2 }}
          >
            Verify Email
          </Button>
        </>
      ) : (
        <>
          <Typography align="center" gutterBottom>
            Your email has been verified.
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleLogin}
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </>
      )}
    </Container>
  );
};

export default VerifyEmail;

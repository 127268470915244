import {
  LoginSocialFacebook,
} from 'reactjs-social-login'

import GoogleIcon from '@mui/icons-material/Google';
import { useGoogleLogin } from '@react-oauth/google';

import UserApiService from "./UserApi";
import {Divider, Button,CardContent,Typography,IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FacebookIcon from '@mui/icons-material/Facebook';
import { GOOGLE_REDIRECT_URI ,FB_APP_ID } from '../../config';
function SocialConnect({token,setShowSocialAccont,setshowProfile}) {

    const [googleConnect, setGoogleConnect] = useState(false);
    const [facebookConnect, setFacebookConnect] = useState(false);
    const handleGoBack = (event) => {
    event.preventDefault();
    setShowSocialAccont(false)
    setshowProfile(true)
  }
    const Googlogin = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri: GOOGLE_REDIRECT_URI ,
    onSuccess: tokenResponse => {
    console.log(tokenResponse);
    const code=tokenResponse.code
    UserApiService.GoogleConnectUser(token,{code})
    .then(resp => {
      setShowSocialAccont(false)
    setshowProfile(true)
    })}
    });

    const handleFacebookLogin = (credentialResponse)=>{
    const access_token=credentialResponse.accessToken
    UserApiService.FacebookConnectUser(token,{access_token})
    .then(resp => {
    })
    } 

    useEffect(()=>{UserApiService.LinkedAccounts(token)
        .then(
            resp=>{
            const hasFacebook = resp.some((account) => account.provider === "facebook");
            setFacebookConnect(hasFacebook)
            const hasgoogle = resp.some((account) => account.provider === "google");
            setGoogleConnect(hasgoogle)
        }
        )})
    return ( 
    <div>
       <IconButton onClick={(e)=>{handleGoBack(e)}}>
          <ArrowBackIcon/>
        </IconButton>
           <Typography  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}variant="h4">Link Accounts</Typography>
          <Divider sx={{ bgcolor: "secondary.light" }} component="p" />

        {googleConnect && facebookConnect && (
            <Typography sx={{ mt:5, alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}} variant="h6" >All Accounts Linked</Typography>
        )}
        <React.Fragment>
        

        {!facebookConnect && (
          <React.Fragment>
        <CardContent  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}>
        <LoginSocialFacebook
        isOnlyGetToken
        appId = {FB_APP_ID}
        onResolve={({ provider, data }) => {
          handleFacebookLogin(data)
        }}
        onReject={(err) => {
          console.log(err)
        }}
          >
      <Button startIcon={<FacebookIcon/>} fullWidth variant='contained' style={{ background: "#3b5998",color:"white",margin:"auto" ,paddingTop:2}} >Login via Facebook</Button>
      </LoginSocialFacebook>
      <div style={{margin:10}}></div>
     
      {!googleConnect&& ( <Button  startIcon={<GoogleIcon />} variant='contained' sx={{background:"white",color:'black'}} onClick={() => Googlogin()}>
        Sign in with Google 
      </Button>
      
       )}
        </CardContent>
      </React.Fragment>
      )}
       
        <Typography  sx={{ mt:5, alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}variant="h4">Linked Accounts</Typography>
        <Divider sx={{ bgcolor: "secondary.light" }} component="p" />
        {!googleConnect && !facebookConnect && (
            <Typography sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}variant="h6">No Accounts Linked</Typography>
        )}
         {googleConnect &&  <Typography sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}variant="h6">Google Account Linked</Typography> }
          {facebookConnect &&  <Typography sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}variant="h6">FaceBook Account Linked</Typography> }
      </React.Fragment>
        
        

    </div>
     );
}

export default SocialConnect;

import React, { useEffect, useState } from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';

function DatePickerv2({ startdate, enddate ,onStartChange , onEndChange ,minutes,hasMaxDate}) {
  const handleDateChange = (dateString) => {
        const newDate = new Date(dateString);
        return dayjs(newDate);
      };
  const nw = dayjs()
  const [startDate, setStartDate] = useState(startdate ? handleDateChange(startdate) : nw);
  const [endDate, setEndDate] = useState(enddate ? handleDateChange(enddate) : nw);
  const [StartTimeError, setStartTimeErrors] = useState({"message":""})
  const [EndTimeError, setEndTimeErrors] = useState({"message":""})

  useEffect(()=>{
    if (startDate!==nw) {
      validateStartDate(startDate)
    }
    if (endDate !== nw) {
      validateEndDate(endDate)
    }
      
      // eslint-disable-next-line
  },[nw,startDate,endDate])


  const getMaxDate = () => {
    if (hasMaxDate) {
      const maxDate =endDate;
      return maxDate;
    }
    return null; // if hasMaxDate is false, no max date is set
  };

  const validateStartDate =(dt) =>{
    dt = dayjs(dt)

    if (
        dt.hour() >= 7 &&
        dt.hour() <= 19  
      ) {
        if (dt.minute() % 30 === 0) {
          onStartChange(dt)
        setStartTimeErrors({});} else { setStartTimeErrors({"message":"time must be in range of 30 mins"}); }
              } else {
        setStartTimeErrors({"message":"time is not in the range 7AM to 7 PM"}); 
    }
  }
  const validateEndDate =(dt) =>{
    dt = dayjs(dt)

    if (
        dt.hour() >= 7 &&
        dt.hour() <= 19  
      ) {
        if (dt.minute() % minutes === 0) {
        setEndTimeErrors({});
        if (dt- startDate >= minutes * 60 * 1000) {
          onEndChange(dt)
           setEndTimeErrors({});
        } else { setEndTimeErrors({"message":`must be atleast ${minutes} minutes`}); }
      } else { 
          setEndTimeErrors({"message":"time must be in range of 30 mins"}); }
              } else {
        setEndTimeErrors({"message":"time is not in the range 7AM to 7 PM"}); 
    }
  }

  // Disable times before 7:00 AM and after 7:00 PM
  const shouldDisableTime = (timeValue) => {
    const currentHour = timeValue.hour();
    return currentHour < 7 || currentHour >= 19;
  };


  return (
    <div>
       <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <MobileDateTimePicker 
      
        label="Start Time"
        value={startDate}
        minDate={startDate}
        onChange={(date)=>{setStartDate(date)}}
        disablePast
        minutesStep={minutes}
        maxDate={getMaxDate()}
        slotProps={{
          textField: {
            helperText: StartTimeError.message,
            error: Boolean(StartTimeError.message)
          },
        }}
        ampm={false}
        shouldDisableTime={shouldDisableTime} 
        />
         <MobileDateTimePicker 
        label="End Time"
        value={endDate}
        minDate={startDate}
        slotProps={{
          textField: {
            helperText: EndTimeError.message,
            error: Boolean(EndTimeError.message)
          },
        }}
        onChange={(date)=>{setEndDate(date)}}
        disablePast
        minutesStep={minutes}
        maxDate={getMaxDate()}
        ampm={false}
        shouldDisableTime={shouldDisableTime} 
        />
      </DemoContainer>
    </LocalizationProvider>
      </div>
  );
}
export default DatePickerv2;


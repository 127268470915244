import { SET_UNREAD_MESSAGES, SET_NOTIFICATIONS , RESET_NOTIFICATIONS_REDUCER } from './NotifyActions';

const initialState = {
  unread_messages: {},
  notifications: {},
};

const notifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNREAD_MESSAGES:
      return {
        ...state,
        unread_messages: action.payload,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case RESET_NOTIFICATIONS_REDUCER:
      return initialState;
    default:
      return state;
  }
};

export default notifyReducer;

import { BACKEND_URL } from "../../config"
import axios from 'axios';

export default class NotifyApiService {

    static AllNotification(token,body) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/notify/`,
        headers: {
            'Content-Type':'application/json',
            'Authorization':`Token ${token}` 
          },
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)
    }
    static UnreadNotification(token,body) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/notify/unread/`,
        headers: {
            'Content-Type':'application/json',
            'Authorization':`Token ${token}` 
          },
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)
    }

    static SentNotification(token,notifications_ids) {
       const options = {
        method: 'post',
        url:`${BACKEND_URL}/notify/sent/`,
        headers: {
            'Content-Type':'application/json',
            'Authorization':`Token ${token}` 
          },
        data :JSON.stringify({notifications_ids:notifications_ids})
      }
     return axios(options).then(resp => resp.data)
    }

    static ReadNotification(token,notifications_ids) {
       const options = {
        method: 'post',
        url:`${BACKEND_URL}/notify/read/`,
        headers: {
            'Content-Type':'application/json',
            'Authorization':`Token ${token}` 
          },
        data :JSON.stringify({notifications_ids:notifications_ids})
      }
     return axios(options).then(resp => resp.data)
    }


    static UnNotifyMessage(token,body) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/chat/unnotified/`,
        headers: {
            'Content-Type':'application/json',
            'Authorization':`Token ${token}` 
          },
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)
    }

    static unReadMessage(token,body) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/chat/unread/`,
        headers: {
            'Content-Type':'application/json',
            'Authorization':`Token ${token}` 
          },
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)
    }
}
import { useEffect, useState } from 'react';
import { Box, Typography,IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

function WaitingForVerificationBanner({onCloseButtonClick = null}) {
  const access = useSelector(x => x.user.access_enabled);
  const [showCloseButton, setshowCloseButton] = useState(false);
  const location = useLocation();
  useEffect(()=>{
    location.pathname === '/profile/helper'  && setshowCloseButton(true)
  },[location])
  const [showBanner, setShowBanner] = useState(!access);

  if (!showBanner) {
    return null;
  }

  const handleCloseButtonClick = () => {
    setShowBanner(false);

    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
  };

return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          width: '80%',
          height: '80%',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        
        {showCloseButton && (
          <IconButton
            sx={{ position: 'absolute',
             top: '1px',
              right: '1px' ,
              color: 'error',
            }}
            onClick={handleCloseButtonClick}
          >
            Close
            <CloseIcon />
          </IconButton>
        )}
        <Typography variant="h5" gutterBottom>
          Waiting for verification from admin
        </Typography>
        <Typography variant="body1">
          Your account is currently being reviewed by an administrator. Please wait for approval before continuing to use the site.
        </Typography>
      </Box>
    </Box>
  );
}
export default WaitingForVerificationBanner;
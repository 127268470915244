import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from '@mui/material';
import UserApiService from '../commonuser/UserApi';
import { useNavigate } from "react-router-dom";
import GoBack from '../commonuser/GoBack';
import ErrorToast from '../utils/ErrorToast';
const PasswordReset = () => {
  let navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    UserApiService.PasswordReset({email})
        .then((resp) => {
          setIsSuccess(true);
          setIsLoading(false);          
          setErrors({});
        })
        .catch((error) => {
                const { data } = error.response;
                const errorFields = Object.keys(data);
                console.log(errorFields);
                const newErrors = {};
                errorFields.forEach(field => {
                const [errorMessage] = data[field];
                newErrors[field] = errorMessage;
                });
                setErrors(newErrors);
                setIsLoading(false);       
              })
    setIsLoading(true);

  };

  return (
    <Container maxWidth="xs">
      <ErrorToast message={errors}/>
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <GoBack/>
        <Typography variant="h4">Password Reset</Typography>
        {isSuccess ? (
          <>
            <Typography sx={{ marginTop: 4, marginBottom: 2 }}>An email has been sent to {email} with instructions on how to reset your password.</Typography>
            <Button variant="contained" onClick={() => navigate('/login')}>Login</Button>
          </>
        ) : (
          <>
            <Typography sx={{ marginTop: 4, marginBottom: 2 }}>Enter your email address below and we'll send you a link to reset your password.</Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                id="email"
                label="Email Address"
                type="email"
                value={email}
                onChange={handleEmailChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                fullWidth
                sx={{ mt: 3, mb: 2 }}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Send Password Reset Email'}
              </Button>
            </form>
          </>
        )}
      </Box>
    </Container>
  );
};

export default PasswordReset;

import React from 'react';
import {useCookies} from 'react-cookie';
import {Button , Snackbar} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';

function Logout({first_name}) {
  const [, , removeUserType] = useCookies(['UserType']);  
  const [, , removeToken] = useCookies(['authtoken']);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const dispatch = useDispatch(); 

  const handleLogout = () => {
  try {
    const cookies = Cookies.get();
    console.log(cookies)
    for (const cookieName in cookies) {
      Cookies.remove(cookieName);
    }
    removeToken('authtoken');
    removeUserType('UserType');
     dispatch({ type: 'RESET_USER_REDUCER' });
    dispatch({ type: 'RESET_NOTIFICATIONS_REDUCER' });
  } catch (err) {
    console.error('Error removing cookies', err);
  }
}
const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };
    return ( 
    <div>
      <Button onClick={handleLogout} startIcon={<LogoutIcon />} color="inherit">
        {first_name}
      </Button>
      <Snackbar
        open={showSnackbar}
        onClose={handleCloseSnackbar}
        message="Logged out successfully"
        autoHideDuration={5000}
      />
    </div>
  );
}


export default Logout;
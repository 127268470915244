export const SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const RESET_NOTIFICATIONS_REDUCER = 'RESET_NOTIFICATIONS_REDUCER';


export const ResetNotificationReducers = () => ({
  type: RESET_NOTIFICATIONS_REDUCER,
});


export const setUreadMessages = (data) => ({
  type: SET_UNREAD_MESSAGES,
  payload: data,
});


export const setAllNotifications = (data) => ({
  type: SET_NOTIFICATIONS,
  payload: data,
});

import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

const Timer = ({ startTime }) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const endTime = new Date(startTime);
    endTime.setMinutes(endTime.getMinutes() + 7);
    const difference = endTime.getTime() - now.getTime();
    return Math.max(difference, 0);
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
      
      if (newTimeLeft <= 0) {
        clearInterval(intervalId);
        window.location.reload();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startTime]);

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / (60 * 1000));
    const seconds = ((timeLeft / 1000) % 60).toFixed(0);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <Typography variant="h4">
      {formatTime()}
    </Typography>
  );
};

export default Timer;


import { BACKEND_URL,authheaders } from "../../config"
import axios from 'axios';

export default class PaymentApi {
    static CreatePaymentSession(body,quote_id,token) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/payment/create-payment/${quote_id}/`,
        headers: authheaders(token),
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)
    }

    static CreateConnectPaymentSession(body,token) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/payment/create-connect-payment/`, 
        headers: authheaders(token),
        data :JSON.stringify(body)
      }
     return axios(options).then(resp => resp.data)
    }

    static CreateOnspotBookingPaymentSession(body,onsbk_id,token) {

      const options = {
        method: 'post',
        url:`${BACKEND_URL}/payment/create-onspot-payment/${onsbk_id}/`,
        headers: authheaders(token),
        data :JSON.stringify(body)
      }

     return axios(options).then(resp => resp.data)

    }

    static PaymentSucess(session_id,task_id,token) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/payment/success?session_id=${session_id}&task_id=${task_id}`, 
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data
    }

    static PaymentCancel(session_id,task_id,token) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/payment/cancel?session_id=${session_id}&task_id=${task_id}`, 
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data
     
    }

    static ConnectsCalculate(token,body) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/payment/calculate-connect-payment/`,
        headers: authheaders(token),
        data :JSON.stringify(body)
      }
      return axios(options).then(resp => resp.data)
    }
}
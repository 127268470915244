import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Snackbar } from '@mui/material';

function EnableNotification() {

  const [open, setOpen] = useState(false);
  const [isDeclined, setIsDeclined] = useState(true);
  const [isOpenDeclined, setIsOpenDeclined] = useState(false);
  // const [isEnabled, setIsEnabled] = useState(Notification.permission === "granted");
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
     try {
    if (typeof Notification !== "undefined" && Notification.permission === "granted") {
      setIsEnabled(true);
    }
  } catch(e) { console.error(e); }
  }, []);

  

  useEffect(()=>{
    try {
     if ('serviceWorker' in navigator) {
     
      if (Notification.permission === "denied") {
        setIsOpenDeclined(true)
         setOpen(false)
      } else if (Notification.permission === "granted") {
        setOpen(false)
      } else {
        setOpen(true)

      }
     }
     } catch(e) { console.error(e); }
  },[])

  const handleToggle = () => {
     try {
    if (isEnabled) {
      Notification.permission === "denied" ? alert("Notifications are blocked!") : Notification.permission === "granted" ? setIsEnabled(false) : Notification.requestPermission().then(permission => setIsEnabled(permission === "granted"));
    } else {
      window.Notification.requestPermission().then(
        (permission) => {
          setIsEnabled(permission === "granted")
         if (permission === "granted") {setOpen(false)}
         if (permission === "denied") {
          setOpen(false)
          setIsDeclined(true)
          setIsOpenDeclined(true)
        }
      }
        );
    }
    } catch(e) { console.error(e); }
  };

  const handleClickDecline= ()=>{
    setIsOpenDeclined(false)
  }

  const handleClick = () => {
    const result =  window.Notification.requestPermission().then(
      (permission) => {
        console.log(permission);
        if (permission === "granted") {setOpen(false)}
         if (permission === "denied") {
           setOpen(false)
          setIsDeclined(true)
        setIsOpenDeclined(true)
      }
  });
    
    if (typeof Notification !== 'undefined' && Notification.permission !== "granted") {
      window.Notification.requestPermission().then((permission) => {
        setIsEnabled(permission === "granted")
        if (permission === "granted") {setOpen(false)}
         if (permission === "denied") {
           setOpen(false)
          setIsDeclined(true)
          setIsOpenDeclined(true)
        }
      });
    }
  }
  // run()
  
  return (
    <React.Fragment>
      {
        
      <Snackbar
      sx={{mb:10}}
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message="This website would like to send you notifications."
      action={
        <>
          <Button 
          id={"subscribe"}
          color="primary" 
           type='button' 
           onClick={handleClick}
          >
            Allow
          </Button>
        </>
      }
    />
        
      }
      {
         <Snackbar
      sx={{mb:10}}
      open={isOpenDeclined}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message="Notification is Blocked"
      action={
        <>
          <Button 
          id={"subscribe"}
          color="primary" 
           type='button' 
           onClick={handleClickDecline}
          >
            Close
          </Button>
        </>
      }
    />
      }
     
    {/* <Button id={"subscribe"} type='button' onClick={handleClick} >{isEnabled ? "Disable notifications" : "Enable notifications"}</Button> */}
  </React.Fragment>
  );
}

async function run() {
  // A service worker must be registered in order to send notifications on iOS
  // const registration = await navigator.serviceWorker.register(
  //   "sw.js",
  //   {
  //     scope: "/",
  //   }
  // );

  const button = document.getElementById("subscribe");
  button.addEventListener("click", async () => {
    // Triggers popup to request access to send notifications
    const result = await window.Notification.requestPermission();
    console.log(result);

    // If the user rejects the permission result will be "denied"
    if (result === "granted") {
      // You must use the service worker notification to show the notification
      // Using new Notification("Hello World", { body: "My first notification on iOS"}) does not work on iOS
      // despite working on other platforms
      // await registration.showNotification("Hello World", {
      //   body: "My first notification on iOS",
      // });
    }
  });
}


export default EnableNotification;

import React, { useState, useEffect } from 'react';
import {Grid,Avatar,Divider,ListItemButton,ListItemText,Box, Card, CardContent, CardHeader, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import useCheckAuth from '../commonuser/CheckAuth';
import NavBar from '../NavBars/navBar';
import EuroIcon from '@mui/icons-material/Euro';
import { useDispatch } from 'react-redux';
import { setUserEmail ,setUserCredit,setUserFirstName,setUserLastName} from '../reducers/userActions';
import Loading from '../NavBars/Loading';
import UserApiService from '../commonuser/UserApi';
import theme from '../Styles/theme';
import { MoreVert } from '@mui/icons-material';
import ProfileUpdate from './ProfileUpdate';
import PasswordChange from './PasswordChange';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BottomBar from '../NavBars/BottomBar';
import SocialConnect from '../commonuser/SocialConnect';
import Logout from '../NavBars/Logout';
import { useLocation } from 'react-router-dom';
import DeleteAccountRequest from '../commonuser/DeleteAccountRequest';
function UserProfile() {

  const location = useLocation()
  useEffect(()=>{
        console.log(location);
        console.log(document.referrer);

    },[])
  const [profile, setProfile] = useState({});
  const token = useCheckAuth();
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [showProfile, setshowProfile] = useState(true);
  const [passwordChange, showpasswordChange] = useState(false);
  const [ShowSocialAccont, setShowSocialAccont] = useState(false);
  const handleButtonClick = () => {
    setEditMode(true)
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch(); 
  useEffect(() => {
    UserApiService.GetUserProfile(token).then(resp => {setProfile(resp);
     dispatch(setUserEmail(resp.email));
     dispatch(setUserCredit(resp.user_credit))
     dispatch(setUserFirstName(resp.first_name));
     dispatch(setUserLastName(resp.last_name));
     setLoading(false)
  });
  }, [dispatch,token]);
  return (
    <Box>
      <NavBar />
      {loading ? (
           <Loading />
          ) : (
          <div>
            {ShowSocialAccont && <SocialConnect token={token} setShowSocialAccont={setShowSocialAccont} setshowProfile={setshowProfile}/> }
            {passwordChange && <PasswordChange token={token} showpasswordChange={showpasswordChange} setshowProfile={setshowProfile} /> }
          {!showProfile ? null :(

            <Box sx={{flexGrow: 1}} p={2}>
              <Card
              style={{backgroundColor: "#0dcaf000"}}
              sx={{ maxWidth: 800,margin: "auto",}}
              >
              <CardHeader
                avatar={
                    <Avatar aria-label="profile picture" 
                    sx={{backgroundColor: theme.palette.primary.main,}}
                     />
                  }
                 title={<Typography variant="h5">{profile.first_name} {profile.last_name}</Typography>}
                 subheader={<Typography variant="subtitle1">{profile.email}</Typography>}
                 action={
                  <React.Fragment>
                  <Logout/>
                    <IconButton aria-label="kebab menu" onClick={handleClick}>
                      <MoreVert />
                    </IconButton>
                    </React.Fragment>
                    }
                >
                </CardHeader>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  >
                  <MenuItem onClick={handleButtonClick}>Update Profile</MenuItem>
                </Menu>           
                <CardContent>
                <Typography variant="subtitle1">Referral Code: {profile.refferal_code}</Typography>
                <Typography variant="subtitle1">Credits: {profile.user_credit}<EuroIcon/></Typography>
              </CardContent>
              <CardContent>
                <Typography variant="h6">Personal Information</Typography>
              </CardContent>
              <Divider />
              { editMode ? (
             
             <ProfileUpdate user={profile} user_type="user" token={token} setEditMode={setEditMode} setLoading={setLoading}  />
              
            ) :(
              <CardContent>
                <Grid container spacing={0.3}>
                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>First Name:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.first_name}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>Last Name:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.last_name}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>Phone Number:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.phone_number}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>Address:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.address}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>Pincode:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.pincode}</Typography>
                  </Grid>

                  <Grid  item xs={4}>
                    <Typography  variant="subtitle1"><strong>City:</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1"> {profile.city}</Typography>
                  </Grid>

                </Grid>
              </CardContent>
            )}
            <CardContent>
            <Divider component="li" />
              <ListItemButton onClick={() => {
                setshowProfile(false)
                showpasswordChange(true)
              }} >
                <ListItemText primary="Password Change" />
                <ArrowForwardIosIcon/>
              </ListItemButton>
            <Divider component="li" />
              <ListItemButton onClick={() => {
                 setshowProfile(false)
                setShowSocialAccont(true)
                }} >
                <ListItemText primary="Link Social Accounts" />
                <ArrowForwardIosIcon/>
              </ListItemButton>
              </CardContent>
              <DeleteAccountRequest/>
              </Card>
              
            </Box>

          )} 
      </div>
          )}
          <BottomBar/>
    </Box>
    
    
    
  );
}

export default UserProfile;

import React, { useEffect,useState } from 'react';
import {
  List,
  Typography,
  Tabs,
  Tab,
  Button,
  Divider
} from '@mui/material';
import TabPanel from '../utils/TabPanel';
import NotifyApiService from './NotifyApi';
import useCheckAuth from '../commonuser/CheckAuth';
import NotificationItem from './notifcations/NotificationItem';
import { subscribeUser } from '../utils/webPush';
import { useDispatch } from 'react-redux';
import { setAllNotifications } from '../reducers/NotifyActions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NavBar from '../NavBars/navBar';
import BottomBar from '../NavBars/BottomBar';
import IsMobile from '../Styles/IsMobile';
import { useNavigate } from "react-router-dom";
import Loading from '../NavBars/Loading';
import { useSelector } from 'react-redux';

function NotifyAllMob() {
  const NewNotifyStatusList = ["CREATED",'NOTIFICATION_SENT'];
  const OldNotifyStatusList = ["READ"];
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isMobile = IsMobile()
  const notif = useSelector(x => x.notify.notifications);
  const [notifications, setNotifications] = useState([]);
  const [newnotifications, setNewNotifications] = useState([]);
  const [oldnotifications, setOldtNotifications] = useState([]);
   // eslint-disable-next-line 
  const token = useCheckAuth();
   useEffect(() => {
    setNotifications(notif)
   },
   [notif])

  useEffect(()=>{
    setNewNotifications(notifications.length ? notifications.filter(notify => NewNotifyStatusList.includes(notify.notification_status)): [])
    setOldtNotifications(notifications.length ? notifications.filter(notify => OldNotifyStatusList.includes(notify.notification_status)): [])
    // eslint-disable-next-line 
  },[notifications])
  const markread =(ids)=>{
    NotifyApiService.ReadNotification(token,ids).then((resp) => {
      console.log(resp);
    })
  }

  const handleClearAllClick = () => {
    const notificationIds = newnotifications.map(notification => notification.id);
    markread(notificationIds)
    const newNotifications = notifications.map(notification => ({
      ...notification,
      notification_status: "READ"
    }));
    setNotifications(newNotifications);
    // updatenotifcations()
    
  };

  const handleNotificationClick = (id) => {
    markread([id])
    const newNotifications = notifications.map((notification) => {
      if (notification.id === id) {
        return { ...notification, notification_status: "READ" };
      }
      return notification;
    });
    setNotifications(newNotifications);
  };

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(()=>{
    if (!isMobile){
        navigate('/')
    }
  })
  return (
    <>
    <div>
   <NavBar/>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
         <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="New" />
          <Tab label="Old" />
         </Tabs>
      <TabPanel value={tabValue} index={0}>
      <List >
          {newnotifications.length > 0 ? (
              newnotifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  handleNotificationClick={handleNotificationClick}
                />
              ))
            ) : (
              <List>
                <Divider variant="middle" color="primary" orientation="horizontal" > <CheckCircleOutlineIcon sx ={{ fontSize:50  , color:"green" }}/></Divider>
                <Typography sx={{textAlign: "center","overflowY": 'auto', "minWidth": '300px', "maxWidth": '500px',}} variant="h5">All caught up!</Typography>
                <Typography sx={{textAlign: "center"}} variant="body1">
                  You have no new Notifications
                </Typography>
              </List>
            )}
        </List>
        {newnotifications.length > 0 && (
          <Button
            variant="outlined"
            color="primary"
            sx={{width :'100%' , flexGrow: 1, display: "inline-block",justifyContent: 'left'}}
            onClick={handleClearAllClick}
          >
            Clear All
          </Button>
        )}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
      <List >

        {oldnotifications.length > 0 ? (
              oldnotifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  handleNotificationClick={handleNotificationClick}
                />
              ))
            ) : (
              <Typography sx ={{ "maxHeight": 'calc(100vh - 64px)' , "overflowY": 'auto', "minWidth": '300px', "maxWidth": '500px',}} variant="body1">No Notifications</Typography>
            )}
        </List>
      </TabPanel>   
     <BottomBar/>
     </React.Fragment>
      )}
     </div>
    </>
  );
}

export default NotifyAllMob;

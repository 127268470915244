import React, { useState, useEffect ,useRef } from 'react';
import ChatAPi from './ChatApi';
import { useParams } from 'react-router-dom';
import NavBar from '../../NavBars/navBar';
import { Box, TextField, Typography ,IconButton, Button,Skeleton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import RedirectHome from '../../payment/redirect';
import axios from "axios";
import CircularProgressWithLabel from '@mui/material/CircularProgress';
import useCheckAuth from '../../commonuser/CheckAuth';
import Loading from '../../NavBars/Loading';
import moment from 'moment';
import SendIcon from '@mui/icons-material/Send';
import { WEBSOCKET_URL } from '../../../config';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { BACKEND_URL } from '../../../config';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useSelector } from 'react-redux';
import BottomBar from '../../NavBars/BottomBar';
import SpeechBubble from './Components/SpeechBubble';
import { MAX_FILE_SIZE_MB,MAX_NO_OF_FILE } from '../../../config';
import LinkIcon from '@mui/icons-material/Link';
import {useCookies} from 'react-cookie';

function Chat() {
  const email = useSelector(x => x.user.email);
  const [userType, , ] = useCookies(['UserType']);
  const [ws, setWs] = useState(null);
  const [message, setMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const token  = useCheckAuth();
  const { taskId } = useParams();
  const [chatHistory, setChatHistory] = useState([]);
  const [premsg, setPremsg] = useState([]);
  const [error, setError] = useState(null);
  const [fileerror, setFileError] = useState(null);
  const [files , setFiles ] =  useState([]);
  const [EnableChat , setEnableChat ] =  useState(false);
  const [EnableUploadProgress , setEnableUploadProgress] =  useState(false);
  const [reconnecting, setReconnecting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initator, setInitator] = useState(0);
  const [receiver, setReceiver] = useState(1);

  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(10);
  const [loadingNewMessages, setLoadingNewMessages] = useState(false);
  const [abortController, setAbortController] = useState(null);
  

  const messagesRef = useRef(null);

  const [typing, setTyping] = useState(false);

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollIntoView();
    }
  };

  let typingTimer;
  const fetchMessages = async (token,taskId,page,pageSize) => {
      ChatAPi.ChatHistory(token,taskId,page,pageSize)
      .then((response)=>{
      setInitator(response.initiator.id)
      setReceiver(response.receiver.id)
      const newMessages = response;
      const getHistory = newMessages.message_set.map((msg) => msg);
      const newHasMore = response.has_more;
      setChatHistory(newMessages);
      setPremsg([...premsg , ...getHistory])
      setEnableChat(true);
      setHasMore(newHasMore);
      setLoading(false);
      setLoadingNewMessages(false);
      
      })
    }
  useEffect(() => {    
    fetchMessages(token,taskId,page,pageSize)
     // eslint-disable-next-line
  }, [taskId,token,page, pageSize]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        console.log(event);
        if (event.data.type === 'NEW_MESSAGE') {
          fetchMessages(token,taskId,1,pageSize)
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
        fetchMessages(token,taskId,1,pageSize)
    }, 60000); // 30000 milliseconds = 30 seconds

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks!
}, []); // Note this empty array, this effect runs once when the component mounts, and never again


  useEffect(()=>{
    if (premsg.length <= 10) {scrollToBottom()}
  },[premsg])

  const handleScroll = (event) => {
    // const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (event.target.scrollTop===0 && hasMore) {
      setLoadingNewMessages(true);
      setPage(page + 1);
    }
  };
  const handleLoadMore = () => {
    setLoadingNewMessages(true);
      setPage(page + 1);
  };
  const handleFileChange = (event) => {
  const selectedFiles = Array.from(event.target.files);
  const filesizes = [...files, ...selectedFiles]
  const totalSize = filesizes.reduce((acc, curr) => acc + curr.size, 0);
  const isFileNameValid = selectedFiles.every(file => file.name.length <= 50);
  if (totalSize > MAX_FILE_SIZE_MB * 1024 * 1024) {
        setFileError('File size cannot exceed 50 MB.');
      }
  else if (!isFileNameValid) {
    setFileError('File name cannot exceed 50 characters.');
  } else if (files.length + selectedFiles.length >= MAX_NO_OF_FILE) {
    setFileError('Maximum number of files exceeded. Please select up to 10 files.'); }
   else {
        setFiles([...files, ...selectedFiles]);
        setFileError(null);
      }
    };
  const handleRemoveFile = (fileIndex) => {
    setFiles((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex));
  };
   const handleScrollToBottomClick = () => {
    scrollToBottom();
  };



  const handleFileUpload =  async (event) => {
    event.preventDefault();    
    const handleSubmit = (attachment_ids) => {
      if (message === "") {setError('Please Enter a message to send')} else{
  
      ChatAPi.MessageCreate(token,taskId,{message ,attachment_ids})
      .then((resp)=>{ 
        setError()
        setFiles([])
        setMessage('')
        // setPremsg([resp, ...premsg]);
         setPremsg((premsg) => [resp, ...premsg]);
        scrollToBottom();
      }   
      ).catch((error) => {
        setError('Error Sending Message.')
        console.log(error);
      })
    
  }
  };
  const controller = new AbortController();
  setAbortController(controller);
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Token ${token}`
      },
      signal: controller.signal,
    };
    
    const total_file=files.length
    let uploaded_file = 0 
    if (files.length>0) {
      setEnableUploadProgress(true);
    setUploadProgress(0);
      try {
    axios.post(
      `${BACKEND_URL}/chat/upload/`,
      formData,
      config
    ).then((resp)=>{
      const attachment_ids = resp.data.map((res) => res.id);
      setEnableUploadProgress(false);
      handleSubmit(attachment_ids);
      setFiles([]);

    })
   
  } catch (error) {
    setEnableUploadProgress(false);
    setError('Error Occuered when uploading files.')
    setFiles([]);
  }

    }
    else {
      handleSubmit([]);
      setEnableUploadProgress(false);
    }


    
  };
  const handleCancelUpload = () => {
    setFiles([]);
    if (abortController) {
      abortController.abort();
    }
    setUploadProgress(0);
    setEnableUploadProgress(false);
  }
  console.log("userType",userType);
  return (
    <React.Fragment>
      <NavBar />

    <React.Fragment>
      {loading ? (
           <Loading />
          ) : (
            <React.Fragment>
      {EnableChat ? (
        <React.Fragment>
          <Box  sx={{overflow:"hidden",px: 3 , marginTop: 1 ,marginBottom:1 }}>
        <Typography align='center' variant="h4" gutterBottom>
          Task ID: {taskId}
          <IconButton component="a" 
          href={userType.UserType==="user" ? `/user/task/${taskId}` : `/booking/${taskId}`}
           >
            <LinkIcon />
          </IconButton>
        </Typography>
        
         <Box onScroll={handleScroll}   // set the height of the box
              sx={{ 
                height:"500px" , 
                overflowY :"scroll" ,
                display: "flex",
                flexDirection: "column",
                px: 4 , 
                marginTop: 2 ,
                overscrollBehaviorX:"contain"

                // overscroll-behavior-y= "contain",
                // marginBottom:15
               }}
          >
        {loadingNewMessages && <Typography variant="body2">Loading new messages...</Typography>}
        {!loading && hasMore && (
        <Button variant="text" sx={{display: 'flex', margin: 'auto',}} onClick={handleLoadMore}>
          Load More
        </Button>
        )}
        {premsg && premsg.length > 5 && (
          <IconButton sx={{
             position: 'fixed',
            bottom: '35px',
            right: '10px',
            display: 'block',
          }} onClick={handleScrollToBottomClick}>
            <ArrowDownwardIcon />
          </IconButton>
      )}
        {!hasMore && premsg.length > 5  && <p>No further messages</p>}
        {premsg &&
          premsg
            .slice(0)
            .reverse()
            .map((msg) => (
              <Box  ref={messagesRef} 
              key={msg.id} 
              sx={{ display: 'flex', flexDirection: 'column', alignItems: msg.sender === initator ? 'flex-end' : 'flex-start', mb: 2 }} >
                <Typography variant="body2"  color="textSecondary">
                    {msg.sender === initator ? chatHistory.initiator.first_name : chatHistory.receiver.first_name}:  {moment(msg.timestamp).fromNow()}
                </Typography>
                      <SpeechBubble message={msg.text} 
                      position={msg.sender === 1 ? 'right' : 'left'}
                      attachments={msg.attachments && msg.attachments.length >0 ? msg.attachments : null }
                       />
                {msg.files && msg.files.map((file, index) => (
                  <Box key={index}>
                    <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                    <IconButton onClick={() => handleRemoveFile(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            ))}
            </Box>
          
        <form onSubmit={handleFileUpload}>
          <Box >
            <div>
        {typing && (
        <Box display="flex" alignItems="center" marginBottom={1}
        >
          <Skeleton variant="circle" width={40} height={40} animation="wave" />
          <Box marginLeft={1}>
            <Skeleton variant="text" width={100} animation="wave" />
          </Box>
        </Box>
      )}

        {/* {reconnecting && <Typography variant="subtitle1">Reconnecting...</Typography>} */}

      </div>
          <TextField
            fullWidth
            variant="outlined"
            label="Enter message"
            color="warning"
            value={message}
            error={!!error}
            helperText={error}
            onChange={(e) => {
              setMessage(e.target.value)
            setError(null);} }
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {!EnableUploadProgress && 
                  <IconButton type="submit" color="primary">
                      <SendIcon />
                    </IconButton>
                  }
                  { !EnableUploadProgress && <IconButton variant="contained" component="label">
                    <AttachFileIcon />
                    <input type="file" hidden onChange={handleFileChange} multiple />
                  {fileerror && <Box sx={{ color: "error" }}>{fileerror}</Box>}
                  </IconButton> }
                </React.Fragment>
              )
            }}
          />
          {  !EnableUploadProgress && files.length > 0 && (
            <Box sx={{ mt: 2 }}>
              {files && files.map((file, index) => (
                <Chip key={index} label={file.name} onDelete={() => handleRemoveFile(index)} />
              ))}
            </Box>
          )}
           
          
          
          {EnableUploadProgress && 
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ marginRight: 1 }}>
          <CircularProgressWithLabel value={uploadProgress} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Chip  label="Uploading files" onDelete={() => handleCancelUpload()} />
        </Box>
      </Box>}
           </Box>
        </form>
        
      </Box>
      </React.Fragment>
      ) : (
        <RedirectHome/>
      )}
      </React.Fragment>
          )}
      </React.Fragment>
      <BottomBar/>
      </React.Fragment>
  );
}

export default Chat;
import React from 'react';

const PaymentStatus = ({ status }) => {
  let text;
  switch (status) {
    case 'PAYMENT_INITIATED':
      text = 'Waiting For verification';
      break;
    case 'PAYMENT_VERIFICATION_SUCESS':
      text = 'Payment Verified';
      break;
    case 'PAYMENT_VERIFICATION_FAILED':
      text = 'Payment Verification Failed';
      break;
    case 'REFUND_SUCCESS':
      text = 'Refunded';
      break;
    case 'REFUND_INITIATED':
      text = 'Refund InProgress';
      break;
    
    default:
      text = status;
  }

  return text;
};

export default PaymentStatus;
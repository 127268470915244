import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useCheckAuth from '../../commonuser/CheckAuth';
import NavBar from '../../NavBars/navBar';
import Loading from '../../NavBars/Loading';
import BookingApi from './BookingApi';
import BookingDetail from './components/BookingDetail';
import BottomBar from '../../NavBars/BottomBar';
import WaitingForVerificationBanner from '../../helpercomponent/WaitingForVerificationBanner';
function BookingDetailView() {

    const { bookingId } = useParams();
    const token  = useCheckAuth();
    const [booking, setBooking] = useState(null);
    const [loading, setLoading] = useState(true);
    

    useEffect( () => {
        BookingApi.JobDetail(token,bookingId)
        .then((data) => {
            setBooking(data);
             setLoading(false);
          });
    },[token,bookingId])

    useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        let sub_types = [
        'BOOKING_CANCELLED_BY_USER_FOR_HELPER',
        'BOOKING_CANCELLED_BY_USER_FOR_USER',
        'BOOKING_CANCELLED_BY_HELPER_FOR_HELPER',
        'BOOKING_CANCELLED_BY_HELPER_FOR_USER',
        'RESCHEDULE_REQUESTED_BY_HELPER',
        'RESCHEDULE_REQUESTED_BY_SEEKER',
        'RESCHEDULE_DECLINED_BY_HELPER',
        'RESCHEDULE_DECLINED_BY_SEEKER',
        'RESCHEDULE_APPROVED_BY_HELPER',
        'RESCHEDULE_APPROVED_BY_SEEKER',
        'ADDITIONAL_TIME_REQUESTED_BY_HELPER',
        'ADDITIONAL_TIME_CANCELLED_BY_USER',
        'ADDITIONAL_TIME_ACCEPTED_BY_USER',
        'MARK_BOOKING_AS_COMPLETE',
        'TASK_OTP'
    ];
        if (sub_types.includes(event.data.sub_type)){
          BookingApi.JobDetail(token,bookingId)
        .then((data) => {
            setBooking(data);
             setLoading(false);
          });
        }
      });
    }
    // eslint-disable-next-line
  }, []);

    return ( 
    <div>
        <NavBar/>
        <WaitingForVerificationBanner/>
            {loading ? (
        <Loading />
      ) : (
         <React.Fragment>
        {booking.task_data && <BookingDetail booking={booking} token={token}/>}
     </React.Fragment>
     )}
    <BottomBar/>

    </div>
     );
}

export default BookingDetailView;
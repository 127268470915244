import React, { useState } from 'react';
import { Tab, Tabs} from '@mui/material';
import TabPanel from '../../utils/TabPanel';
import JobTab from './components/JobTab';
import NavBar from '../../NavBars/navBar';
import BottomBar from '../../NavBars/BottomBar';
import IsMobile from '../../Styles/IsMobile';
function JobsTabView() {
  const isMobile = IsMobile();

     const [value, setValue] = useState(1);
    return (<div>
        <NavBar />
        
        <Tabs
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered={!isMobile}
        variant={isMobile ? 'scrollable' : 'standard'}
        // scrollButtons={isMobile ? 'auto' : 'on'}
        sx={{ width: '100%', marginTop: '10px' }}
        >
        <Tab label="Accepted" />
        <Tab label="Pending" />
        <Tab label="Closed" />

        </Tabs>
         <TabPanel value={value} index={0} >
          <JobTab status={"accepted"}/>
        </TabPanel>
        <TabPanel value={value} index={1} >
          <JobTab status={"pending"}/>
        </TabPanel>
        <TabPanel value={value} index={2} >
          <JobTab status={"closed"}/>
        </TabPanel>
        <BottomBar/>
        

    </div>  );
}

export default JobsTabView;
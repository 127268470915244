import React, { useState, useEffect } from 'react';
import { IconButton,ListItemText, Divider,Pagination, CardContent,Typography,Box, Card,  ListItemButton} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dateConvert from '../../utils/dateConvert';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ScrollToTop from '../../utils/ScrollToTop';
function EarningsTable ({tasks,setshowEarningsPage,setshowEarningsTable}) {

  
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;
    const pageCount = Math.ceil(tasks.data.length / rowsPerPage);
    useEffect(()=>{
      ScrollToTop();
    },[page])
    
  const handlePageChange = (event, value) => {
    
    setPage(value);
  };
  const handleGoBack = (event) => {
    event.preventDefault();
    setshowEarningsTable(false)
    setshowEarningsPage(true)
  }

    return ( 
        <div>
              <Typography align="center" variant="h5">Earnings</Typography>
      <Card
          sx={{ maxWidth: 800,margin: "auto",}} elevation={0}
          style={{backgroundColor: "#0dcaf000"}}
          >

        <IconButton onClick={(e)=>{handleGoBack(e)}}>
            <ArrowBackIcon/>
        </IconButton>
      

        { tasks.data && (rowsPerPage > 0
              ? tasks.data.slice((page - 1) * rowsPerPage, page * rowsPerPage)
              : tasks.data
            ).map(task => (
              <React.Fragment>
              {task.total_payment_data && (
               <CardContent>
              <React.Fragment>
              <ListItemButton disableGutters href={`/Booking/${task.external_id}`}>

                <Box display="flex" justifyContent="space-between" width="100%">
                  <ListItemText primary="Earning" secondary={dateConvert(task.created_at)} sx={{ textAlign: 'left' }} />
                  <ListItemText primaryTypographyProps={{ color: "success.main" }}
                  primary={` + ${task.total_payment_data.payout_amount.toLocaleString("en-US", {style:"currency", currency:"EUR"})} `} sx={{ textAlign: 'right' }}
                    />
                </Box>
                <ArrowForwardIosIcon fontSize="medium"sx={{ml:2}}/>
              </ListItemButton>
              
              <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black',p:0 }} />
              </React.Fragment>
              </CardContent>
            
              )}

              </React.Fragment>
            
          ))}

             <Pagination count={pageCount} page={page} onChange={handlePageChange} />



      </Card>
           
        </div>
     );
}

export default EarningsTable;
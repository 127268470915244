import PaymentApi from './PaymentApi';
import useCheckAuth from '../commonuser/CheckAuth';
import { STRIPE_PUBLIC_KEY } from '../../config';
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Loading from '../NavBars/Loading';
import CheckoutForm from "./CheckoutForm";
// import "../../App.css";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

function Checkout({type,Id,useCredits,number_of_connects,couponCode,taskId}) {
  console.log(taskId);
  const [loading, setLoading] = useState(true);
  const token  = useCheckAuth();
  const [clientSecret, setClientSecret] = useState("");
  const use_credits = useCredits;
  useEffect(() => {
    if (type === "onsbk") {
      Id && PaymentApi.CreateOnspotBookingPaymentSession({use_credits} ,Id,token)
      .then((resp => {
        setClientSecret(resp.clientSecret);
      setLoading(false);
      }
      ))
    }
    if (type === "dbk") {
      Id && PaymentApi.CreatePaymentSession({use_credits,couponCode} ,Id,token).then((resp => {
        setClientSecret(resp.clientSecret);
          setLoading(false);
      
    }))
    }
    if (type === "connect") {
      number_of_connects && PaymentApi.CreateConnectPaymentSession({number_of_connects,use_credits} ,token).then((resp => {
        setClientSecret(resp.clientSecret);
        setLoading(false);
      
    }))
    }
    
  }, [Id,number_of_connects,token,type,use_credits]);

  const appearance = {
    theme: 'flat',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (<div>
         {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm taskId={taskId}/>
        </Elements>
      )}
      </div>)}
    </React.Fragment>
  );
}

export default Checkout;

import React, { useEffect, useState } from 'react';
import { Button,TextField, Checkbox,Dialog, DialogActions, DialogContent, DialogTitle, Stepper, Step, StepLabel, Typography, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import Checkout from '../../../../payment/Payment';
import TaskAPi from '../../TaskApi';
import useCheckAuth from '../../../../commonuser/CheckAuth';
function PaymentStep({type,id,title,task_type,total_price_inc_vat,total_no_hours,open,helper_name,disable_offers,taskId}) {
    const credit = useSelector(x => x.user.credit);
    const [activeStep, setActiveStep] = useState(0);
    const [servicefee, setservicefee] = useState(0);
    const [totalCost, setTotalCost] = useState(total_price_inc_vat);
    const steps = ['Total Cost', 'Payment'];
    const [checked, setChecked] = useState(false);
    const [showCredits, setshowCredits] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [couponCodeError, setCouponCodeError] = useState("");
    const token= useCheckAuth()

    const handleCouponCodeChange = (e) => {
      TaskAPi.ValidateCoupon(token,{code:e.target.value})
      .then((resp)=>{
        setCouponCodeError('')
        let discountedAmount = totalCost * (resp.discount / 100);
        if (discountedAmount > resp.max_value) {
            discountedAmount = resp.max_value;
          }
        console.log(discountedAmount);
        setTotalCost((totalCost - discountedAmount).toFixed(2));
      })
      .catch((error) => {
        setCouponCodeError(error.response.data.message)
        setTotalCost(total_price_inc_vat)
        setChecked(false)
      })
    
      setCouponCode(e.target.value)
    };

    useEffect(()=>{
      if (credit>0 ){
      const  dis_price = (total_price_inc_vat - credit).toFixed(2)
      if (dis_price+5 >credit ) {
        setshowCredits(true)
      }
      else{
        setshowCredits(false)
      }
      }
      

    },[totalCost])

    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
          setTotalCost((totalCost - credit).toFixed(2));
        } else {
          setTotalCost(total_price_inc_vat);
        }
    };
    const handleClose = (event) => {
      event.preventDefault();
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {disable_offers ? 
          <Typography align='center' variant='subtitle1'>{helper_name} has accepted your booking</Typography> :(
            <Typography align='center' variant='subtitle1'>You have Accepted one of the offers recieved</Typography>
          )}
          
          <Typography align='center' variant='h6'>Pay To Confirm The Booking</Typography>
          </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === 0 ? (
              <div>
                <Grid container spacing={1.6}>
                  <Grid  item xs={4}>
                    <Typography  sx={{ fontSize:15  }}>Task Type: </Typography>
                  </Grid>
                   <Grid variant="outlined" item xs={8}>
                    {task_type==="IN_PERSON" ? (<Typography  align='right' sx={{ fontSize:15  }}> <strong>In Person Appoinment</strong></Typography>) :(<Typography  align='right' sx={{ fontSize:15 }}> <strong>Online Appoinment</strong></Typography>)}
                   </Grid>
                   <Grid  item xs={4}>
                    <Typography  sx={{ fontSize:15  }}>Total Hours: </Typography>
                  </Grid>
                   <Grid item xs={8}>
                     <Typography  align='right'  sx={{ fontSize:15  }}><strong>{total_no_hours}</strong> </Typography>
                   </Grid>
                   <Grid  item xs={4}>
                    <Typography  sx={{ fontSize:15  }}>Total Cost: </Typography>
                  </Grid>
                   <Grid item xs={8}>
                     { total_price_inc_vat && <Typography align='right' sx={{ fontSize:15  }}><strong>{total_price_inc_vat.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</strong> </Typography> }
                   </Grid>
                   <Grid  item xs={4}>
                    <Typography  sx={{ fontSize:15  }}>Service Fee: (Free) </Typography>
                  </Grid>
                   <Grid item xs={8}>
                     <Typography align='right' sx={{ fontSize:15  }}><strong>{servicefee.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</strong> </Typography> 
                   </Grid>
                    <Grid  item xs={4}>
                     
                      <Typography  sx={{ fontSize:15  }}> Coupon Code </Typography>
                      </Grid>
                   <Grid item xs={8} sx={{ textAlign: 'right' }}>
                   <TextField
                    label="Coupon Code"
                    sx={{align:'right',justifyContent:"right"}}
                    value={couponCode}
                    onChange={handleCouponCodeChange}
                    helperText={couponCodeError}
                    error={couponCodeError}
                    size="small"
                  />
                  </Grid>

                   
                   <Grid  item xs={4}>
                    {showCredits && <Typography sx={{ fontSize:15  }}>Use credits({credit})
                    </Typography> }
                  </Grid>
                   <Grid item xs={8}>
                    {showCredits && 
                    <Typography align='right'>
                    <Checkbox
                     
                      size='small'
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    /></Typography>}
                    
                   </Grid>
                    <Grid  item xs={4}> 
                    {totalCost < total_price_inc_vat  &&  <Typography  sx={{ fontSize:15  }} >Total Cost(Discounted):</Typography> } 
                    </Grid>
                     <Grid item xs={8}>
                {totalCost < total_price_inc_vat && <Typography  align='right'  sx={{ fontSize:15  }} > <strong>{totalCost}</strong></Typography> }
                </Grid>

                </Grid>
              </div>
            ) : (
              <div>
                <Checkout type={type} Id={id} useCredits={checked} couponCode={couponCode} taskId={taskId}/>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          {activeStep === steps.length - 1 ? null :
           <Button variant="contained" color="primary" onClick={activeStep === steps.length - 1 ? handleClose : handleNext}>
            Next
          </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default PaymentStep;
import React, { useEffect, useState } from 'react';
import { List,Card, ListItem, ListItemText, Typography, Divider, Avatar, CardContent, ListItemButton } from '@mui/material';
import OrgApi from './OrgApi';
import useCheckAuth from '../commonuser/CheckAuth';
import NavBar from './PopOverMenu';
import BottomBar from './BottomBar';
import AllowOrgAdmin from '../permissions/AllowOrgAdmin';
const OrganizationAdmin = () => {
  const token = useCheckAuth()
  const [AllUsers, setGetAllUsers] = useState(null);

  useEffect(()=>{
    OrgApi.GetUsers(token)
    .then((resp)=>(setGetAllUsers(resp)))
    
  },
  [])

  return (
    <div>
      <AllowOrgAdmin/>
        <NavBar/>
         <Card style={{backgroundColor: "#0dcaf000"}}
              sx={{ maxWidth: 800,margin: "auto",}}
              >
            <CardContent>
      <Typography variant="h5" gutterBottom>
        Users
      </Typography>
      <List>
        {AllUsers && AllUsers.map(user => (
          <React.Fragment key={user.user}>
            <Divider component="li" />
            <ListItem>

              {
                user.helper_type === "ORG_USER" && (
              <ListItemButton 
              href={`/org/stats/${user.user}`}
              >
              <Avatar>{`${user.first_name[0]}${user.last_name[0]}`}</Avatar>
              <ListItemText
              sx={{p:2}}
                primary={`${user.first_name} ${user.last_name}`}
                secondary={`Email: ${user.email} | Phone: ${user.phone_number}`}
              />
              <Typography>Role: User</Typography>
              </ListItemButton>

                )
              }
              {
                user.helper_type === "ORG_ADMIN" && (
                <React.Fragment>
                  <ListItem sx={{p:2}}>

              <Avatar>{`${user.first_name[0]}${user.last_name[0]}`}</Avatar>
              <ListItemText
              sx={{p:2}}
                primary={`${user.first_name} ${user.last_name}`}
                secondary={`Email: ${user.email} | Phone: ${user.phone_number}`}
              />
              <Typography>Role: Admin</Typography>
              </ListItem>
              </React.Fragment>
                )
              }  
            </ListItem>
          </React.Fragment>
        ))}
      </List>
      </CardContent>
      </Card>
      <BottomBar/>
    </div>
  );
};

export default OrganizationAdmin;

import React from 'react';
import { Box, Typography, Link, useTheme } from '@mui/material';

const SpeechBubble = ({ message, attachments, position }) => {
  const theme = useTheme();
  const isLeftPosition = position === 'left';

  return (
    <Box
      sx={{
        backgroundColor: '#e0e0e0',
        borderRadius: '8px',
        padding: '10px',
        maxWidth: '100%',
        width: 'fit-content',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          [isLeftPosition ? 'right' : 'left']: '100%',
          top: '50%',
          borderTop: '10px solid transparent',
          borderBottom: '10px solid transparent',
          borderRight: isLeftPosition ? `10px solid ${theme.palette.background.paper}` : 'none',
          borderLeft: isLeftPosition ? 'none' : `10px solid ${theme.palette.background.paper}`,
          transform: 'translateY(-50%)',
        },
      }}
    >
      <Typography variant="body1">{message}</Typography>
      {attachments && attachments.length > 0 && (
        <div>
          <Typography variant="caption">Attachments:</Typography>
          <ul style={{ margin: 0, paddingInlineStart: '1rem' }}>
            {attachments.map((attachment, index) => (
              <li key={index}>
                <Link href={attachment.url} target="_blank" rel="noopener noreferrer">
                  {attachment.name.length > 50
                    ? `${attachment.name.slice(0, 50)}...`
                    : attachment.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Box>
  );
};

export default SpeechBubble;

import React, { useState } from 'react';
import { Tab, Tabs} from '@mui/material';
import TabPanel from '../../utils/TabPanel';
import NavBar from '../../NavBars/navBar';
import BottomBar from '../../NavBars/BottomBar';
import IsMobile from '../../Styles/IsMobile';
import BookingTab from './components/BookingTabComponents/BookingTab';
import WaitingForVerificationBanner from '../../helpercomponent/WaitingForVerificationBanner';
function BookingTabView() {
  const isMobile = IsMobile();

     const [value, setValue] = useState(0);
    return (<div>
        <NavBar />
        <WaitingForVerificationBanner/>
        <Tabs
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered={!isMobile}
        variant={isMobile ? 'scrollable' : 'standard'}
        // scrollButtons={isMobile ? 'auto' : 'on'}
        sx={{ width: '100%', marginTop: '10px' }}
        >
        <Tab label="Upcomming" />
        <Tab label="Inprogress" />
        <Tab label="Completed" />
        <Tab label="Cancelled" />

        </Tabs>
        <TabPanel value={value} index={0} >
          <BookingTab status={"upcomming"}/>
        </TabPanel>
         <TabPanel value={value} index={1} >
          <BookingTab status={"inprogress"}/>
        </TabPanel>
        <TabPanel value={value} index={2} >
          <BookingTab status={"completed"}/>
        </TabPanel>
        <TabPanel value={value} index={3} >
          <BookingTab status={"cancelled"}/>
        </TabPanel>
        
        <BottomBar/>
        

    </div>  );
}

export default BookingTabView;
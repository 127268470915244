import React from 'react';
import {  Dialog, DialogTitle , DialogContent, Button, Typography } from '@mui/material';
import BookingApi from '../../BookingApi';
import RefreshPage from '../../../../utils/refreshPage';
const CompleteBooking = ({bookingId,token,showBookingEnd, setshowBookingEnd}) => {
  // const [isOpen, setIsOpen] = useState(false);

  // const handleToggle = () => {
  //   setIsOpen(!isOpen);
  // };
  const handleClose = () => {
    setshowBookingEnd(false);
};
  const CompleteBooking = (e) =>{

      BookingApi.EndBooking(token,bookingId)
      .then(resp=>{
        RefreshPage()
      })

    }

  return (
    <React.Fragment>
        <Dialog open={showBookingEnd} variant="contained" onClose={handleClose}>
         <DialogTitle>Complete Booking</DialogTitle>
         
          <DialogContent>
            <Typography sx={{p:1}}>Please Confirm to complete the booking</Typography>
            <Button 
            fullWidth
            sx={{p:1}}
            onClick={() => {
                CompleteBooking();
                handleClose();
            }} variant="contained"  color='success'>
                Confirm
          </Button>
          

          </DialogContent>
          </Dialog>

    </React.Fragment>
  );
};

export default CompleteBooking;

import React, { useState } from 'react';
import {Box, Accordion,Button, AccordionSummary, AccordionDetails, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TaskAPi from '../../TaskApi';
import PaymentStep from './PaymentStep';
import moment from "moment";

function OnSpotBookings({onsbks,token,taskId}) {

  const [showPayment, setshowPayment] = useState(false);

  const onspotBookingCancel = (onspot_booking_id) => {
    TaskAPi.OnspotBookingCancel(token, onspot_booking_id)
    .then(resp => {
      window.location.reload(false);
    })
  }
  const dateConvert = (ds) =>{
        return  moment(ds).format('MMMM Do YYYY, h:mm:ss a');
    }

    return ( <div>     
        <Box sx={{flexGrow: 1}} p={2} >
          {onsbks.map((onsbk, index) => (
            <Accordion style={{backgroundColor: "#0dcaf000"}}  defaultExpanded={true} key={index}>
              <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
                <Typography><strong>additional time for {onsbk.onsbk_quotes_data.total_no_hours} Hours</strong> </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box  sx={{display:"flex",flexGrow:1,flexDirection:'column'}}>
                <Typography sx={{p:1}}><strong>title:</strong> {onsbk.title}</Typography>
                <Typography sx={{p:1}}><strong>Description:</strong> {onsbk.description}</Typography>
                <Typography sx={{p:1}} ><strong>Start Time:</strong> {dateConvert(onsbk.start_time)}</Typography>
                <Typography sx={{p:1}}><strong>End Time:</strong> {dateConvert(onsbk.end_time)}</Typography>
                <Typography sx={{p:1}} ><strong>Total Price inc VAT:</strong> {onsbk.onsbk_quotes_data.total_price_inc_vat}</Typography>
                <Typography sx={{p:1}} ><strong>Total Hours:</strong> {onsbk.onsbk_quotes_data.total_no_hours}</Typography>
                {
                  onsbk.status === "USER_ACCEPTED" && (
                     <Typography sx={{p:1}}><strong>Status:</strong> Accepted</Typography>
                  )
                } 
                
                </Box>
                
                {
                  onsbk.status === "HELPER_INITATED" && (
                  <React.Fragment>
                  <PaymentStep 
                  id={onsbk.id} 
                  type={"onsbk"}
                  title={onsbk.title}
                  task_type={onsbk.description} 
                  total_price_inc_vat={onsbk.onsbk_quotes_data.total_price_inc_vat} 
                  total_no_hours={onsbk.onsbk_quotes_data.total_no_hours}
                  open={showPayment} 
                  taskId={taskId}
                  />
                  <Box sx={{display:'flex'}}>
                  <Button sx={{fontSize:10,display:'flex',mr:2,flexGrow:1}}variant="contained" color="error" onClick={() => onspotBookingCancel(onsbk.id)}>
                    Cancel 
                  </Button>
                  <Button sx={{fontSize:10,display:'flex',ml:2,flexGrow:1}} variant="contained" color="success" onClick={() => setshowPayment(true)}>
                    Accept
                  </Button>
                  </Box>
                  </React.Fragment>
                  )
                }
                {
                  onsbk.status === "CANCELLED" && (
                  <Typography><strong>Status:</strong> Cancelled</Typography>
                  )
                }
              </AccordionDetails>
            </Accordion>
          ))}
          </Box>
    </div> );
}

export default OnSpotBookings;
import React,{useEffect} from 'react';
import {  
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import { useLocation } from 'react-router-dom';
import TaskIcon from '@mui/icons-material/Task';
import IsMobile from '../Styles/IsMobile';
import {useCookies} from 'react-cookie';
import HomeIcon from '@mui/icons-material/Home';

import EnableNotification from '../notify/EnableNotification';
import UnreadMessages from '../notify/UnreadMessages';
import NotifyAll from '../notify/NotifyAll';
import NotifyAllMob from '../notify/NotifyAllMob';
import CreateBadge from '../notify/CreateBadge';
const Footer = () => {
  const isMobile = IsMobile();
  const location = useLocation();
  const [userType] = useCookies(['UserType']);


  const userMobileList = () => (
    
   
      <React.Fragment>
      
        <IconButton 
          sx={{display:'flex', marginBottom:1,
          flexGrow:1, flexDirection:'column' ,  color: location.pathname === '/user/tasks' ? 'grey' : 'white' }} 
          href='/user/tasks' 
          >
            <TaskIcon fontSize="small"  />
            <Typography fontSize={10} >Tasks</Typography>
          </IconButton>
         
          <IconButton
          sx={{display:'flex', flexGrow:1, marginBottom:1, flexDirection:'column' ,  color: location.pathname === "/profile/user" ? 'grey' : 'white' }} 
          href="/profile/user"
          >
            <PersonIcon fontSize="small" />
            <Typography fontSize={10} >Profile</Typography>
          </IconButton>
           <IconButton
          sx={{display:'flex', flexGrow:1, marginBottom:1, flexDirection:'column' ,   color: location.pathname === "/user/task/create" ? 'grey' : 'white' }} 
          href="/user/task/create" 
            >
            <AddIcon fontSize="large"
            //  sx={{fontSize: 30 ,}} 
            />
          </IconButton>
         
          </React.Fragment>
  );
  const helperMobileList = () => (
     <React.Fragment>
      
        <IconButton 
          sx={{display:'flex', flexGrow:1, marginBottom:1,  flexDirection:'column' ,
           color: location.pathname === '/helper/jobs' ? 'grey' : 'white' }} 
          href='/helper/jobs'
          >
            <TaskIcon  fontSize="small" />
            <Typography fontSize={10} >Jobs</Typography>
          </IconButton>
          <IconButton
          
          sx={{display:'flex', flexGrow:1,  marginBottom:1,   flexDirection:'column' , color: location.pathname === '/booking' ? 'grey' : 'white' }} 
          href='/booking'
            >
            <EventIcon   fontSize="small" />
             <Typography fontSize={10} >Bookings</Typography>
          </IconButton>
          <IconButton 
          sx={{display:'flex', flexGrow:1, marginBottom:1,  flexDirection:'column' ,
           color: location.pathname === '/helper/home' ? 'grey' : 'white' }} 
          href='/helper/home'
          >
            <HomeIcon  fontSize="small" />
            <Typography fontSize={10} >Home</Typography>
          </IconButton>
          {/* <IconButton
          sx={{display:'flex', flexGrow:1, marginBottom:1,   flexDirection:'column' , color: location.pathname === "/profile/helper"? 'grey' : 'white' }} 
          href="/profile/helper"
          >
            <PersonIcon   fontSize="small" />
             <Typography fontSize={10} >Profile</Typography>
          </IconButton> */}
          </React.Fragment>
  );
  return (
    <React.Fragment>
      {isMobile && (
        <React.Fragment>
          <div style={{ marginTop:'35' ,padding:40}}></div>
        <AppBar position="fixed"  sx={{ top: 'auto', bottom: 0 , background :"#131313", padding:1}}>
          
           <Toolbar sx={{display:'flex',flexGrow:1}}>
          {userType.UserType==="user" && userMobileList()}
          {userType.UserType==="helper" && helperMobileList()}
           <NotifyAll 
            ic_sx={{display:'flex', flexGrow:1,  marginBottom:1,   flexDirection:'column' ,  color: location.pathname === "/notify" ? 'grey' : 'white' }} 
           />
           <UnreadMessages
           ic_sx={{display:'flex', flexGrow:1,  marginBottom:1,   flexDirection:'column' ,  color: location.pathname === "/messages" ? 'grey' : 'white' }} 
           />
          </Toolbar>         
        </AppBar>
        </React.Fragment>

      )}
      
        </React.Fragment>

  );
};

const BottomBar = ({first_name,user_type}) => {
  return (
    <React.Fragment>
      <EnableNotification/>
      <CreateBadge/>
    <Footer first_name={first_name} user_type={user_type} />
    </React.Fragment>
  );
};

export default React.memo(BottomBar)

import React  from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {  IconButton, Divider,CardContent,Typography,Box, Card} from '@mui/material';
import dateConvert from '../../utils/dateConvert';

function Withdrawls({withdrawls,setshowEarningsPage,setshowWithdrawls}) {
    const handleGoBack = (event) => {
    event.preventDefault();
    setshowWithdrawls(false)
    setshowEarningsPage(true)
  }
    
    return (
    <div>
    
    <Typography align="center" variant="h5">Withdrawls</Typography>

    <Card
    style={{backgroundColor: "#0dcaf000"}}
    sx={{ maxWidth: 800,margin: "auto",}} elevation={0}
    >
        <IconButton onClick={(e)=>{handleGoBack(e)}}>
        <ArrowBackIcon/>
    </IconButton>
    {withdrawls.map(withdrawl=>(
        <CardContent>
        <React.Fragment>
        <Box display="flex" justifyContent="space-between">
        <Typography  variant="body1">Withdrawl Successful</Typography>
        <Typography color="error"> - {(withdrawl.payout_amount/ 100).toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
        </Box>
        <Typography>{dateConvert(withdrawl.created_at)}</Typography>
        <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black',p:2 }} />
        </React.Fragment>
        </CardContent>

        
    ))}
    </Card>

    </div>  );
}

export default Withdrawls;
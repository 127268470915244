import React from 'react';
import {  
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import HomeIcon from '@mui/icons-material/Home';
import theme from '../Styles/theme';
import NotifyAll from '../notify/NotifyAll';
import UnreadMessages from '../notify/UnreadMessages';
import { useLocation } from 'react-router-dom';
import TaskIcon from '@mui/icons-material/Task';
import IsMobile from '../Styles/IsMobile';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import Logout from '../NavBars/Logout';
import ContactsIcon from '@mui/icons-material/Contacts';

const Header = ({first_name,user_type}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  
  const isMobile = IsMobile();
  const location = useLocation();
  
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

 
    const OrgAdmin = () => (
    <div
        style={{ width: 250}}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button key="Home">
          <ListItemButton  href="/org/home">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
           <ListItemText primary="Home" />
    {location.pathname === '/org/home'  &&  <ArrowForwardIosIcon/> }
           </ListItemButton>
        </ListItem>
        <ListItem button key="Profile">
          <ListItemButton  href="/org/admin-profile">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
           <ListItemText primary="Profile" />
    {location.pathname === '/org/admin-profile'  &&  <ArrowForwardIosIcon/> }
           </ListItemButton>
        </ListItem>
        <ListItem button key="users">
          <ListItemButton  href='/org/users'>
          <ListItemIcon>
            <ContactsIcon />
          </ListItemIcon>
           <ListItemText primary="Users" />
           </ListItemButton>
    {location.pathname === '/org/users'  &&  <ArrowForwardIosIcon/> }
        </ListItem>
      </List>
    </div>
  );
  return (
    // <Box sx={{ flexGrow: 1 }}>
    <React.Fragment>
      <AppBar position="fixed" sx={{ background: theme.palette.primary.gradient } }>
         
        <Toolbar >
          
          {!isMobile && (
            <React.Fragment>
            <IconButton
            edge="start"
            sx={{ marginRight: 2}}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            {OrgAdmin()}
          </Drawer>
          </React.Fragment>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <PersonPinCircleIcon />
            LocaleLingo+
          </Typography>
          <React.Fragment>
          <NotifyAll/>
          </React.Fragment>    
          <Logout first_name={first_name}/>
        </Toolbar>
        
      </AppBar>
      <div style={{ marginTop:'35' ,padding:40}}></div>   
      <Typography align='center' sx={{pb:2}}>Admin Console</Typography>   
    </React.Fragment>

      // </Box>
  );
};

const NavBar = ({first_name,user_type}) => {
  return (
    <Header first_name={first_name} user_type={user_type} />
  );
};

export default NavBar;

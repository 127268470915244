import React, {  useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
function AllowOrgAdmin() {
    const navigate = useNavigate();
    const helper_type = useSelector(x => x.user.helper_type);
    useEffect(()=>{
    if (helper_type==="ORG_ADMIN") {
        console.log();
    } else (navigate('/'))
    },[navigate,helper_type])
    return null;
}

export default AllowOrgAdmin;
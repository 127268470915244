import React, { useEffect } from 'react';
import { Button, CircularProgress, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const PaymentRedirectPage = () => {

    let params = new URLSearchParams(document.location.search);
    let taskId = params.get("taskId");
    let navigate = useNavigate()

    useEffect(()=>{
        navigate(`/user/task/${taskId}`);
    },[])

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h5" gutterBottom>
        Processing payment...
      </Typography>
      <CircularProgress style={{ margin: '50px auto' }} />
    </Container>
  );
};

export default PaymentRedirectPage;

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import JobsApi from '../JobsAPi';
import React, { useState } from 'react';

function CancelQuote({ quote_id,token }) {
  const [open, setOpen] = useState(false);
  const [QuoteSetError, setQuoteSetError] = useState({})

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const CancelQuotes = (quote_id) => {
    JobsApi.QuoteCancel(token, quote_id)
      .then((resp) => {
        console.log(resp);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        const { data } = error.response;
        const errorFields = Object.keys(data);
        const newErrors = {};
        errorFields.forEach(field => {
          const [errorMessage] = data[field];
          newErrors[field] = errorMessage;
        });
        setQuoteSetError(newErrors);
      })
  }

  return (
    <div>
      <Button sx={{background:"error", mx:2,marginTop:2}}  onClick={handleOpen} variant="contained" color="primary">
        Cancel
      </Button>
      <Dialog open={open} variant="contained" onClose={handleClose}>
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>Are you sure you want to cancel this quote?</DialogContent>
        <DialogActions>
          <Button  onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button  onClick={() => {
            CancelQuotes(quote_id);
            handleClose();
          }} variant="contained" color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {QuoteSetError.message && (<div className="alert alert-danger">{QuoteSetError.message}</div>)}
    </div>
  );
}

export default CancelQuote;

import {
  LoginSocialFacebook,
} from 'reactjs-social-login'

import GoogleIcon from '@mui/icons-material/Google';
import { useGoogleLogin } from '@react-oauth/google';
import UserApiService from './UserApi';
import { Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FB_APP_ID } from '../../config';
function SocialLogin({setToken,cookisetup}) {
    const Googlogin = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri:"" ,
    onSuccess: tokenResponse => {
    console.log(tokenResponse);
    const code=tokenResponse.code
    UserApiService.GoogleLoginUser({code})
    .then(resp => {
              setToken(resp.key);
              cookisetup('authtoken', resp.key)
            })
    }
        });

    const handleFacebookLogin = (credentialResponse)=>{
    const access_token=credentialResponse.accessToken
    UserApiService.FacebookLoginUser({access_token})
    .then(resp => {
              setToken(resp.key);
              cookisetup('authtoken', resp.key)
            })

  } 
    return ( <div>

    <LoginSocialFacebook
        isOnlyGetToken
        appId={ FB_APP_ID }
        onResolve={({ provider, data }) => {
          handleFacebookLogin(data)
        }}
        onReject={(err) => {
          console.log(err)
        }}
          >
        <Button startIcon={<FacebookIcon/>} fullWidth style={{ background: "#3b5998",color:"white",margin:"auto" ,paddingTop:2}} >Login via Facebook</Button>
      </LoginSocialFacebook>
      <div style={{margin:10}}></div>
       <Button fullWidth startIcon={<GoogleIcon />} variant='contained' sx={{background:"white",color:'black'}} onClick={() => Googlogin()}>
        Sign in with Google 
      </Button>

    </div> );
    }

export default SocialLogin;



import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import PasswordIcon   from '@mui/icons-material/Password';
import { Button, TextField, Typography, Container } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import UserApiService from './UserApi';
import {
  Box,CircularProgress
} from '@mui/material';
import GoBack from './GoBack';
function PasswordResetForm () {
    const { uid } = useParams();
    const { token } = useParams();
    const [new_password1, setNewPassword1] = useState('')
    const [new_password2, setNewPassword2] = useState('')
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    let navigate = useNavigate()

    const passwordReset = () => {
        UserApiService.PasswordResetForm({uid,token,new_password1,new_password2})
        .then((resp) => {
          setIsLoading(false);
          navigate('/login')

        })
        .catch((error) => {
                const { data } = error.response;
                const errorFields = Object.keys(data);
                console.log(errorFields);
                const newErrors = {};
                errorFields.forEach(field => {
                const [errorMessage] = data[field];
                newErrors[field] = errorMessage;
                });
                setIsLoading(false);
                setErrors(newErrors);
              })
      }

      return (
        <Container maxWidth="xs">
           <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <GoBack/>
        <Typography variant="h5" sx={{ mb: 2 }} > Please Enter New Password</Typography>
      <TextField
        type="password"
        label="New Password"
        variant="outlined"
        value={new_password1}
        onChange={e => setNewPassword1(e.target.value)}
        sx={{ mb: 2 }}
        error={Boolean(errors.password1)}
        helperText={errors.password1}
        InputProps={{
        startAdornment: (
    <InputAdornment position="start">
        <PasswordIcon />
    </InputAdornment>
          ),
        }}
            />
            <TextField
        type="password"
        label="New Password"
        variant="outlined"
        value={new_password2}
        onChange={e => setNewPassword2(e.target.value)}
        sx={{ mb: 2 }}
        error={Boolean(errors.password2)}
        helperText={errors.password2}
        InputProps={{
        startAdornment: (
            <InputAdornment position="start">
                <PasswordIcon />
            </InputAdornment>
          ),
        }}
        />
        <Button
         onClick={passwordReset} 
          variant="contained"
           >
            {isLoading ? <CircularProgress size={24} /> : 'Reset'}
        </Button>
       </Box>
        </Container>        
      );
}

export default PasswordResetForm;
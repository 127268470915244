import { BACKEND_URL,authheaders } from "../../../config"
import axios from 'axios';


export default class JobsApi {

    static GetNotifications(token,body) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/marketplace/job/`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data
    }

    static setQuote(token ,task_id,quote) {
      const options = {
        method: 'post',
        url: `${BACKEND_URL}/marketplace/quotes/${task_id}/create`,
        headers: authheaders(token),
        data :JSON.stringify(quote)
      }
     return axios(options).then(resp => resp.data)
    }

    static jobInteractions(token ,job_id,action) {
      const options = {
        method: 'post',
        url: `${BACKEND_URL}/marketplace/job/${job_id}/interactions`,
        headers: authheaders(token),
        data :JSON.stringify({action:action})
      }
     return axios(options).then(resp => resp.data)
    }

    static JobAccept(token ,task_id) {
      const options = {
        method: 'post',
        url: `${BACKEND_URL}/marketplace/job/${task_id}/accept`,
        headers: authheaders(token),
        data :JSON.stringify({price_hr:0})
      }
     return axios(options).then(resp => resp.data)
    }

    static QuoteCancel(token ,quote_id) {
      const options = {
        method: 'post',
        url: `${BACKEND_URL}/marketplace/quotes/${quote_id}/cancel/`,
        headers:authheaders(token),
        data :JSON.stringify({})
      }
     return axios(options).then(resp => resp.data)
    }

    static JobDetail(token ,job_id) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/marketplace/job/${job_id}`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data  
      }
    static JobList(token ,status,page,per_page) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/marketplace/job/list?page=${page}&per_page=${per_page}&status=${status}`,
        headers:authheaders(token) ,
      }
      const data = axios(options).then(resp => resp.data)
      return  data  
      }

}
import React from 'react';
import moment from "moment";
import { IconButton,Button ,CardContent,Card,Typography, Box,Divider } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TaskAPi from '../../TaskApi';
function ReScheduleBookings({task,token,setShowTaskDetail,setshowRescheduleTaskBookings}) {
    const dateConvert = (ds) =>{
        return  moment(ds).format('MMMM Do YYYY, h:mm:ss A');
    }

    const RescheduleCancel = (rschbk_id) => {
    const action = "DECLINED"
    TaskAPi.RescheduleAction(token,rschbk_id,{action})
    .then(resp => console.log(resp))
     window.location.reload(false);
  }

    const AcceptCancel = (rschbk_id) => {
    const action = "ACCEPTED"
    TaskAPi.RescheduleAction(token,rschbk_id,{action})
    .then(resp => console.log(resp))
     window.location.reload(false);
  }
  const handleGoBack = (event) => {
    event.preventDefault();
    setShowTaskDetail(true)
    setshowRescheduleTaskBookings(false)
  }
    return ( <Box   p={2}>
        <IconButton sx={{p:3}} onClick={handleGoBack}>
          <ArrowBackIcon/>
        </IconButton>

         <Card
         style={{backgroundColor: "#0dcaf000"}}
        elevation={0}
          >
            <Typography  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}variant="h4">Booking Reschedules</Typography>
            <Divider sx={{ bgcolor: "secondary.light" }} component="p" />
        <CardContent  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}>
            {
                task.reschedule_data.length === 0 && <div>No Reschedules Found</div>
            }
        </CardContent>
            {
                task.reschedule_data.map((reschedule)=>(
    <React.Fragment>
        <CardContent  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}>
        <Typography>Description: {reschedule.description}</Typography>
        <Typography  variant="subtitle1">
            <strong>Reschedule Start Time: </strong> {dateConvert(reschedule.start_time)}
        </Typography>
        <Typography variant="subtitle1">
            <strong>Reschedule End Time:  </strong> {dateConvert(reschedule.end_time)}
        </Typography>
        {reschedule.status === "RESCHEDULE_REQUESTED_BY_SEEKER" &&
        <Typography>Your Reschedule Request Has Been Sent</Typography>}
        {reschedule.status === "RESCHEDULE_DECLINED_BY_HELPER" &&
        <Typography>Your Reschedule Request Has Been Declined</Typography>}
        {reschedule.status === "RESCHEDULE_DECLINED_BY_SEEKER" &&
        <Typography>Reschedule Request Has Been Declined by You</Typography>}
        {reschedule.status === "RESCHEDULE_REQUESTED_BY_HELPER" &&
    <React.Fragment>
        <Typography>Rescheduling Task Has been requested</Typography>
        <Box sx={{display: 'flex'}}>
        <Button variant="contained" sx={{background:"success" , mx:2,marginTop:2}} onClick={() => AcceptCancel(reschedule.id)}>
        Accept Reschedule Task
        </Button>
        <Button variant="contained" sx={{background:"error", mx:2,marginTop:2}} onClick={() => RescheduleCancel(reschedule.id)}>
        Decilne Reschedule Task
        </Button>
        </Box>
    </React.Fragment>
        
        }
        </CardContent>
        <Divider sx={{ bgcolor: "secondary.light" }} component="p" /> 
        </React.Fragment>
                ))
            }

         



          </Card>




    </Box> );
}

export default ReScheduleBookings;
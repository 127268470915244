import React, { useState ,useEffect} from 'react';
import {Popover} from '@mui/material';
import {Badge,List,ListItemAvatar,ListItem,ListItemText,Typography,Divider} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import useCheckAuth from '../commonuser/CheckAuth';
import NotifyApiService from './NotifyApi';
import moment from 'moment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { setUreadMessages } from '../reducers/NotifyActions';
import { useDispatch } from 'react-redux';
import IsMobile from '../Styles/IsMobile';
import { useNavigate } from 'react-router-dom';
function UnreadMessages({ic_sx} ) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [messages, setMessages] = useState([]);
  const token = useCheckAuth();
  const dispatch = useDispatch(); 

  let navigate = useNavigate()
  const isMobile = IsMobile()



  const updateunreadmessages= () =>{
    NotifyApiService.unReadMessage(token).then((resp) => {
      setMessages(resp)
      dispatch(setUreadMessages(resp))
    })
  }
  function truncateMessage(message, maxLength) {
  if (message.length <= maxLength) {
    return message;
  }

  return message.substring(0, maxLength) + '...';
}

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data.type === 'NEW_MESSAGE') {
          updateunreadmessages();
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateunreadmessages();
    
  }, 50000);
  window.addEventListener('beforeunload', () => {
      clearInterval(intervalId);
    });
  return () => {
    window.removeEventListener("beforeunload", () => clearInterval(intervalId));
    clearInterval(intervalId);
  };
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
   updateunreadmessages();
   // eslint-disable-next-line
  }, []); 

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      

      {isMobile ? (
        <IconButton
          sx={ic_sx}
          href="/messages"
          >
            <Badge badgeContent={messages.length} color="secondary"></Badge>
            <EmailIcon fontSize="small"  />
            <Typography fontSize={10} >Messages</Typography>
          </IconButton>
      ):(
        <IconButton color="inherit" onClick={handlePopoverOpen}>
        <Badge badgeContent={messages.length} color="secondary">
          <EmailIcon />
        </Badge>
      </IconButton>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography sx={{padding:1, marginRight:"10%"}}variant="h5">Unread Messages!</Typography>
        { messages.length >0 ? (messages.map((msg,index) => (
        <List key={index} >
           
           <Divider variant="middle" color="primary" orientation="horizontal" ></Divider>
        <ListItem
          button
          
          component="a"
          href={`/message/${msg.external_id}`}

        //   href = `/helper/job/${notificationData.associated_id}`

        >
          <ListItemAvatar>
            <EmailIcon />
          </ListItemAvatar>
          <ListItemText
            alignItems="flex-start"
            primary={`${msg.sender_name} - ${ moment(msg.timestamp).fromNow()}`}
            secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {truncateMessage( msg.text, 20)}
              </Typography>
            </React.Fragment>
          }

        />
        </ListItem>
        </List>
        ))
      
      ):(
        <List >
         <ListItemText sx ={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
          <Divider variant="middle" color="primary" orientation="horizontal" > <CheckCircleOutlineIcon sx ={{ fontSize:50  , color:"green" }}/></Divider>
                <Typography sx={{textAlign: "center","overflowY": 'auto', "minWidth": '300px', "maxWidth": '500px',}} variant="h5">All caught up!</Typography>
          <Typography sx={{textAlign: "center", }}variant="body1">
            You have no new messages
          </Typography>
        </ListItemText>
        </List>)}
      </Popover>
    </div>
  );
}

export default UnreadMessages;


import { createTheme } from '@mui/material/styles';
const isLightMode = true

const theme = createTheme({
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
    typography: {
    allVariants: {
      fontFamily: "-apple-system,BlinkMacSystemFont,Segoe UI,Noto Sans,Helvetica,Arial,sans-serif,Apple Color Emoji ,Segoe UI Emoji"
      
    },
  },
  components: {
    // MuiCssBaseline: {
    //   styleOverrides: {
    //     body: {
    //       // backgroundImage: 'linear-gradient(to right top, #008080, #077979, #0c7373, #106c6c, #136666, #156161, #175c5c, #185757, #1a5353, #1b4e4e, #1c4a4a, #1d4646)',
    //       backgroundRepeat: 'no-repeat',
    //       backgroundSize: 'cover',
    //       // Additional background properties can be added here
    //     },
    //   },
    // },
    MuiButton: {
        styleOverrides:{
      root: {
        '&:hover': {
          // backgroundColor: '#00402E', // change to your desired color
          color: 'black',
        },
      }
    }},
    MuiIconButton: {
        styleOverrides:{
      root: {
         '&:hover': {
          color: '#00402E', // change to your desired color
        },
      }
    }},
  },
  palette: {
    mode:  isLightMode ? 'light' : 'dark',
    background: {
      default:  isLightMode ? '#F8F8FF' : '#324B4B',
      // paper: isLightMode ? '#0dcaf000' : '#324B4B',
    },
    // text: {
    //   primary: "#fff",
    //   secondary:"#fff"
    // },
    primary: {
      gradient: 'linear-gradient(to right top, #008080, #078282, #0f8484, #148686, #198888, #1c8b8b, #1e8e8e, #219191, #239595, #259999, #279e9e, #29a2a2)',
      main: isLightMode ? '#008080' : '#008080',
      light: '#008080',
      dark: '##055d57',
      // light: "#e3f2fd",
    },
    info :{
      main: '#1E2535',
      light: "#1E2535",

    },
    secondary: {
      main: '#1E2535',
    },
    error :{
      main: '#D32F2F',
      // dark: "#fff",
      light: "#1E2535",

    },
  },

  
});

export default theme;

import React, { useEffect, useState } from 'react';
import { Box,FormControl,InputLabel, Button, TextField, Typography, FormControlLabel,Checkbox } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import PasswordIcon   from '@mui/icons-material/Password';
import DialpadIcon from '@mui/icons-material/Dialpad';
import LoginIcon from '@mui/icons-material/Login';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import TermsAndConditionsPopup from '../commonuser/TermsAndConditionsPopup';
import UserApiService from '../commonuser/UserApi';
import { useNavigate } from "react-router-dom";
import TaskAPi from '../marketplacev2/Tasks/TaskApi';
import DirectionsIcon from '@mui/icons-material/Directions';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ErrorToast from '../utils/ErrorToast';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import 'react-phone-number-input/style.css'
import { Listlanguages } from '../../config';


const HelperRegister = () => {

    let navigate = useNavigate();
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [phone_number, setPhone_number] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [pincode, setPincode] = useState('')
    const [refferal_code, setRefferal_code] = useState('')
    const [errors, setErrors] = useState({});
    const [has_accepted_terms, setAccepted] = useState(false);
    const [showError, setShowError] = useState(false);
    const [Languages, setLanguages] = React.useState(JSON.stringify([]));
    const [country, setCountry] = useState("DE")
    const [CallingCode, setCallingCode] = useState()

    const handleChange = (event) => {

    const selectedValues = event.target.value;
    const selectedJSON = JSON.stringify(selectedValues);
    setLanguages(selectedJSON);
    };
    const isMinSelectionMet = JSON.parse(Languages).length >= 2;

    useEffect(()=>{
      if (JSON.parse(Languages).length >= 2) {
      }
    }
    ,[Languages])

    const handleAccept = () => {
        setAccepted(true);
      };


  const handleSubmit = (event) => {
    event.preventDefault();
        const languages_selected=Languages
        const ph = CallingCode + phone_number
        UserApiService.HelperRegister({first_name,last_name,email,phone_number:ph,address,city,pincode, password1,password2,refferal_code,has_accepted_terms,languages_selected})
        .then((resp) =>  {
            navigate('/login');
        })
         .catch((error) => {
            const { data } = error.response;
            const errorFields = Object.keys(data);
            const newErrors = {};
            errorFields.forEach(field => {
            const [errorMessage] = data[field];
            newErrors[field] = errorMessage;
            });
            setErrors(newErrors);
            setShowError(true)
        })
  };

  const handlePincodeInput = async (event) => {
        const inputValue = event.target.value;
        setPincode(inputValue);
        if (inputValue.length >= 5) {
            errors["pincode"]="";
            setErrors(errors);
        TaskAPi.GetCity(inputValue,{})
        .then((resp) => {setCity(resp.city)})
            .catch((error) => {
            const newErrors = {};
            newErrors["pincode"] = "Please Enter a valid Pincode";
            console.log(newErrors);
            setErrors(newErrors);
        setErrors(newErrors);
        setShowError(true)
        })
        }
    };

    const renderSelectedValue = () => {
    if (!country) {
      return '';
    }
    return `+${getCountryCallingCode(country)}`;
  };
useEffect(()=>{
  setCallingCode(+getCountryCallingCode(country))
},[country])

  return (
<Box sx={{ maxWidth: 500, mx: 'auto', mt: 3 ,p:2 }}>
      {showError && <ErrorToast
        message={errors}
      /> }
                  
      <Typography variant="h5" align='center' mb={3}>
        Sign Up as Helper
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{display:'flex'}}>
        <TextField
          fullWidth
          label="First Name"
          required
          name="First Name"
          value={first_name}
          onChange={e => setFirstName(e.target.value)}
          error={Boolean(errors.first_name)}
          helperText={errors.first_name}
          sx={{ ml: 1, mr:1 ,pb:1}}
        />
        <TextField
        fullWidth
          required
          label="Last Name"
          variant="outlined"
          value={last_name}
          onChange={e => setLastName(e.target.value)}
          mb={2}
          error={Boolean(errors.last_name)}
          helperText={errors.last_name}
          sx={{ pr :1 }}
        />
        </Box>

        <TextField
        fullWidth
          required
          label="Email"
          variant="outlined"
          value={email}
          onChange={e => setEmail(e.target.value)}
          mb={2}
          sx={{ p: 1 }}
          error={Boolean(errors.email)}
          helperText={errors.email}
          InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
        />
        <Box display="flex" justifyContent="space-between">
          <FormControl sx={{ m: 1, minWidth: 120 }} size="medium">
        <Select
        value={country}
        renderValue={renderSelectedValue}
        defaultValue="DE"
        onChange={(event) => {
        setCountry(event.target.value || undefined)
        }}>
        {getCountries().map((country) => (
          <MenuItem key={country} value={country}>
            {en[country]} +{getCountryCallingCode(country)}
          </MenuItem>
        ))}
      </Select>
      </FormControl>
      <TextField
            fullWidth
            required
            label="phonenumber"
            variant="outlined"
            value={phone_number}
            onChange={e => setPhone_number(e.target.value)}
            mb={2}
            sx={{ p: 1 }}
            error={Boolean(errors.phone_number)}
            helperText={errors.phone_number}
            InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DialpadIcon />
            </InputAdornment>
          ),
        }}
        />
        </Box>
        

        
        <Box sx={{  p:1 }}>
        <InputLabel>Select minimum of two languages spoken...</InputLabel>
        <Select
          label="Languages"
          multiple
          fullWidth
          // mb={2}
            // sx={{ p: 1,mb:2 ,ml:1,margin:"auto"}}
            
          value={JSON.parse(Languages)}
          onChange={handleChange}
          // input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
        >
          {Listlanguages.map((language) => (
            <MenuItem key={language} value={language}>
              <Checkbox checked={Languages.indexOf(language) > -1} />
              <ListItemText primary={language} />
            </MenuItem>
          ))}
        </Select>
        {!isMinSelectionMet && <p>Please select at least two options</p>}
        </Box>
        
        <TextField
        fullWidth
        required
        label="address"
        variant="outlined"
        value={address}
        onChange={e => setAddress(e.target.value)}
            sx={{ p: 1}}
        error={Boolean(errors.address)}
        helperText={errors.address}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DirectionsIcon />
            </InputAdornment>
          ),
        }}
                
        />
        
        <Box sx={{display:'flex'}}>
        <TextField
         fullWidth
          required
          label="pincode"
          variant="outlined"
          mb={2}
            sx={{ ml: 1, mr:1 ,pb:1}}
          value={pincode}
          onChange={handlePincodeInput}
          error={Boolean(errors.pincode)}
          helperText={errors.pincode}
        />
        <TextField
          fullWidth
          required
          label="city"
          variant="outlined"
          value={city}
          mb={2}
          sx={{ pr :1 }}
          error={Boolean(errors.city)}
          helperText={errors.city}
          readonly
          InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationCityIcon />
            </InputAdornment>
          ),
        }}
        />
        
        </Box>
        
        <TextField
        fullWidth
          required
        type="password"
        label="Password"
        variant="outlined"
        value={password1}
        onChange={e => setPassword1(e.target.value)}
        mb={2}
        sx={{ p: 1 }}
        error={Boolean(errors.password1)}
        helperText={errors.password1}
        InputProps={{
        startAdornment: (
    <InputAdornment position="start">
        <PasswordIcon />
    </InputAdornment>
          ),
        }}
            />
        <TextField
        fullWidth
          required
        type="password"
        label="Password"
        variant="outlined"
        value={password2}
        onChange={e => setPassword2(e.target.value)}
        mb={2}
        sx={{ p: 1 }}
        error={Boolean(errors.password2)}
        helperText={errors.password2}
        InputProps={{
        startAdornment: (
            <InputAdornment position="start">
                <PasswordIcon />
            </InputAdornment>
          ),
        }}
        />
        <TextField
        fullWidth
        type="refferal_code"
        label="Refferal Code"
        variant="outlined"
        value={refferal_code}
        onChange={e => setRefferal_code(e.target.value)}
        mb={2}
        sx={{ p: 1 }}
        error={Boolean(errors.refferal_code)}
        helperText={errors.refferal_code}
        InputProps={{
        startAdornment: (
            <InputAdornment position="start">
                <Diversity3Icon />
            </InputAdornment>
          ),
        }}
        />
        <Box  sx={{  alignItems:"center",display:"flex" , justifyItems:'center',justifyContent:'center'}} >
          <TermsAndConditionsPopup />
        </Box>
      <FormControlLabel
        control={<Checkbox checked={has_accepted_terms} onChange={handleAccept} />}
        label="I accept the terms and conditions"
      />
        <Button startIcon={<LoginIcon />} variant="contained" type="submit" fullWidth>
          Register
        </Button>
        <Box  sx={{  alignItems:"center",display:"flex" , justifyItems:'center',justifyContent:'center'}} >
          <Button onClick={() => navigate('/')} sx={{ textTransform: 'none', fontSize: 16, mb: 2 }}>Already Signed Up? Login</Button>
        </Box>
        
        {errors.non_field_errors &&  <Typography variant="body1" color="error" sx={{ mb: 2 }}>{errors.non_field_errors}</Typography>}
      </form>
    </Box>
  );
};

export default HelperRegister;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useCheckAuth from '../commonuser/CheckAuth';
import RedirectHome from '../payment/redirect';
import UserApiService from '../commonuser/UserApi';
function KycSuccess () {
    const location = useLocation();
    const token  = useCheckAuth();
    const searchParams = new URLSearchParams(location.search);
    const stripe_account_id = searchParams.get('stripe_account_id');
    useEffect( () => {
      console.log(stripe_account_id);
        UserApiService.KycFlow(token,{stripe_account_id})
        .then(resp=> console.log(resp))
    })
    return (
    <div>
      <h2>KYC Success completed please wait for 3-5 business day for verfication</h2>
      <RedirectHome/>
    </div>
      );
}
export default KycSuccess ;
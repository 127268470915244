import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useCheckAuth from '../../commonuser/CheckAuth';
import JobsApi from './JobsAPi';
import NavBar from '../../NavBars/navBar';
import Loading from '../../NavBars/Loading';
import JobDetail from './components/JobDetail';
import BottomBar from '../../NavBars/BottomBar';
import WaitingForVerificationBanner from '../../helpercomponent/WaitingForVerificationBanner';
function JobDetailView() {
    const { jobId } = useParams();
    const token  = useCheckAuth();
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect( () => {
        JobsApi.JobDetail(token,jobId)
        .then((data) => {
            setJob(data);
            setLoading(false);
          });
    },[token,jobId])
    useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        let sub_types = ['BOOKING_CREATED'];
        if (sub_types.includes(event.data.sub_type)){
          JobsApi.JobDetail(token,jobId)
          .then((data) => {
            setJob(data);
            setLoading(false);
          });
        }
      });
    }
    // eslint-disable-next-line
  }, []);
    return ( <div>
        <NavBar />
        <WaitingForVerificationBanner/>
      {loading ? (
        <Loading />
      ) : ( 
        <div>
        <JobDetail job={job} token={token}/>
         </div>
      )}
      <BottomBar/>
    </div> );
}

export default JobDetailView;
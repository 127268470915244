import React, { useState } from 'react';
import axios from 'axios';
import { Box,Chip,IconButton,Typography, Grid,Dialog,DialogTitle,DialogContent,TextField, Button } from '@mui/material';
import { BACKEND_URL } from '../../../../../config';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CircularProgressWithLabel from '@mui/material/CircularProgress';

function UpdateTask({ token,task,showUpdateTask,setShowUpdateTask }) {
  const [title, setTitle] = useState(task.title);
  const [phone_number, setPhoneNumber] = useState(task.phone_number);
  const [description, setDescription] = useState(task.description);
  const [additional_info, setAdditionalInfo] = useState(task.additional_info);
  const [attachment_ids, setAttachment_Ids] = useState(task.attachment_ids ? (JSON.parse(task.attachment_ids)):([]));
  const [attachmnents, setAttachments] = useState(task.attachments);
  const [errors, setErrors] = useState({});
  const [fileerror, setFileError] = useState(null);
  const [files , setFiles ] =  useState([]);
  const [EnableUploadProgress , setEnableUploadProgress] =  useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [abortController, setAbortController] = useState(null);


  const handleDelete = (e) => {
    const updatedList = attachment_ids.filter(item => item !== e);
    const updatedattachment = attachmnents.filter(item => item.id !== e);
    setAttachments(updatedattachment)
    setAttachment_Ids(updatedList)
  };

  const NewhandleRemoveFile = (fileIndex) => {
    setFiles((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex));
  };

  const handleFileUpload =  async (event) => {
    event.preventDefault();    

  const formData = new FormData();
  const controller = new AbortController();
  setAbortController(controller);
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Token ${token}`
      },
      signal: controller.signal,
    };
    
    if (files.length>0) {
      setEnableUploadProgress(true);
    setUploadProgress(0);
      try {
    axios.post(
      `${BACKEND_URL}/marketplace/upload/`,
      formData,
      config
    ).then((resp)=>{
      const attachment_ids = resp.data.map((res) => res.id);
      setEnableUploadProgress(false);
      handleSubmit(attachment_ids);
      setFiles([]);

    })
   
  } catch (error) {
    setEnableUploadProgress(false);
    // setError('Error Occuered when uploading files.')
    setFiles([]);
  }
    } else {
      handleSubmit(attachment_ids);
    }
  };

 const handleNewFileChange = (event) => {
  const selectedFiles = Array.from(event.target.files);
  console.log(selectedFiles);
  const filesizes = [...files, ...selectedFiles]
  const totalSize = filesizes.reduce((acc, curr) => acc + curr.size, 0);
  const isFileNameValid = selectedFiles.every(file => file.name.length <= 50);
  selectedFiles.every((file) => {
    console.log(file.name.length); 
  } );
  

  if (totalSize > 30 * 1024 * 1024) {
        setFileError('File size cannot exceed 30 MB.');
      }
  else if (!isFileNameValid) {
    setFileError('File name cannot exceed 50 characters.');
  } else if (files.length + selectedFiles.length >= 10) {
    setFileError('Maximum number of files exceeded. Please select up to 10 files.'); }
   else {
        setFiles([...files, ...selectedFiles]);
        setFileError(null);
      }
    };

  const handleSubmit = (Newattachment_ids) => {    
    axios
      .put(
        `${BACKEND_URL}/marketplace/task/${task.id}/update/`,
        {
          phone_number: phone_number,
          title: title,
          description: description,
          additional_info: additional_info,
          attachment_ids: JSON.stringify([...Newattachment_ids,...attachment_ids]) 
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      )
      .then((response) => {
        window.location.reload();
      })
      .catch(error => {
        const { data } = error.response;
        const errorFields = Object.keys(data);
        const newErrors = {};
        errorFields.forEach(field => {
          const [errorMessage] = data[field];
          newErrors[field] = errorMessage;
        });
        setErrors(newErrors);
      });
  };
  const handleCancelUpload = () => {
    setFiles([]);
    if (abortController) {
      abortController.abort();
    }
    setUploadProgress(0);
    setEnableUploadProgress(false);
  }
  return (

    <Dialog open={showUpdateTask} onClose={() => setShowUpdateTask(false)}>
          <DialogTitle sx={{margin: "auto" }}>Update Task</DialogTitle>
          <DialogContent>

      <Box  sx={{flexGrow: 1 , display:'flex',margin: "auto" }} p={2}>
    <form   onSubmit={handleSubmit}>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            sx={{mt:2}}
            value={title}
            onChange={event => setTitle(event.target.value)}
            error={!!errors.title}
            helperText={errors.title}
          />
       
          <TextField
            label="Description"
            variant="outlined"
            multiline
            rows={8}
            fullWidth
            sx={{mt:2}}
            value={description}
            onChange={event => setDescription(event.target.value)}
            error={!!errors.description}
            helperText={errors.description}
          />

          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            sx={{mt:2}}
            value={phone_number}
            onChange={event => setPhoneNumber(event.target.value)}
            error={!!errors.phone_number}
            helperText={errors.phone_number}
          />
 
          <TextField
            label="Additional Info"
            variant="outlined"
            fullWidth
            sx={{mt:2}}
            value={additional_info}
            onChange={event => setAdditionalInfo(event.target.value)}
            error={!!errors.additional_info}
            helperText={errors.additional_info}
          />
          <div><br></br></div>

          {attachmnents.length > 0 && attachmnents.map((attachment, index) => (
        <React.Fragment>
        <Chip 
        key={index}
        sx={{p:1,ml:1}}
        onDelete={() => handleDelete(attachment.id)}
        label={attachment.name.slice(0, 50)}
        />
        </React.Fragment>
        ))}
        <div><br></br></div>
        { !EnableUploadProgress && 
          <IconButton variant="contained" component="label">
            <AttachFileIcon />
            <input type="file" hidden onChange={handleNewFileChange} multiple />
            {fileerror && <Box sx={{ color: "error" }}>{fileerror}</Box>}
          </IconButton> }   
          <div><br></br></div> 
            {  !EnableUploadProgress && files.length > 0 && (
              <React.Fragment>
              {files && (
              <Typography fontSize={10} variant="body1" color="warning.main">
                Please do not upload files with personal details.
              </Typography>
            )}
            <Box sx={{ mt: 2, display: 'flex', gap: '8px' }}>
              <Grid container>
               {files && files.map((file, index) => (
                <Grid item xs={12}>
                <Chip key={index} label={file.name} onDelete={() => NewhandleRemoveFile(index)} />
                  <Box my={2} />
                </Grid>
              ))}
             
              </Grid>
            </Box>
            </React.Fragment>
            
          )}
          {EnableUploadProgress && <Box sx={{ display: 'flex', alignItems: 'center' }}>


        <Box sx={{ marginRight: 1 }}>
          <CircularProgressWithLabel value={uploadProgress} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Chip  label="Uploading files" onDelete={() => handleCancelUpload()} />
        </Box>
      </Box>}
          <Button sx={{mt:2}} variant="contained" color="primary" onClick={handleFileUpload}>
            Update Task
          </Button>
    
    </form>
    </Box>
    </DialogContent>
    </Dialog>
  );
}

export default UpdateTask;

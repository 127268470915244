import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {Tooltip,Checkbox,ListItemText, Box,TextField,Chip,Grid,IconButton, Select, MenuItem, Button,InputLabel, setRef } from '@mui/material';
import useCheckAuth from '../../commonuser/CheckAuth';
import TaskAPi from './TaskApi';
import DatePickerv2 from '../utility/DatePickerv2';
import { useNavigate } from "react-router-dom";
import NavBar from '../../NavBars/navBar';
import BottomBar from '../../NavBars/BottomBar';
import ErrorToast from '../../utils/ErrorToast';
import Loading from '../../NavBars/Loading';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import 'react-phone-number-input/style.css'
import axios from "axios";
import CircularProgressWithLabel from '@mui/material/CircularProgress';
import { BACKEND_URL } from '../../../config';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AutocompleteAddress from './components/TaskDetailComponents/AutocompleteAddress';
import GoogleMapsAutocomplete from './components/TaskDetailComponents/GoogleMapsAutocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
// import ReactGA from "react-ga4";

import NoDriversAvailable from './components/TaskFormComponents/NoDriversAvailable';
import { MAX_FILE_SIZE_MB ,MAX_NO_OF_FILE,Listlanguages } from '../../../config';
function TaskForm() {
  const theme = useTheme();
  let navigate = useNavigate()

  const token  = useCheckAuth();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [task_type, setTaskType] = useState('ONLINE')
  const [start_time, setStartTime] = useState('')
  const [end_time, setEndTime] = useState('')
  const [city, setCity] = useState('')
  const [pincode, setPincode] = useState('')
  const [street_name, setStreetName] = useState('')
  const [house_numer, setHouseNumber] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [location_text, setLocation_text] = useState('')
  const [additional_info, setAddInfo] = useState('')
  const [phone_number, setPhoneNumber] = useState('')
  const [errors, setErrors] = useState({})
  const [fprice, setFprice] = useState({})
  const [categories,setCategories] = useState([])
  const [selectedMaincategories,setSelectedMainCategories] = useState([])
  const [category,setCategory] = useState([])
  const [minTime,setMinTime] = useState(30)
  const [phvalue, setphValue] = useState()
  const [country, setCountry] = useState("DE")
  const [CallingCode, setCallingCode] = useState()
  const [fileerror, setFileError] = useState(null);
  const [files , setFiles ] =  useState([]);
  const [EnableUploadProgress , setEnableUploadProgress] =  useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [typing, setTyping] = useState(false);
  const [cityprogress, setCityProgress] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [NumberOfHelpers, setNumberOfHelpers] = useState(1);
  const [ShowNoHelperMessage, setShowNoHelperMessage] = useState(false);
  const [AvailableLanguages, setAvailableLanguages] = useState([]);
  const [SelectedLanguages, setSelectedLanguages] = useState(JSON.stringify([]));
  const [LanguageUnavailable, setLanguageUnavailable] = useState(false);
  const [LanguageProgress, setLanguageProgress] = useState(false);
  const [isCreating, setisCreating] = useState(false);

  // useEffect(()=>{
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Create Task" ,'user_id': 1});
  // },[])
  
  const isMinSelectionMet = JSON.parse(SelectedLanguages).length >= 2;
    // eslint-disable-next-line
    const [showError, setShowError] = useState(false);
    const [FixedPriceTask, setFixedPriceTask] = useState(false);
    const [formErrors, setFormErrors] = useState({
        category: false,
        sub_category: false,
        pincode: false,
    });
    const [form2Errors, setForm2Errors] = useState({
        title: false,
        description: false,
    });
    const [form3Errors, setForm3Errors] = useState({
        start_time: false,
        end_time: false,
    });
    const [form4Errors, setForm4Errors] = useState({
        phone_number: false,
        street_name: true,
        house_numer: true
    });
    const FixedPrice = ( ) =>{
      const category_id = category
    TaskAPi.GetFixedPrice(token,{start_time,end_time,category_id,task_type}).then(
      resp=>(setFprice(resp)
      ))
  }

  useEffect(()=>{    
     if (pincode.length >= 5 && !isMinSelectionMet && city.length >= 2) {
     if (!city) { setLanguageProgress(true)}
      setNumberOfHelpers(1)
      TaskAPi.GetNumberOfHelpers(token,{pincode,task_type,start_time,end_time,city})
      .then((resp) => {
        setNumberOfHelpers(resp.no_of_helpers)
        setAvailableLanguages(resp.languages)
        setLanguageProgress(false)
      ;})
     }

     if (isMinSelectionMet) {
      TaskAPi.GetNumberOfHelpers(token,{pincode,task_type,start_time,end_time,city,selected_languages:SelectedLanguages})
      .then((resp) => {
        setLanguageUnavailable(true)
        setNumberOfHelpers(resp.no_of_helpers)
      ;})
      
     }
   },[pincode,task_type,city,SelectedLanguages])

  useEffect(()=>{
    if (NumberOfHelpers === 0){
      setShowNoHelperMessage(true)
      setPincode('')
      setCity('')
      if (LanguageUnavailable) {
      setSelectedLanguages(JSON.stringify([]))
    }
    }
    
  },[NumberOfHelpers])
  
    useEffect(()=>{
        TaskAPi.GetCategories(token)
            .then((resp) => {
              setCategories(resp)
              setLoading(false)})
            .catch((error) => {
            const { data } = error.response;
            const errorFields = Object.keys(data);
            const newErrors = {};
            errorFields.forEach(field => {
            const [errorMessage] = data[field];
            newErrors[field] = errorMessage;
            });
        setErrors(newErrors);
         setShowError(true)
        })
    },[token])
      // const handleCloseError = () => {
      //   setShowError(false);
      //  
      // };
    const handleSelectChange = event => {
        setFormErrors((prev) => ({ ...prev, category: true }));
        const selectedCategoryId = event.target.value;
        const selectedCategory = categories.find(category => category.id === parseInt(selectedCategoryId));
        setSelectedMainCategories(selectedCategory);
        };
    const handleSubService = event => {
        setCategory(event.target.value);
        
        const selectedCategory = categories.find(category => category.id === parseInt(selectedMaincategories.id));
        const selectedSubCategory = selectedCategory.sub_service.find(category => category.id === parseInt(event.target.value));
        if (selectedSubCategory.type =="FIXED") {
          setFixedPriceTask(true)
          
        }
        setFormErrors((prev) => ({ ...prev, sub_category: true }))
        };
    useEffect(()=>{
      if (start_time && end_time) {
         if (FixedPriceTask) {FixedPrice()}
      }
     
    },[categories,start_time,end_time])
    const handlePincodeInput = async (event) => {
        const inputValue = event.target.value;
        setPincode(inputValue);
        
        if (inputValue.length >= 3) {
          setCityProgress(true)
        }
        if (inputValue.length >= 5) {
            errors["pincode"]="";
            setErrors(errors);
        TaskAPi.GetCity(inputValue,{})
        .then((resp) => {
          setCity(resp.city);
          setCityProgress(false)
          setFormErrors((prev) => ({ ...prev, pincode: true }))})
            .catch((error) => {
            const newErrors = {};
            newErrors["pincode"] = "Please Enter a valid Pincode";
            setErrors(newErrors);
            setShowError(true)
            setCityProgress(true)
       
        })
        }
    };
    const HandleTaskType = event => {
       const value = event.target.value;
       setTaskType( event.target.value)
       setFormErrors((prev) => ({ ...prev, task_type: !value }));
       if ( event.target.value ==="IN_PERSON"){
        setForm4Errors((prev) => ({ ...prev, street_name: false,house_numer:false }))
        setMinTime(60)
       }
        };

    const handleNext = (e) => {
      e.preventDefault();
      if (activeStep=== 1) {
        if (start_time && end_time) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } 
        else {
          if (!start_time) {
            setErrors({
            "non_field_errors":"Start Time required"
          })
          }
          if (!end_time) {
            setErrors({
            "non_field_errors":"End Time required"
          })
        } 
          setShowError(true)
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const createTask =(attachment_ids) => {
    // e.preventDefault();
    // handleFileUpload(e)
    setisCreating(true)
    console.log(isCreating);
    const attachment_id = JSON.stringify(attachment_ids)
    const ph = CallingCode + phone_number
        TaskAPi.CreateTask(token,{description,title,task_type,start_time,end_time,city,
            pincode,street_name,house_numer,additional_info,phone_number:ph,category,attachment_ids:attachment_id,
          latitude:latitude,longitude:longitude,location:location_text,languages_selected:SelectedLanguages})
            .then((resp) =>  {
            setisCreating(false)
            navigate(`/user/task/${resp.external_id}`);
            
            })
            .catch((error) => {
            const { data } = error.response;
            const errorFields = Object.keys(data);
            const newErrors = {};
            errorFields.forEach(field => {
            const [errorMessage] = data[field];
            newErrors[field] = errorMessage;
            });
        setErrors(newErrors);
        setShowError(true)
        setisCreating(false)
        })
    }
  const handleFileUpload =  async (event) => {
    event.preventDefault();    


   const controller = new AbortController();
  setAbortController(controller);
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Token ${token}`
      },
      signal: controller.signal,
    };
    
    if (files.length>0) {
    setEnableUploadProgress(true);
    setUploadProgress(0);

      try {
    axios.post(
      `${BACKEND_URL}/marketplace/upload/`,
      formData,
      config
    ).then((resp)=>{
      const attachment_ids = resp.data.map((res) => res.id);
      setEnableUploadProgress(false);
      setisCreating(true)
      createTask(attachment_ids);
      setFiles([]);

    })
   
  } catch (error) {
    setEnableUploadProgress(false);
    // setError('Error Occuered when uploading files.')
    setFiles([]);
  }
    } else {
      createTask([])
    }
  };
    const handleRemoveFile = (fileIndex) => {
    setFiles((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex));
  };

    const handleCancelUpload = () => {
    // setActiveStep(3)
    if (abortController) {
      abortController.abort();
    }
    setUploadProgress(0);
    setEnableUploadProgress(false);
  }

  const handleFileChange = (event) => {
  const selectedFiles = Array.from(event.target.files);
  const filesizes = [...files, ...selectedFiles]
  const totalSize = filesizes.reduce((acc, curr) => acc + curr.size, 0);
  const isFileNameValid = selectedFiles.every(file => file.name.length <= 50);
  selectedFiles.every((file) => {
  } );
  

  if (totalSize > MAX_FILE_SIZE_MB * 1024 * 1024) {
        setFileError('File size cannot exceed 50 MB.');
      }
  else if (!isFileNameValid) {
    setFileError('File name cannot exceed 50 characters.');
  } else if (files.length + selectedFiles.length >= MAX_NO_OF_FILE) {
    setFileError('Maximum number of files exceeded. Please select up to 10 files.'); }
   else {
        setFiles([...files, ...selectedFiles]);
        setFileError(null);
      }
    };
  const handleSubmit= (e) =>{
    if (FixedPriceTask) {handleNext(e)}
    else {handleFileUpload(e)}
  }

  const handleSubmitfixed= (e) =>{

    handleFileUpload(e)
  }

  function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}
useEffect(()=>{
  setCallingCode(+getCountryCallingCode(country))

},[country])

const renderSelectedValue = () => {
    if (!country) {
      return '';
    }
    return `+${getCountryCallingCode(country)}`;
  };
const handleLanguagesChange = (event) => {

  const selectedValues = event.target.value;
  const selectedJSON = JSON.stringify(selectedValues);
  setSelectedLanguages(selectedJSON);
};

const steps = [
    { label: 'Choose Task and Location',
     formValidate: formErrors,
     formid: "formErrors",
     content: 
      (
        <React.Fragment>
           
      <form id ="formErrors" onSubmit={handleNext}>
      <InputLabel>Task Type...</InputLabel>
        <Select
            fullWidth
            label="Task Type"
            required
            value={task_type}
            onChange={HandleTaskType}
            error={Boolean(errors.task_type)}
            helperText={errors.task_type}

        >
            <MenuItem 
            // style={{
            //   backgroundColor: '#F8F8FF',
            // }}
            value="ONLINE">Online</MenuItem>
            <MenuItem
            // style={{
            //   backgroundColor: '#F8F8FF',
            // }}

             value="IN_PERSON">In Person</MenuItem>
        </Select>
        <InputLabel>Select a category...</InputLabel>
        
            <Select
            fullWidth
            required
            label="Task Category"
            value={selectedMaincategories.id}
            onChange={handleSelectChange}
            renderValue={() => selectedMaincategories.name}
            >
            {categories.map(category => (
            <MenuItem 
            // style={{
            //   backgroundColor: '#F8F8FF',
            // }}
            value={category.id} key={category.id}>{category.name}</MenuItem>
            ))}
            </Select>
          
        <InputLabel>Select a sub category...</InputLabel>
            <Select
            required
            fullWidth
            label="Task Sub Category"
            value={category}
            onChange={handleSubService}
            >
            {selectedMaincategories.sub_service && selectedMaincategories.sub_service.map(category => (
            <MenuItem  
            // style={{
            //   backgroundColor: '#F8F8FF',
            // }}

            value={category.id} key={category.id}>{category.name}</MenuItem>
            ))}
                
            </Select>
        <TextField
        fullWidth
        sx={{mt:2}}
        label="Pincode"
        value={pincode}
        onChange={handlePincodeInput}
        error={Boolean(errors.pincode)}
        helperText={errors.pincode}
        required
    />
   {!cityprogress ? (
     <TextField
        fullWidth
        label="City"
        sx={{mt:2}}
        value={city}
        error={Boolean(errors.city)}
        helperText={errors.city}
        readonly
    />

   ) :(<CircularProgress/>)} 
   <InputLabel>Select minimum of two languages...</InputLabel>
   {!LanguageProgress ? (
    
    <Select
    fullWidth
    required
    label="Languages"
    multiple
    value={JSON.parse(SelectedLanguages)}
    onChange={handleLanguagesChange}
    renderValue={(SelectedLanguages) => SelectedLanguages.join(', ')}
    >
    {AvailableLanguages.map((language) => (
          <MenuItem key={language} value={language}>
            <Checkbox checked={SelectedLanguages.indexOf(language) > -1} />
            <ListItemText primary={language} />
          </MenuItem>
        ))}
    </Select>

   ) :(<CircularProgress/>)} 
   
       
            </form>
        </React.Fragment>
      ) },
      { label: 'Enter Task time',
  formValidate: form3Errors,
  formid:"form3Errors",
   content: 
      (
      <React.Fragment>
         <form id ="form3Errors" onSubmit={handleNext}>
      <DatePickerv2
        onStartChange={(date) => {
          setStartTime(date)
          setForm3Errors((prev) => ({ ...prev, start_time: true }))
        }}
        onEndChange={(date) => {
          setEndTime(date)
          setForm3Errors((prev) => ({ ...prev, end_time: true }))
        }}
        minutes = {30}
        hasMaxDate = {false}
        />
        </form>
            
    {errors.start_time && <div className="alert alert-danger">{errors.start_time}</div>}
    {errors.end_time && <div className="alert alert-danger">{errors.end_time}</div>}
        </React.Fragment>
      ) },
      { label:  'Enter Task Details' , 
      formValidate: form2Errors,
      formid:"form2Errors",
      content: 

      (
        <React.Fragment>
          <form id ="form2Errors" onSubmit={handleNext}>
      <TextField fullWidth label="Title" 
      sx={{mt:2}} value={title} 
      onChange={(e) => {
        setTitle(e.target.value)
        setForm2Errors((prev) => ({ ...prev, title: true }))
      }} 
      error={Boolean(errors.title)}
      helperText={errors.title} required />
      <TextField
        fullWidth
        label="Description"
        sx={{mt:2}}
        multiline
        rows={5}
        value={description}
        onChange={(e) => {
        setDescription(e.target.value)
        setForm2Errors((prev) => ({ ...prev, description: true }))}}
        error={Boolean(errors.description)}
        helperText={errors.description}
        required
            />
            </form>

        

        { !EnableUploadProgress && 
          <IconButton variant="contained" component="label">
            <AttachFileIcon />
            <input type="file" hidden onChange={handleFileChange} multiple />
            {fileerror && <Box sx={{ color: "error" }}>{fileerror}</Box>}
          </IconButton> }    
            {  !EnableUploadProgress && files.length > 0 && (
              <React.Fragment>
              {files && (
              <Typography fontSize={10} variant="body1" color="warning.main">
                Please do not upload files with personal details.
              </Typography>
            )}
            <Box sx={{ mt: 2, display: 'flex', gap: '8px' }}>
              <Grid container>
               {files && files.map((file, index) => (
                <Grid item xs={12}>
                <Chip key={index} label={file.name} onDelete={() => handleRemoveFile(index)} />
                  <Box my={2} />
                </Grid>
              ))}
             
              </Grid>
            </Box>
            </React.Fragment>
            
          )}

        </React.Fragment>
      ) },

  
  {
    label: 'Contact Details',
    formValidate: form4Errors,
    formid:"form4Errors",
    content: (
    <React.Fragment>
       <form id ="form4Errors" onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between">
        <Select
        value={country}
        sx={{mt:2,mr:2}}
        renderValue={renderSelectedValue}
        defaultValue="DE"
        onChange={(event) => {
        setCountry(event.target.value || undefined)
        }}>
        {getCountries().map((country) => (
          <MenuItem key={country} value={country}>
            {en[country]} +{getCountryCallingCode(country)}
          </MenuItem>
        ))}
      </Select>
      <Tooltip title="We Do not Send any Promotional Messages without user Consent. Please Provide the Mobile Number to send Task Updates." arrow>
    <TextField
        fullWidth
        label="Phone Number"
        sx={{mt:2}}
        value={phone_number}
        onChange={e => {
          setPhoneNumber(e.target.value)
          setForm4Errors((prev) => ({ ...prev, phone_number: true }))
        }}
        error={Boolean(errors.phone_number)}
        helperText={errors.phone_number}
        required
    />
    </Tooltip>
   
    </Box>
    
    {task_type==="IN_PERSON" && (
        <React.Fragment>
           {/* <GoogleMapsAutocomplete 
      setStreetName={setStreetName} 
     setHouseNumber={setHouseNumber} 
     setLatitude={setLatitude} 
     setLongitude={setLongitude}
     setLocation_text={setLocation_text}
    /> */}
        <TextField
        fullWidth
        label="Street Name"
        sx={{mt:2}}
        value={street_name}
        onChange={(e) => {
          setStreetName(e.target.value)
         setForm4Errors((prev) => ({ ...prev, street_name: true }))
        }}
        error={Boolean(errors.street_name)}
        helperText={errors.street_name}
        required
    />
    <TextField
        fullWidth
        label="House Number"
        sx={{mt:2}}
        value={house_numer}
        onChange={e => {
        setHouseNumber(e.target.value)
        setForm4Errors((prev) => ({ ...prev, house_numer: true }))}}
        error={Boolean(errors.house_numer)}
        helperText={errors.house_numer}
        required
    />
    <TextField
        fullWidth
        label="Additional Info"
        value={additional_info}
        sx={{mt:2}}
        onChange={e => setAddInfo(e.target.value)}
        error={Boolean(errors.additional_info)}
        helperText={errors.additional_info}
    />
    


    </React.Fragment>

    )}
    </form>
    {EnableUploadProgress && <Box sx={{ display: 'flex', alignItems: 'center' }}>


        <Box sx={{ marginRight: 1 }}>
          <CircularProgressWithLabel value={uploadProgress} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
                    <Chip  label="Uploading files" onDelete={() => handleCancelUpload()} />

        </Box>
      </Box>}

    </React.Fragment>
      )
  },
  {
    label: 'Pricing Details',
    formValidate: form4Errors,
    formid:"form5Errors",
    content: (
      <React.Fragment>
      <form id="form5Errors" onSubmit={handleSubmitfixed}></form>
      <Grid container spacing={1} display="flex"justifyContent="center">
      <Grid  item style={{ flexGrow: "1"}}  xs={8} >
        <Typography variant="subtitle1">Price/Hr(Inc Vat): </Typography> 
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle1"><strong>{fprice?.price_hr_inc_vat?.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</strong></Typography> 
      </Grid>
      <Grid  item xs={8}>
        <Typography variant="subtitle1">Price(Exc Vat): </Typography> 
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle1"><strong>{fprice?.total_price_exc_vat?.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</strong></Typography> 
      </Grid>
      <Grid  item xs={8}>
        <Typography variant="subtitle1">Vat: </Typography> 
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle1"><strong>{ roundToTwo(fprice.total_price_inc_vat - fprice.total_price_exc_vat,2)?.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</strong></Typography> 
      </Grid>
      <Grid  item xs={8}>
        <Typography variant="subtitle1">Total Price </Typography> 
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle1"><strong>{ fprice?.total_price_inc_vat?.toLocaleString("en-US", {style:"currency", currency:"EUR"}) }</strong></Typography> 
      </Grid>
      </Grid>
       {/* <Typography>progress</Typography> */}
      {EnableUploadProgress && <Box sx={{ display: 'flex', alignItems: 'center' }}>


        <Box sx={{ marginRight: 1 }}>
          <CircularProgressWithLabel value={uploadProgress} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body2">
            {uploadProgress === 0 ? 'Uploading files' : 'Uploaded ' + uploadProgress + '%'}
          </Typography>
        </Box>
      </Box>}
      </React.Fragment>
)}
];


  
  const maxSteps =   FixedPriceTask ? steps.length: steps.length-1;
  const handleClose = () => {
    setShowNoHelperMessage(false);
  };

  return (
    <React.Fragment>
    <NavBar/>{loading ? (
        <Loading />
      ) : ( 
        <React.Fragment> 
    <ErrorToast message={errors}/>
    <NoDriversAvailable
     open={ShowNoHelperMessage}
     onClose={handleClose}
     setShowNoHelperMessage={setShowNoHelperMessage}
     message={LanguageUnavailable ? "Sorry, there are currently no Helper available for the languages you selected. Please Select different languages (German and English are available Easily)" : "Sorry, there are currently no Helper available in your area. Please Stay Tuned. Please also Try Changing the Task Type to Online If it is applicable"}

    //  message="Sorry, there are currently no Helper available in your area. Please Stay Tuned. Please also Try Changing the Task Type to Online If it is applicable"
     buttonText="No Helpers available"
    />
    <Box justifyContent= "space-evenly"
        alignItems="center"   
        sx={{flexGrow: 1 , flexDirection:'column', justifyContent:'center',  display: 'flex',}} 
        p={2}>
      <Grid md={8} 
      direction="column"
      container 
      justifyContent= "center"
      alignItems="stretch"
            >
      <Typography sx={{flexGrow:1,display:"flex",justifyContent:'center'}} variant="h5">Post a Task</Typography>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
           background: '#0dcaf000',
          height: 50,
          pl: 2,
        }}
      >
        
        <Typography sx={{flexGrow:1,display:"flex",justifyContent:'center'}} variant="h7">{steps[activeStep].label}</Typography>
      </Paper>
      <Box sx={{flexGrow: 1}} p={2}>
        
        {steps[activeStep].content }
      </Box>

      <MobileStepper
        // variant="text"
        variant="progress"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{  background: '#0dcaf000',}}
        nextButton={
            <React.Fragment>
              <Box>
                {activeStep === maxSteps-1 ? (
                <React.Fragment>
                    <React.Fragment>
                <Button 
                // onClick ={createTask}
                 variant="contained"
                 type='submit'
                 form={steps[activeStep].formid}
                 disabled={isCreating}
                  > 
                    {isCreating ? <CircularProgress size={20} /> :  "Create"}                  
                  </Button>
                </React.Fragment> 
                </React.Fragment>
              )
                : <React.Fragment>
                <Button
                    size="small"
                    type='submit'
                    form={steps[activeStep].formid}
                >
                    Next
                    {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                    ) : (
                    <KeyboardArrowRight />
                    )}
                </Button>
                 
          </React.Fragment>}
          </Box>

          
          </React.Fragment>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
      </Grid>
    </Box>
    </React.Fragment> )}
    <BottomBar/>
    </React.Fragment>
  );
}

export default TaskForm;
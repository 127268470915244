import React ,{useEffect,useState} from 'react';
import { IconButton,CardContent,Card,Typography, Box,Divider, CardHeader ,Grid} from "@mui/material";
import moment from "moment";
import CreateQuote from './CreateQuote';
import CancelQuote from './CancelQuote';
import GoBack from '../../../commonuser/GoBack';
import AcceptJob from './AcceptJob';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import JobsApi from '../JobsAPi';
function JobDetail({job,token}) {
    const created = moment(job.created_at).fromNow();
    const [fixedPrice, setFixedPrice] = useState(false);
    const [isLiked, setIsLiked] = useState(job.liked);
    const [isDisliked, setIsDisliked] = useState(job.disliked);
    const online_rec = 15
    const inperson_rec = 30
    useEffect(()=>{
      if (job.disable_offers) {
        setFixedPrice(true)
      }
      console.log(job);
    },[job])

    

    const dateConvert = (ds) =>{
        return  moment(ds).format('MMMM Do YYYY, h:mm a');
    }

    const handleLike= (action)=>{
        JobsApi.jobInteractions(token ,job.id,action)
        .then( resp =>{
          if (action==="like") {
            setIsLiked(true)
            setIsDisliked(false)
          }
          if (action==="dislike") {
            setIsLiked(false)
            setIsDisliked(true)
          }
        }
          
        )
    }
    useEffect(()=>{
      handleLike("open")
    },[])

    return ( 
    <div>
        <Box sx={{flexGrow: 1}} p={2}>
          <GoBack/>
        <Card style={{backgroundColor: "#0dcaf000"}} elevation={1} >
            <CardHeader
                    title={<Typography sx={{ml:3,display:"flex",justifyContent:'center'}} variant="h5">Job Details</Typography>}
                    action={
                      <React.Fragment>
                         <IconButton
        color={isLiked ? 'primary' : 'default'}
        onClick={()=>(handleLike("like"))}
        aria-label="Like"
      >
        {isLiked ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}
      </IconButton>
      <IconButton
        color={isDisliked ? 'primary' : 'default'}
        onClick={()=>(handleLike("dislike"))}
        aria-label="Dislike"
      >
        {isDisliked ? <ThumbDownAltIcon /> : <ThumbDownOffAltIcon />}
      </IconButton>

                      </React.Fragment>
                    }
            ></CardHeader>
       
        <CardContent>
        {job.task_data.task_type==="IN_PERSON" ? (<Typography sx={{ fontSize:15  }}> <strong>In Person Appoinment</strong></Typography>) :(<Typography sx={{ fontSize:15 }}> <strong>Online Appoinment</strong></Typography>)}
        <Typography sx={{ fontSize:12 }}>Posted {created}</Typography>
        </CardContent>
        <Divider>Job Details</Divider>
        <CardContent>
        <Grid container spacing={0.3}>
        <Grid  item xs={4}>
          <Typography  variant="subtitle1"><strong>Category:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{job.task_data.parent_category_name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1"><strong>Sub Category:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{job.task_data.category_name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>Start Time: </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{dateConvert(job.task_data.start_time)}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>End Time:  </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{dateConvert(job.task_data.end_time)}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>Total Hours:  </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{job.task_data.total_no_hours}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>Languages Selected:  </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">
            {job.task_data.languages_selected && JSON.parse(job.task_data.languages_selected).join(', ')}
            </Typography>
        </Grid>
        {job?.quote_data.price_hr &&
        (
          <React.Fragment>
          <Grid item xs={4}>
          <Typography variant="body1"><strong>Quote Sent:  </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{job?.quote_data.price_hr.toLocaleString("en-US", {style:"currency", currency:"EUR"})}/hr ({(job?.quote_data.price_hr * job.task_data.total_no_hours).toLocaleString("en-US", {style:"currency", currency:"EUR"})})</Typography>
        </Grid>
        </React.Fragment>
        ) }
        {job?.quote_data.comp_price &&
        (
          <React.Fragment>
          <Grid item xs={4}>
          <Typography variant="body1"><strong>Quote Sent:  </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{job?.quote_data.comp_price.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
        </Grid>
        </React.Fragment>
        ) }
        
      </Grid>
      </CardContent>
        <Divider>Description</Divider>
       <CardContent>
      <Typography variant="subtitle1"> {job.task_data.description}</Typography>
      
        {job.task_data.additional_info && <Typography variant="subtitle1"><strong>Additional Info: </strong>{job.task_data.additional_info}</Typography> }
     </CardContent>
    {job.task_data.task_type==="IN_PERSON" && (
        <React.Fragment>
      <Divider>Location Details</Divider>
        <CardContent>
    <Grid container spacing={0.3}>
        <Grid item xs={4}>
          <Typography variant="subtitle1"><strong>Pincode:</strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{job.task_data.pincode}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1"><strong>Location: </strong></Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{job.task_data.city}</Typography>
        </Grid>
    </Grid>
    </CardContent>

    
        
            </React.Fragment>
            )
        }
         {fixedPrice &&  (
           <React.Fragment>
            <Divider>Job Payment</Divider>
              <CardContent>
          <Grid container spacing={0.3}>
              <Grid item xs={4}>
                <Typography variant="subtitle1"><strong>Total Price:</strong></Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{job.total_fixed_price?.total_price_exc_vat?.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography>
              </Grid>
          </Grid>
          </CardContent>
            </React.Fragment>
         )
         }
        

        </Card>
        {!fixedPrice ?
        (
          <React.Fragment>
          {job.quote_data.status === "WAITING_FOR_QUOTE" && ( 
          <React.Fragment>
            <Box sx={{display:"flex",mt:2,justifyContent:'center'}}>
            {job.task_data.task_type==="IN_PERSON" && (<Typography>Recommended Price :  {inperson_rec.toLocaleString("en-US", {style:"currency", currency:"EUR"})}/hr</Typography>)}
            {job.task_data.task_type==="ONLINE" && (<Typography>Recommended Price : {online_rec.toLocaleString("en-US", {style:"currency", currency:"EUR"})}/hr</Typography>)}
            </Box>
            </React.Fragment>
          )}
          
        <Box sx={{ml:3,display:"flex",mt:2,justifyContent:'center'}}>
        {job.quote_data.status === "WAITING_FOR_QUOTE" && ( 
          <React.Fragment>
            <CreateQuote task_id={job.task} token={token} total_no_hours={job.task_data.total_no_hours} connects_required={job.task_data.connects_required}/> 
          </React.Fragment>
            )}
        {job.quote_data.status.includes("CREATED") && (
            <CancelQuote quote_id={job.quote_data.id} token={token}/>
        )}
        </Box>
        </React.Fragment>
        ) :(<div>
          <Box sx={{display:"flex",mt:0,p:2,justifyContent:'center'}}>
        {job.quote_data.status === "WAITING_FOR_QUOTE" && ( 
          <AcceptJob task_id={job.task} token={token}  connects_required={job.task_data.connects_required}/>
        )}
          </Box>
        </div>)}
        
        </Box>

    </div> 
    );
}

export default JobDetail;
import React, {  useEffect, useState } from 'react';
import { Button, FormControl, FormHelperText, TextField, Typography } from '@mui/material';
import "react-datepicker/dist/react-datepicker.css";
import useCheckAuth from '../../../commonuser/CheckAuth';
import BookingApi from '../BookingApi';
import { Box, Dialog,DialogTitle,DialogContent } from '@mui/material';
// import DatePicker from '../../utility/DatePicker';
import DatePickerv2 from '../../utility/DatePickerv2';
function AdditionalBookingForm({ booking,showAdditionalBookingForm, setShowAdditionalBookingForm}) {
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [price_hr, setPricehr] = useState(null);
    const [start_time, setStartTime] = useState(null);
    const [end_time, setEndTime] = useState(null);
    const token  = useCheckAuth();
    const [onsbkerrors, setOnsbkErrors] = useState({});
    const [totalHours, setTotalHours] = useState(0);

    useEffect(()=>{
    const timeDiff = Math.abs(end_time - start_time);
    const totalHours =(timeDiff / (1000 * 60 * 60));
    console.log("totalHours",totalHours,timeDiff);
    setTotalHours(totalHours);
    console.log(start_time,end_time);
    },[start_time,end_time])

    const OnSpotBooking =(task) => {
        BookingApi.OnSpotBooking(token,{title,description,start_time,end_time,price_hr,task})
        .then((resp) =>  {
            setShowAdditionalBookingForm(false);
            window.location.reload();
        })
      .catch((error) => {
          const { data } = error.response;
          const errorFields = Object.keys(data);
          const newErrors = {};
          errorFields.forEach(field => {
          const [errorMessage] = data[field];
          newErrors[field] = errorMessage;
          });
          console.log(newErrors);
          setOnsbkErrors(newErrors);
        })
    }
    return (
    <>

    <Dialog  open={showAdditionalBookingForm} onClose={() => setShowAdditionalBookingForm(false)}>

    <DialogTitle sx={{margin: "auto" }}>Create Additonal Booking</DialogTitle>
      <DialogContent>
         <Box  sx={{flexGrow: 1 , display:'flex',margin: "auto" }} p={2}>
        <FormControl>
          <TextField
            fullWidth
            type="text"
            placeholder="Enter Title"
             sx={{mb:2}}
            id="title"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          {onsbkerrors.pincode && (
            <FormHelperText error={true}>{onsbkerrors.title}</FormHelperText>
          )}
          <TextField
            type="text"
            fullWidth
            rows={5}
            sx={{mb:2}}
            multiline
            placeholder="Enter description"
            id="description"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          {onsbkerrors.description && (
            <FormHelperText error={true}>{onsbkerrors.description}</FormHelperText>
          )}

          <TextField
            type="number"
            placeholder="Enter Price/Hr"
            id="price_hr"
            value={price_hr}
            onChange={e => setPricehr(e.target.value)}
          />
          {onsbkerrors.price_hr && (
            <FormHelperText error={true}>{onsbkerrors.price_hr}</FormHelperText>
          )}
          <br></br>

          {/* <DatePicker
            startdate = {booking.task_data.start_time}
            enddate= {booking.task_data.end_time}
            onStartChange={(date) => setStartTime(date)}
            onEndChange={(date) => setEndTime(date)}
            minutes = {15}
            hasMaxDate = {true}
            /> */}
          <DatePickerv2
          startdate = {booking.task_data.start_time}
            enddate= {booking.task_data.end_time}
        onStartChange={(date) => (setStartTime(date))}
        onEndChange={(date) => (setEndTime(date))}
        minutes = {15}
        hasMaxDate = {false}
        />
        {totalHours >0 && <Typography>Total Amount: {(price_hr * totalHours).toLocaleString("en-US", {style:"currency", currency:"EUR"})}</Typography> }
          {onsbkerrors.end_time && (
            <FormHelperText error={true}>{onsbkerrors.end_time}</FormHelperText>
          )}
          {onsbkerrors.non_field_errors && (
            <FormHelperText error={true}>{onsbkerrors.non_field_errors}</FormHelperText>
          )}
          <Button
            sx={{mt:2}}
            onClick={() => {
              OnSpotBooking(booking.task_data.id);
            }}
            variant="contained"
            color="primary"
          >
            CREATE ADDITIONAL BOOKING
          </Button>
        </FormControl>
        </Box>
        </DialogContent>
        </Dialog>
    </>
  );
}
export default AdditionalBookingForm;
import { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import UserApiService from './UserApi';
import GoBack from './GoBack';
const ResendEmailVerification = () => {
  const [email, setEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [errors, setErrors] = useState({});
  let navigate = useNavigate()


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    UserApiService.ResendEmailVerification({email})
        .then((resp) => {
          console.log(resp)
          setIsEmailSent(true);
        })
        .catch((error) => {
                const { data } = error.response;
                const errorFields = Object.keys(data);
                console.log(errorFields);
                const newErrors = {};
                errorFields.forEach(field => {
                const [errorMessage] = data[field];
                newErrors[field] = errorMessage;
                });
                setErrors(newErrors);
              })
    
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4, p: 2 }}>
      {isEmailSent ? (
        <>
          <Typography variant="h5" gutterBottom>
            Check your email
          </Typography>
          <Typography variant="body1" gutterBottom>
            We've sent an email to {email}. Click on the verification link in the email to complete your registration.
          </Typography>
        <Button onClick={() => navigate('/')} sx={{ textTransform: 'none', fontSize: 16, mb: 2 }}>Login</Button>

        </>
      ) : (
        <>
        <GoBack/>
          <Typography variant="h5" gutterBottom>
            Verify your email
          </Typography>
          <Typography variant="body1" gutterBottom>
            We've sent a verification link to your email address. Please click on the link to complete your registration. if not Send verification link again.
          </Typography>
          <form onSubmit={handleEmailSubmit}>
            <TextField
              id="email"
              name="email"
              label="Email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              required
              fullWidth
              sx={{ my: 2 }}
            />
            <Button type="submit" variant="contained">
              Send Verification Link
            </Button>
          </form>
    {errors.non_field_errors && <div className="alert alert-danger">{errors.non_field_errors}</div>}

          
        </>
      )}
    </Box>
  );
};

export default ResendEmailVerification;

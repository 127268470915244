import React, { useState, useEffect } from 'react';
import useCheckAuth from '../commonuser/CheckAuth';
import {ListItemText,Avatar,Divider,ListItemButton, Card, CardContent, CardHeader, Typography } from '@mui/material';
import Loading from '../NavBars/Loading';
import { useDispatch } from 'react-redux';
import { setUserEmail ,setHelperType,setUserCredit,setUserFirstName,setUserLastName,setUserCubes,setUserAccess} from '../reducers/userActions';
import UserApiService from '../commonuser/UserApi';
import theme from '../Styles/theme';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PasswordChange from '../user/PasswordChange';
import SocialConnect from '../commonuser/SocialConnect';
import OrgApi from './OrgApi';
import NavBar from './PopOverMenu';
import BottomBar from './BottomBar';
import AllowOrgAdmin from '../permissions/AllowOrgAdmin';
function OrgAdminProfile() {
    const [profile, setProfile] = useState({});
    const [Orgprofile, setOrgProfile] = useState({});
    const token = useCheckAuth()
    const [showProfile, setshowProfile] = useState(true);
    const [ShowSocialAccont, setShowSocialAccont] = useState(false);
    const [loading, setLoading] = useState(true);
    const [passwordChange, showpasswordChange] = useState(false);
    const dispatch = useDispatch(); 
  useEffect(() => {
    OrgApi.GetOrg(token)
    .then((resp)=>{
        setOrgProfile(resp[0])
    })
    UserApiService.GetHelperProfile(token).then((resp) => {
      setProfile(resp);
      setLoading(false);
      dispatch(setUserEmail(resp.email));
      dispatch(setUserCredit(resp.user_credit));
      dispatch(setUserFirstName(resp.first_name));
      dispatch(setUserLastName(resp.last_name));
      dispatch(setUserCubes(resp.connects_amount))
      dispatch(setUserAccess(resp.is_access_enabled))
      dispatch(setHelperType(resp.helper_type))
    });
    
  }, [token,dispatch]);
    const handleClick = (stripe_account_id) => {
        OrgApi.GetStripeDashboardUrl(token, { stripe_account_id }).then(
        (resp) => { (window.location.href = resp.stripe_dashboard_url) }
        );
    };


    return ( 
        <div>
          <AllowOrgAdmin/>
             <NavBar />
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
             {ShowSocialAccont && <SocialConnect token={token} setShowSocialAccont={setShowSocialAccont} setshowProfile={setshowProfile}/> }

           {passwordChange && <PasswordChange token={token} showpasswordChange={showpasswordChange} setshowProfile={setshowProfile} />   }
        {!showProfile ? null :(
        <Card style={{backgroundColor: "#0dcaf000"}}
              sx={{ maxWidth: 800,margin: "auto",}}
              >


             <CardHeader
                avatar={
                    <Avatar aria-label="profile picture" 
                    sx={{backgroundColor: theme.palette.primary.main,}}
                     />
                  }
                 title={<Typography variant="h5">{profile.first_name} {profile.last_name}</Typography>}
                 subheader={<Typography variant="subtitle1">{profile.email}</Typography>}
      
                >
                </CardHeader>     
                <CardContent>
              </CardContent>
              
                <Typography sx={{marginLeft:2}}variant="h6">Personal Information</Typography>
            <CardContent>
                <Divider  component="li" />
                <Typography variant="subtitle1"><strong>First Name:</strong> {profile.first_name}</Typography>
                <Typography variant="subtitle1"><strong>Last Name:</strong>  {profile.last_name}</Typography>
                <Typography variant="subtitle1"><strong>Phone Number:</strong>  {profile.phone_number}</Typography>
                <Typography variant="subtitle1"><strong>Address:</strong>  {profile.address}</Typography>
                <Typography variant="subtitle1"><strong>Pincode:</strong>  {profile.pincode}</Typography>
                <Typography variant="subtitle1"><strong>City:</strong>  {profile.city}</Typography>
            </CardContent>

                <Typography sx={{marginLeft:2}}variant="h6">Organisation Information</Typography>

            <CardContent>
                <Divider  component="li" />
                <Typography variant="subtitle1"><strong>Name:</strong> {Orgprofile.name}</Typography>
                {/* <Typography variant="subtitle1"><strong>Email:</strong>  {profile.last_name}</Typography> */}
                <Typography variant="subtitle1"><strong>Phone Number:</strong>  {Orgprofile.phone_number}</Typography>
                <Typography variant="subtitle1"><strong>Address:</strong>  {Orgprofile.address}</Typography>
                <Typography variant="subtitle1"><strong>Pincode:</strong>  {Orgprofile.pincode}</Typography>
                <Typography variant="subtitle1"><strong>City:</strong>  {Orgprofile.city}</Typography>
            </CardContent>
            <CardContent>
              {/* <Divider  component="li" />
              <ListItemButton component="a" href="/org/earnings">
                <ListItemText primary="Earnings" />
                <ArrowForwardIosIcon/>
              </ListItemButton> */}
                <React.Fragment>
              <Divider component="li" />
              <ListItemButton onClick={() => handleClick(Orgprofile.stripe_account_id)} >
                <ListItemText primary="Stripe Dashboard" />
                <ArrowForwardIosIcon/>
              </ListItemButton>
              </React.Fragment>     
              
            <Divider component="li" />
              <ListItemButton onClick={() => {
                showpasswordChange(true)
               setshowProfile(false)}} >
                <ListItemText primary="Password Change" />
                <ArrowForwardIosIcon/>
              </ListItemButton>
            <Divider component="li" />
              <ListItemButton onClick={() => {
                 setshowProfile(false)
                setShowSocialAccont(true)
                }} >
                <ListItemText primary="Link Social Accounts" />
                <ArrowForwardIosIcon/>
              </ListItemButton>
              </CardContent>
              </Card>
        )}
        <BottomBar/>
    </React.Fragment>

      )}

        </div>
     );
}

export default OrgAdminProfile;
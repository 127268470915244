import React, { useState } from 'react';
import { Button } from '@mui/material';
const TruncatedText = ({ text ,slice_no =150}) => {
  const [showFullText, setShowFullText] = useState(false);
  const truncatedText = text && text.slice(0, slice_no);

  return (
    <span>
      {showFullText ? (
        <>
          {text}
          <Button sx={{fontSize:8}}  
          onTouchStart={(event) => event.stopPropagation()}
  onMouseDown={(event) => event.stopPropagation()}
          onClick={(event) => {
            setShowFullText(false)
           event.preventDefault()
           }}>less</Button>
        </>
      ) : (
        <>
          {truncatedText}
          {truncatedText && truncatedText.length < text.length && (
            <Button sx={{fontSize:8}} 
            onTouchStart={(event) => event.stopPropagation()}
  onMouseDown={(event) => event.stopPropagation()}
            onClick={(event) => {
              setShowFullText(true)
              event.preventDefault()
            }}>more</Button>
          )}
        </>
      )}
    </span>
  );
};

export default TruncatedText;
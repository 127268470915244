import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import moment from 'moment';
function NotificationItem(props) {
    const notificationData =props.notification
    const handleClick = () => {
      console.log(props);
      
      if (props.handleNotificationClick) {
        props.handleNotificationClick(props.notification.id);
      }
    };
    const timeAgo = moment(notificationData.timestamp).fromNow();
    const getListItemProps = () => {
    switch (notificationData.notification_type) {
        case 'JOB_CREATED':
          return {
            href: `/helper/job/${notificationData.associated_id}`,
          };
        case 'QUOTE_CREATED':
          return {
            href: `/user/task/${notificationData.associated_id}`,
          };
        case 'BOOKING_CREATED':
          return {
            href: `/booking/${notificationData.associated_id}`,
          };
        case 'BOOKING_CANCELLED_BY_USER_FOR_HELPER':
          return {
            href: `/booking/${notificationData.associated_id}`,
          };
        case 'BOOKING_CANCELLED_BY_USER_FOR_USER':
          return {
            href: `/user/task/${notificationData.associated_id}`,
          };
        case 'BOOKING_CANCELLED_BY_HELPER_FOR_HELPER':
          return {
            href: `/booking/${notificationData.associated_id}`,
          };
        case 'BOOKING_CANCELLED_BY_HELPER_FOR_USER':
          return {
            href: `/user/task/${notificationData.associated_id}`,
          };
        case 'RESCHEDULE_REQUESTED_BY_HELPER':
          return {
            href: `/user/task/${notificationData.associated_id}`,
          };
        case 'RESCHEDULE_REQUESTED_BY_SEEKER':
          return {
            href: `/booking/${notificationData.associated_id}`,
          };
        case 'RESCHEDULE_DECLINED_BY_HELPER':
          return {
            href: `/user/task/${notificationData.associated_id}`,
          };
        case 'RESCHEDULE_DECLINED_BY_SEEKER':
          return {
            href: `/booking/${notificationData.associated_id}`,
          };
        case 'RESCHEDULE_APPROVED_BY_HELPER':
          return {
            href: `/user/task/${notificationData.associated_id}`,
          };
        case 'RESCHEDULE_APPROVED_BY_SEEKER':
          return {
            href: `/booking/${notificationData.associated_id}`,
          };
        case 'ADDITIONAL_TIME_REQUESTED_BY_HELPER':
          return {
            href: `/user/task/${notificationData.associated_id}`,
          };
        case 'ADDITIONAL_TIME_CANCELLED_BY_USER':
          return {
            href: `/booking/${notificationData.associated_id}`,
          };
        case 'ADDITIONAL_TIME_ACCEPTED_BY_USER':
          return {
            href: `/booking/${notificationData.associated_id}`,
          };
        case 'MARK_BOOKING_AS_COMPLETE':
          return {
            href: `/booking/${notificationData.associated_id}`,
          };
        case 'TASK_OTP':
          return {
            href: `/user/task/${notificationData.associated_id}`,
          };
        default:
          return {};
      }
    };

    return (
        <ListItem
          button
          component="a"
          onClick={handleClick}
          {...getListItemProps()}
        >
          <ListItemAvatar>
            <NotificationsIcon />
          </ListItemAvatar>
          <ListItemText
            primary={ <Typography sx={{ whiteSpace: 'pre-line'}}
            dangerouslySetInnerHTML={{ __html: notificationData.message }}
            > 
            </Typography>}
            secondary={timeAgo}
          />
        </ListItem>
      );
    }


export default NotificationItem;
import React, { useRef } from 'react';
import { GoogleMap, useLoadScript, Autocomplete } from '@react-google-maps/api';
import TextField from '@mui/material/TextField';
import { GOOGLE_MAPS_API_KEY } from '../../../../../config';
const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 0,
  lng: 0,
};

function GoogleMapsAutocomplete ({setStreetName,setHouseNumber,setLatitude,setLongitude,setLocation_text}) {
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    setLocation_text(place.formatted_address);
    const { lat, lng } = place.geometry.location;
    const addressComponents = place.address_components;

    let houseNumber = '';
    let streetName = '';

    addressComponents.forEach((component) => {
      const types = component.types;

      if (types.includes('street_number')) {
        houseNumber = component.long_name;
      }

      if (types.includes('route')) {
        streetName = component.long_name;
      }
    });
    setStreetName(streetName)
    setHouseNumber(houseNumber)
    setLatitude(lat())
    setLongitude(lng())
  };

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';

  return (
    <div>
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handlePlaceSelect}
      >
        <TextField
          label="Enter a location"
          variant="outlined"
          fullWidth
          sx={{mt:2}}
        //   InputProps={{
        //     style: { backgroundColor: 'white' },
        //   }}
        />
      </Autocomplete>
    </div>
  );
};

export default GoogleMapsAutocomplete;


import React, { useState } from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';



function DatePicker({ startdate, enddate ,onStartChange , onEndChange ,minutes,hasMaxDate}) {
  const handleDateChange = (dateString) => {
        const newDate = new Date(dateString);
        return dayjs(newDate);
      };
  
  const [startDate, setStartDate] = useState(startdate ? handleDateChange(startdate) : dayjs());
  const [endDate, setEndDate] = useState(enddate ? handleDateChange(enddate) : dayjs());
  const [StartTimeError, setStartTimeErrors] = useState({"message":""})
  const [EndTimeError, setEndTimeErrors] = useState({"message":""})


  const getMaxDate = () => {
    if (hasMaxDate) {
      const maxDate =endDate;
      return maxDate;
    }
    return null; // if hasMaxDate is false, no max date is set
  };

  const handleStartDateChange = (newStartDate) => {
    // Check if newStartDate is not in the past
    newStartDate = dayjs(newStartDate)
    console.log(newStartDate.hour() ,  dayjs(Date()).hour());
    // if (newStartDate.hour() >=  dayjs(Date()).hour()) {
      // Check if newStartDate is within allowed time range
      if (
        newStartDate.hour() >= 7 &&
        newStartDate.hour() < 19  &&
        newStartDate.minute() % 30 === 0
      ) {
        setStartDate(newStartDate);
        setStartTimeErrors({});
        if (onStartChange) {
          onStartChange(newStartDate);
          
        }
      } else {
        setStartTimeErrors({"message":"time is not in the range"}); 
      }
    // } 
    // else { setStartTimeErrors({"message":"time is in the past"}); }
  };

  // Disable times before 7:00 AM and after 7:00 PM
  const shouldDisableTime = (timeValue) => {
    const currentHour = timeValue.hour();
    return currentHour < 7 || currentHour >= 19;
  };
  const handleEndDateChange = (newEndDate) => {
    // Check if newEndDate is not in the past   
    // if (newEndDate.hour() >= new  dayjs(Date()).hour()) {
      
      // Check if newEndDate is within allowed time range
      if (
        newEndDate.hour() >= 7  &&
        newEndDate.hour() < 19 &&
        newEndDate.minute() % 30 === 0
      ) {
        // Check if newEndDate is at least 1 hour after startDate
        if (newEndDate - startDate >= minutes * 60 * 1000) {
          setEndDate(newEndDate);
          setEndTimeErrors({});
          if (onEndChange) {
            onEndChange(newEndDate);
          }
          
        } else {
        setEndTimeErrors({"message":`must be atleast ${minutes} minutes`}); 
      }
      } 
      // else {
      //   setEndTimeErrors({"message":"time is not in the range"}); 
      // }
    // } else { setEndTimeErrors({"message":"time is in the past"}); }
  };

  return (
    <div>
       <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <MobileDateTimePicker 
      
        label="Start Time"
        value={startDate}
        minDate={startDate}
        onChange={handleStartDateChange}
        disablePast
        minutesStep={30}
        maxDate={getMaxDate()}
        slotProps={{
          textField: {
            helperText: StartTimeError.message,
            error: Boolean(StartTimeError.message)
          },
        }}
        ampm={false}
        shouldDisableTime={shouldDisableTime} 
        />
         <MobileDateTimePicker 
        label="End Time"
        value={endDate}
        minDate={startDate}
        slotProps={{
          textField: {
            helperText: EndTimeError.message,
            error: Boolean(EndTimeError.message)
          },
        }}
        onChange={handleEndDateChange}
        disablePast
        minutesStep={30}
        maxDate={getMaxDate()}
        ampm={false}
        shouldDisableTime={shouldDisableTime} 
        />
      </DemoContainer>
    </LocalizationProvider>
      </div>
  );
}
export default DatePicker;


import React, { useEffect, useState } from 'react';
import { DialogActions,Typography,DialogContent, TextField, Button,Rating } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import TaskAPi from '../../TaskApi';
const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));
function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}
IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};
const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
};
function FeedbackForm(props) {
  const [rating, setRating] = useState('');
  const [feedback, setFeedback] = useState('');
  const token  = props.token
  const task = props.task
  const [errors, setErrors] = useState({})

  useEffect(()=>{console.log(rating);})

  const handleSubmit = (e) => {
    e.preventDefault();

    TaskAPi.TaskFeedback(token,{rating,feedback,task})
    .then
    ((resp) => {
      console.log(resp) ;
      props.onFeedbackSubmit();
    }
    )
    .catch((error) => {
            const { data } = error.response;
            const errorFields = Object.keys(data);
            const newErrors = {};
            errorFields.forEach(field => {
            const [errorMessage] = data[field];
            newErrors[field] = errorMessage;
            });
        setErrors(newErrors);
        })
   
  }

  return (
    <React.Fragment>
    <DialogContent>
      <form onSubmit={handleSubmit}>
        <StyledRating
          name="rating"
          defaultValue={2}
          IconContainerComponent={IconContainer}
          getLabelText={(value) => customIcons[value].label}
          highlightSelectedOnly
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
           error={Boolean(errors.rating)}
        />
        {errors.rating && (
          <Typography variant="body2" color="error">
            {errors.rating}
          </Typography>
        )}
        <TextField
          id="feedback"
          label="Comments"
          multiline
          fullWidth
          rows={4}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          error={Boolean(errors.feedback)}
          helperText={errors.feedback}
        />
        
      {errors.non_field_errors && <div className="alert alert-danger">{errors.non_field_errors}</div>} 
      </form>

      </DialogContent>     
        <DialogActions>
        <Button  sx={{display: 'flex',flexGrow:1 ,margin:"auto",mr:2 , fontSize:10}} variant="contained" color="primary" onClick={props.handleClose}>
              Close
        </Button> 
        <Button  sx={{display: 'flex',flexGrow:1 ,margin:"auto",mr:2 , fontSize:10}} type="submit" variant="contained" onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </React.Fragment>
  );
}

export default FeedbackForm;

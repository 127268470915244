import React from 'react';
import {  
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import HomeIcon from '@mui/icons-material/Home';
import theme from '../Styles/theme';
import Logout from './Logout';
import NotifyAll from '../notify/NotifyAll';
import UnreadMessages from '../notify/UnreadMessages';
import { useLocation } from 'react-router-dom';
import TaskIcon from '@mui/icons-material/Task';
import IsMobile from '../Styles/IsMobile';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import HelperKycBanner from './HelperKycBanner';

const Header = ({first_name,user_type}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  
  const isMobile = IsMobile();
  const location = useLocation();
  
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const userList = () => (
    <div
        style={{ width: 250}}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
          <ListItem button key="Profile">
          <ListItemButton  href="/profile/user">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
           <ListItemText primary="Profile" />
    {location.pathname === "/profile/user" &&  <ArrowForwardIosIcon/> }
           </ListItemButton>
        </ListItem>
        <ListItem button key="Create">
          <ListItemButton  href="/user/task/create">
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
           <ListItemText primary="Create" />
    {location.pathname === "/user/task/create" &&  <ArrowForwardIosIcon/> }
           </ListItemButton>
        </ListItem>
        <ListItem button key="tasks">
          <ListItemButton  href='/user/tasks'>
          <ListItemIcon>
            <TaskIcon />
          </ListItemIcon>
           <ListItemText primary="Tasks" />
    {location.pathname === '/user/tasks' &&  <ArrowForwardIosIcon/> }
           </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
    const HelperList = () => (
    <div
        style={{ width: 250}}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button key="Home">
          <ListItemButton  href="/helper/home">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
           <ListItemText primary="Home" />
    {location.pathname === '/helper/home'  &&  <ArrowForwardIosIcon/> }
           </ListItemButton>
        </ListItem>
        <ListItem button key="Profile">
          <ListItemButton  href="/profile/helper">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
           <ListItemText primary="Profile" />
    {location.pathname === '/profile/helper'  &&  <ArrowForwardIosIcon/> }
           </ListItemButton>
        </ListItem>
        <ListItem button key="jobs">
          <ListItemButton  href='/helper/jobs'>
          <ListItemIcon>
            <TaskIcon />
          </ListItemIcon>
           <ListItemText primary="Jobs" />
           </ListItemButton>
    {location.pathname === '/helper/jobs'  &&  <ArrowForwardIosIcon/> }
        </ListItem>
        <ListItem button key="bookings">
          <ListItemButton  href='/booking'>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
           <ListItemText primary="Bookings" />
    {location.pathname === '/booking'  &&  <ArrowForwardIosIcon/> }
           </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
  return (
    // <Box sx={{ flexGrow: 1 }}>
    <React.Fragment>
      <AppBar position="fixed" sx={{ background: theme.palette.primary.gradient } }>
         
        <Toolbar >
          
          {!isMobile && (
            <React.Fragment>
            <IconButton
            edge="start"
            sx={{ marginRight: 2}}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
            sx: {
              backgroundColor: "#F8F8FF",
            }
          }}
          >
            {user_type ==="user" && userList()}
            {user_type ==="helper" && HelperList()}
          </Drawer>
          </React.Fragment>
          )}
           <PersonPinCircleIcon />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            LocaleLingo
          </Typography>
          {!isMobile && (
          <React.Fragment>
          <NotifyAll/>
          <UnreadMessages/>
          </React.Fragment>
          )}
          {
            isMobile && user_type==="helper" &&  (
              <React.Fragment>
                 <IconButton
          sx={{flexDirection:'column' , color: location.pathname === "/profile/helper"? 'primary' : 'white' }} 
          href="/profile/helper"
          >
            <PersonIcon   fontSize="small" />
          </IconButton> 
              </React.Fragment>
            )
              
            
          }
          <Logout first_name={first_name}/>
        </Toolbar>
        
      </AppBar>
      {/* <HelperKycBanner/> */}
      <div style={{ marginTop:'35' ,padding:40}}></div> 
       
    </React.Fragment>

      // </Box>
  );
};

const App = ({first_name,user_type}) => {
  return (
    <Header first_name={first_name} user_type={user_type} />
  );
};

export default App;

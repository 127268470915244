import React, { useState,useEffect } from 'react';
import { MapContainer, TileLayer, Polygon ,GeoJSON} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Typography } from '@mui/material';

const MapWithPolygonDrawing = () => {
  const [coordinates, setCoordinates] = useState([]);
  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setCoordinates((prevCoordinates) => [...prevCoordinates, [lat, lng]]);
  };
  const [geojsonData, setGeojsonData] = useState([]);

  let pincodes = [63]

  useEffect(() => {
  const fetchData = async () => {
    try {
      for (const pincode of pincodes) {
        console.log(pincode);
        const response = await fetch(`http://localhost:8000/marketplace/locations/autocomplete/?query=${pincode}`);
        const data = await response.json();
        console.log(data);
        setGeojsonData(data)
        // setGeojsonData((prevData) => [data, ...prevData]);
      }
    } catch (error) {
      console.error('Error fetching GeoJSON data:', error);
    }
  };

  fetchData();
}, []);


  useEffect(()=>{

geojsonData.length>0 && console.log( geojsonData[0].area);
  }
  ,[geojsonData])

  const handleClear = () => {
    setCoordinates([]);
  };

  return (
    <div>
      <MapContainer
        center={[51.1657, 10.4515]}
        zoom={5}
        style={{ height: '400px', width: '100%' }}
        onMouseUp={handleMapClick}
      >
       <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
        {
            geojsonData.length>0 && geojsonData.map(geo=>(
                <Polygon key={geo.id} positions={geo.coordinates} /> 
            ))
        }
      </MapContainer>
      <button onClick={handleClear}>Clear</button>
    </div>
  );
};

export default MapWithPolygonDrawing;

import React, { useEffect }  from 'react';
import moment from "moment";
import { IconButton,CardContent,Card,Typography, Box,Divider } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OnSpotBookings from './OnSpotBookings';
import AdditionalBookingForm from './AdditionalBookingForm';
import { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
function BoookingsLists({booking,token,setShowBookingDetail,setshowBookings}) {
    const task=booking.task_data
     const [showAdditionalBookingForm, setShowAdditionalBookingForm] = useState(false);
    const dateConvert = (ds) =>{
        return  moment(ds).format('MMMM Do YYYY, h:mm:ss a');
    }
    const handleGoBack = (event) => {
    event.preventDefault();
    setShowBookingDetail(true);
    setshowBookings(false);
  }
  useEffect(()=>{
    
  })

    const taskData=task
    return ( 
        <Box   p={2}>
        <IconButton sx={{p:3}} onClick={handleGoBack}>
          <ArrowBackIcon/>
        </IconButton>
        <Card
        style={{backgroundColor: "#0dcaf000"}}
        elevation={0}
          >
            <Typography  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}variant="h4">Booking Detail</Typography>
            <Divider sx={{ bgcolor: "secondary.light" }} component="p" />
            <CardContent  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}>
                <Typography variant="h5">{taskData.title}</Typography>
                <Typography  variant="subtitle1">
                    <strong>Start Time: </strong> {dateConvert(taskData.start_time)}
                </Typography>
                <Typography variant="subtitle1">
                    <strong>End Time:  </strong> {dateConvert(taskData.end_time)}
                </Typography>
            
            </CardContent>
          </Card>
            <Typography  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}variant="h5">Additonal Booking Details</Typography>
            <Divider sx={{ bgcolor: "secondary.light" }} component="p" />
            <AdditionalBookingForm booking={booking} showAdditionalBookingForm={showAdditionalBookingForm} setShowAdditionalBookingForm={setShowAdditionalBookingForm}/>            
            
            {
          booking.available_actions.additonal_time_action &&
          (
          <IconButton sx={{p:3 }} onClick={() => setShowAdditionalBookingForm(true)}>
                <AddIcon/>
            </IconButton>
          )
        }

        <CardContent  sx={{ alignItems:"center",display:"flex", flexFlow: 'column' , justifyItems:'center',justifyContent:'center'}}>
            {
                booking.onspot_booking_data.length === 0 && <div>No Bookings Found</div>
            }
            {
                booking.onspot_booking_data.length !== 0 && <OnSpotBookings onsbk={booking.onspot_booking_data} token={token}/>
            }
        </CardContent>
         
        </Box>
        
     );
}

export default BoookingsLists;
import React, { useEffect,useState } from 'react';
import {
  Badge,
  IconButton,
  List,
  Typography,
  Popover,
  Tabs,
  Tab,
  Button,
  Divider
} from '@mui/material';
import TabPanel from '../utils/TabPanel';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotifyApiService from './NotifyApi';
import useCheckAuth from '../commonuser/CheckAuth';
import NotificationItem from './notifcations/NotificationItem';
import { subscribeUser } from '../utils/webPush';
import { useDispatch } from 'react-redux';
import { setAllNotifications } from '../reducers/NotifyActions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IsMobile from '../Styles/IsMobile';
import { useNavigate } from 'react-router-dom';

function NotifyAll({ic_sx}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const NewNotifyStatusList = ["CREATED",'NOTIFICATION_SENT'];
  const OldNotifyStatusList = ["READ"];
  const dispatch = useDispatch(); 
  const isMobile = IsMobile()
  const [notifications, setNotifications] = useState([]);
  const [newnotifications, setNewNotifications] = useState([]);
  const [oldnotifications, setOldtNotifications] = useState([]);
  // eslint-disable-next-line
  // const [allowsubscription, setSubcription] = useState(false);
  const token = useCheckAuth();
  let navigate = useNavigate()
  
  const handleClick = (event) => {
    // event.prevent.default()
    if (isMobile) {
       navigate('/notify');
       console.log(event);
    } else {
      setAnchorEl(event.currentTarget);
    }
    
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updatenotifcations =( )=>{
    NotifyApiService.AllNotification(token).then((resp) => {
      setNotifications(resp)
      dispatch(setAllNotifications(resp))
    })
  }

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data.type === 'PUSH_NOTIFICATION_RECEIVED') {
          updatenotifcations()
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
    updatenotifcations()
    
  }, 50000);

  window.addEventListener('beforeunload', () => {
      clearInterval(intervalId);
    });
  return () => {
    window.removeEventListener("beforeunload", () => clearInterval(intervalId));
    clearInterval(intervalId);
  };
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updatenotifcations() 
    // eslint-disable-next-line
  }, []); 

  useEffect(()=>{
    setNewNotifications(notifications.length ? notifications.filter(notify => NewNotifyStatusList.includes(notify.notification_status)): [])
    setOldtNotifications(notifications.length ? notifications.filter(notify => OldNotifyStatusList.includes(notify.notification_status)): [])
  // eslint-disable-next-line  
  },[notifications])


  const markread =(ids)=>{
    NotifyApiService.ReadNotification(token,ids).then((resp) => {
      console.log(resp);
    })
  }

  const handleClearAllClick = () => {
    const notificationIds = newnotifications.map(notification => notification.id);
    markread(notificationIds)
    const newNotifications = notifications.map(notification => ({
      ...notification,
      notification_status: "READ"
    }));
    setNotifications(newNotifications);
    handleClose();
  };

  const handleNotificationClick = (id) => {
    markread([id])
    const newNotifications = notifications.map((notification) => {
      if (notification.id === id) {
        return { ...notification, notification_status: "READ" };
      }
      return notification;
    });
    setNotifications(newNotifications);
  };

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'notifications-popover' : undefined;
  return (
    <>
      {isMobile ? (
        <IconButton 
      aria-describedby={id} 
      sx={ic_sx}
      href='/notify'
      >
        <Badge badgeContent={newnotifications.length} color="secondary"></Badge>
          <NotificationsIcon fontSize="small" />
       {isMobile && <Typography fontSize={10} >Alerts</Typography>}
        
      </IconButton>
      ):(
        <IconButton 
      aria-describedby={id} 
      onClick={(e) => (handleClick(e))} 
      color="inherit"
      >
      <Badge badgeContent={newnotifications.length} color="secondary">
          <NotificationsIcon />     
          </Badge>   
      </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
            sx: {
              backgroundColor: "#F8F8FF",
            }
          }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
         <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="New" />
          <Tab label="Old" />
         </Tabs>
      <TabPanel value={tabValue} index={0}>
      <List >
          {newnotifications.length > 0 ? (
              newnotifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  handleNotificationClick={handleNotificationClick}
                />
              ))
            ) : (
              <List>
                <Divider variant="middle" color="primary" orientation="horizontal" > <CheckCircleOutlineIcon sx ={{ fontSize:50  , color:"green" }}/></Divider>
                <Typography sx={{textAlign: "center","overflowY": 'auto', "minWidth": '300px', "maxWidth": '500px',}} variant="h5">All caught up!</Typography>
                <Typography sx={{textAlign: "center"}} variant="body1">
                  You have no new Notifications
                </Typography>
              </List>
            )}
        </List>
        {newnotifications.length > 0 && (
          <Button
            variant="outlined"
            color="primary"
            sx={{width :'100%' , flexGrow: 1, display: "inline-block",justifyContent: 'left'}}
            onClick={handleClearAllClick}
          >
            Clear All
          </Button>
        )}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
      <List >

        {oldnotifications.length > 0 ? (
              oldnotifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  handleNotificationClick={handleNotificationClick}
                />
              ))
            ) : (
              <Typography 
              sx ={{ "maxHeight": 'calc(100vh - 64px)' , 
              "overflowY": 'auto',
               "minWidth": '300px',
                "maxWidth": '500px',}}
               variant="body1">No Notifications</Typography>
            )}
        </List>
      </TabPanel>   
      </Popover>
    </>
  );
}

export default NotifyAll;


import axios from 'axios';
import { BACKEND_URL , authheaders } from "../../config"
export default class OrgApi {


    static GetUsers(token) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/auth/org/users/`,
        headers: authheaders(token),
      }
      return axios(options).then(resp => resp.data)
    }
    static GetOrg(token) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/auth/org/`,
        headers: authheaders(token),
      }
      return axios(options).then(resp => resp.data)
    }

    static GetOrgEarnings(token) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/payment/org/total-earnings/`,
        headers: authheaders(token),
      }
      return axios(options).then(resp => resp.data)
    }

    static GetWithdrawls(token) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/payment/org/withdrawls/`,
        headers: authheaders(token),
      }
      return axios(options).then(resp => resp.data)
    }
    
    static PayoutRemaining(token ) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/payment/org/payout/`,
        headers:authheaders(token),
        data :JSON.stringify({})
      }
      return axios(options).then(resp => resp.data)
    }

    static GetStripeDashboardUrl(token ) {
      const options = {
        method: 'post',
        url:`${BACKEND_URL}/auth/org/payment-dashboard`,
        headers:authheaders(token),
        data :JSON.stringify({})
      }
      return axios(options).then(resp => resp.data)
    }
    static EarningsOverTime(token ,months) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/payment/org/earnings-over-time/?months=${months}`,
        headers:authheaders(token),
      }
      return axios(options).then(resp => resp.data)
    }
    static HelperEarningsOverTime(token ,user_id,months) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/payment/org/earnings-over-time/${user_id}/?months=${months}`,
        headers:authheaders(token),
      }
      return axios(options).then(resp => resp.data)
    }
    static HelperEarningsTotal(token ,user_id) {
      const options = {
        method: 'get',
        url:`${BACKEND_URL}/payment/org/total-earnings/${user_id}/`,
        headers:authheaders(token),
      }
      return axios(options).then(resp => resp.data)
    }
    static HelperStats(token,user_id) {
       const options = {
        method: 'get',
        url:`${BACKEND_URL}/stats/org/${user_id}`,
        headers: authheaders(token),
      }
     return axios(options).then(resp => resp.data)
    }



}
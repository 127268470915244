import { useState } from 'react';
import { Box, Button, TextField, Typography ,IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UserApiService from '../commonuser/UserApi';
import PasswordIcon   from '@mui/icons-material/Password';
import InputAdornment from '@mui/material/InputAdornment';

function PasswordChange({token,showpasswordChange,setshowProfile}) {
  const [new_password1, setNewPassword1] = useState('')
  const [new_password2, setNewPassword2] = useState('')
  const [errors, setErrors] = useState({});
  const handleGoBack = (event) => {
    event.preventDefault();
    showpasswordChange(false)
    setshowProfile(true)
  }

  // const handleNewPasswordChange = (event) => {
  //   setNewPassword1(event.target.value);
  // };

  // const handleConfirmNewPasswordChange = (event) => {
  //   setNewPassword2(event.target.value);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
   UserApiService.PasswordChangeForm(token,{new_password1,new_password2})
        .then((resp) => {
          showpasswordChange(false)
          setshowProfile(true)
        })
        .catch((error) => {
                const { data } = error.response;
                const errorFields = Object.keys(data);
                console.log(errorFields);
                const newErrors = {};
                errorFields.forEach(field => {
                const [errorMessage] = data[field];
                newErrors[field] = errorMessage;
                });
                setErrors(newErrors);
              })
      

  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4, px: 2 }}>
        <IconButton onClick={handleGoBack}>
          <ArrowBackIcon/>
        </IconButton>
      <Typography variant="h5" gutterBottom>
        Change Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          id="new-password"
          label="New Password"
          type="password"
          variant="outlined"
          fullWidth
          required
          value={new_password1}
          onChange={e => setNewPassword1(e.target.value)}
           error={Boolean(errors.new_password1)}
        helperText={errors.new_password1}
          margin="normal"
          InputProps={{
        startAdornment: (
    <InputAdornment position="start">
        <PasswordIcon />
    </InputAdornment>
          ),
        }}
        />
        <TextField
          id="confirm-new-password"
          label="Confirm New Password"
          type="password"
          variant="outlined"
          fullWidth
          required
          value={new_password2}
          margin="normal"
          onChange={e => setNewPassword2(e.target.value)}
             error={Boolean(errors.new_password2)}
        helperText={errors.new_password2}
            InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <PasswordIcon />
                </InputAdornment>
              ),
            }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" color="primary" type="submit">
            Save Changes
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default PasswordChange;

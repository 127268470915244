import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import JobsApi from '../JobsAPi';
import { FaCube } from 'react-icons/fa';

function AcceptJob({task_id,token,connects_required}) {

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [QuoteSetError, setQuoteSetError] = useState({});
    const handleConfirmOpen = () => {
        setConfirmOpen(true);
    };
    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };
    const AcceJob= () => {
        JobsApi.JobAccept(token,task_id)
        .then((resp) => (window.location.reload(false)))
        .catch((error) => {
          const { data } = error.response;
          const errorFields = Object.keys(data);
          const newErrors = {};
          errorFields.forEach(field => {
          const [errorMessage] = data[field];
          newErrors[field] = errorMessage;
          });
          setQuoteSetError(newErrors);
        })
      }
    useEffect(()=>{ console.log(QuoteSetError);})
    return (
        <React.Fragment>
        <Button sx={{ml:3}} variant="contained" color="primary" onClick={handleConfirmOpen}>
            Press To Accept
        </Button>
        <Dialog open={confirmOpen} onClose={handleConfirmClose}>
            <DialogTitle>Accept Job</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Are you sure you want to Accept the Job?
            </DialogContentText>
            <DialogContentText>
                Sending quote for this task will cost {connects_required} <FaCube/>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleConfirmClose} color="primary">
                Cancel
            </Button>
            <Button onClick={() => AcceJob()} color="primary">
                Submit
            </Button>
            </DialogActions>
        </Dialog>
        {QuoteSetError.message && <div className="alert alert-danger">{QuoteSetError.message}</div>}
        </React.Fragment>
     );
}

export default AcceptJob;
import React, { useState, useEffect } from 'react';
import useCheckAuth from '../../commonuser/CheckAuth';
import {MenuItem,Select,Divider, Card,Typography ,CardContent,Box,Grid, Button} from '@mui/material';
import Loading from '../../NavBars/Loading';
import WaitingForVerificationBanner from '../../helpercomponent/WaitingForVerificationBanner';
import { CircularProgressbar ,buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import IsMobile from '../../Styles/IsMobile';
import { BsSuitDiamondFill } from "react-icons/bs";
import moment from "moment";
import BookingsGraph from '../../HelperHome/components/BookingsGraph';
// import UserApiService from '../commonuser/UserApi';
import OrgApi from '../OrgApi';
import { useParams } from 'react-router-dom';
import NavBar from '../PopOverMenu';
import BottomBar from '../BottomBar';
function UserStats() {
    const token = useCheckAuth();
    const [stats, setStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showBookingssGraph, setshowBookingssGraph] = useState(false);
    const [ bookingsMonths,setbookingsMonths]= useState(3);
    const [bookingssOvertime, setbookingssOvertime] = useState([]);
    const [showEarningsTotal, setshowEarningsTotal] = useState(false);
    const [TotalEarnings, setTotalEarnings] = useState({});
     const { userId } = useParams();


    const ismobile = IsMobile();

    const dateConvert = (ds) =>{
    return  moment(ds).format('MMMM Do YYYY');
    }

    useEffect(()=>{
    OrgApi.HelperEarningsOverTime(token,userId,bookingsMonths)
    .then(response => {
      setbookingssOvertime(response.data)
      setshowBookingssGraph(true)
    }
    )

    },[token,bookingsMonths])

    useEffect(()=>{
    OrgApi.HelperEarningsTotal(token,userId)
    .then(response => {
      setTotalEarnings(response.total_earnings_data)
      setshowEarningsTotal(true);
      
    }
    )
    OrgApi.HelperStats(token,userId)
        .then(resp=>{
        setStats(resp)
    setLoading(false)})
    },[token])
    const tierColor = (tier)=>{
        if (tier === "Silver") {return "#C0C0C0"}
        else if (tier === "Gold") {return "#DFBD69"}
        else if (tier === "Platinum") {return "Black"}
        else  {return "brown"}

    }    
    return (
    <div>
        <NavBar/>
        <WaitingForVerificationBanner/>
        {loading ? (
        <Loading />
      ) : (
        <React.Fragment>

        <Card
              sx={{ maxWidth: 800,margin: "auto",}}
              elevation={0}>
                <Typography align='center' variant='h5'>Helper stats</Typography>
                <CardContent>
                <Box sx={{p:2}} display="flex" justifyContent="space-between">
                <Typography variant="body1">Helper tier</Typography>
                <Typography variant="body1">
                {stats.tier} <BsSuitDiamondFill style={{ fill: tierColor(stats.tier) }} />
                </Typography>
                
                </Box>
                <Box sx={{p:2}} display="flex" justifyContent="space-between">
                <Typography variant="body1">Joined on</Typography>
                <Typography variant="body1">
                {dateConvert(stats.joined_at)} 
                </Typography>
                
                </Box>
                <Box sx={{p:2}} display="flex" justifyContent="space-between">
                <Typography variant="body1">Verified languages</Typography>
                <Typography variant="body1">
                {stats.verified_languages && JSON.parse(stats.verified_languages).join(', ')}
                </Typography>
                
                </Box>
                <Select fullWidth value={bookingsMonths} onChange={(e)=>{setbookingsMonths(e.target.value)}} variant="standard">
                <MenuItem value="2">This Month</MenuItem>
                <MenuItem value="3">3 Months</MenuItem>
                <MenuItem value="6">6 Months</MenuItem>
                <MenuItem value="12">Year to Date</MenuItem>
                </Select>
                {showBookingssGraph &&<BookingsGraph data={bookingssOvertime}/>}

                
                </CardContent>
                <Grid container spacing={2} >
                   {!ismobile && <Grid  item xs={1} lg={0.3} md={1} ></Grid>} 
                    <Grid  item xs={0} lg={1} md={1} ></Grid>
                    
                <Grid  item xs={2} lg={1.5} md={1} >
                    <CircularProgressbar styles={buildStyles({pathColor:"green" , textColor:"black"})} value={stats.completion_rate} text={`${stats.completion_rate}%`} />
               <Typography align='center' sx={{}}>Completion Rate</Typography>
               </Grid>
               <Grid  item xs={1} lg={1} md={1} ></Grid>
               <Grid  item xs={2} lg={1.5} md={1} >
                <CircularProgressbar 
                styles={buildStyles({pathColor:"green" , textColor:"black"})
                } 
                value={stats.number_of_completed_booking>0 ? 100 :0} 
                text={stats.number_of_completed_booking} />
               <Typography align='center' sx={{}}>No. of Completed Bookings</Typography>
               </Grid>
               <Grid  item xs={1} lg={1} md={1} ></Grid>
               <Grid  item xs={2} lg={1.5} md={1} >
                 <CircularProgressbar 
                styles={buildStyles({pathColor:"green" , textColor:"black"})
                } 
                value={stats.accepted_rate} 
                text={`${stats.accepted_rate}%`} />
              
               <Typography align='center' sx={{}}>Acceptance Rate</Typography>
               </Grid>
               <Grid  item xs={1} lg={1} md={1} ></Grid>
                <Grid  item xs={2} lg={1.5} md={1} >
                    <CircularProgressbar 
                styles={buildStyles({pathColor:"green" , textColor:"black"})
                } 
                value={stats.ratings_percetnt} 
                text={stats.ratings} />
               <Typography align='center' sx={{}}>Ratings</Typography>
               </Grid>
               </Grid>
            <Divider sx={{p:2, borderBottomWidth: '2px', borderColor: 'black' }} />
             
            

        </Card>
        <br></br>
        <Box sx={{p:1,mb:2, maxWidth: 800,margin: "auto"}}  display="flex" justifyContent="space-between">
                <Typography variant='h5'>Earnings</Typography>
                </Box>
        {showEarningsTotal && (
          <Card style={{backgroundColor: "#0dcaf000"}} sx={{ maxWidth: 800,margin: "auto",}} variant='outlined' elevation={2} >
              <CardContent>
                
                 <Grid container spacing={0.3}>
                  <Grid  item xs={3}>
                    <Typography align='center'  variant="subtitle1"><strong>€{TotalEarnings.total_payout_amount_ready_to_withdraw}</strong></Typography>
                     <Typography  align='center' sx={{fontSize:12}} variant="subtitle1">Ready to withdraw</Typography>
                  </Grid>
                  <Grid  item xs={3}>
                    <Typography align='center'  variant="subtitle1"><strong>€{TotalEarnings.total_payout_amount_pending_withdraw}</strong></Typography>
                     <Typography  align='center' sx={{fontSize:12}} variant="subtitle1">Amount Pending Verification</Typography>
                  </Grid>
                  <Grid  item xs={3}>
                    <Typography align='center'  variant="subtitle1"><strong>€{TotalEarnings.total_earnings}</strong></Typography>
                     <Typography  align='center' sx={{fontSize:12}} variant="subtitle1">Total Earnings</Typography>
                  </Grid>
                  <Grid  item xs={3}>
                    <Typography align='center'  variant="subtitle1"><strong>€{TotalEarnings.total_payout_amount}</strong></Typography>
                     <Typography  align='center' sx={{fontSize:12}} variant="subtitle1">Total Payouts</Typography>
                  </Grid>
                 </Grid>
                
            </CardContent>
            <br></br>
            </Card>
        )}
        
            <br></br>
            <br></br>
            <br></br>
            <BottomBar/>
            </React.Fragment>)}
         

    </div> );
}

export default UserStats;